import { Skeleton } from '@mui/material'

import { CardsContainer, ResultsTitle } from '../SavedSales.style'

const Loader = () => (
  <>
    <ResultsTitle>
      <Skeleton height="40px" width="100%" />
    </ResultsTitle>
    <CardsContainer className="hidden-desktop">
      <Skeleton height="300px" width="100%" style={{ transform: 'none' }} />
      <Skeleton height="300px" width="100%" style={{ transform: 'none' }} />
      <Skeleton height="300px" width="100%" style={{ transform: 'none' }} />
    </CardsContainer>
    <CardsContainer className="hidden-mobile">
      <Skeleton height="150px" width="100%" style={{ transform: 'none' }} />
      <Skeleton height="150px" width="100%" style={{ transform: 'none' }} />
      <Skeleton height="150px" width="100%" style={{ transform: 'none' }} />
      <Skeleton height="150px" width="100%" style={{ transform: 'none' }} />
    </CardsContainer>
  </>
)

export default Loader
