import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'

import { OCEAN_PADS_SVG, LOGOUT_ICON, HAMBUGER_ICON } from '../../../constants/image.const'
import { USER_TYPE } from '../../../constants/app.const'
import { logout } from '../../../services/auth.service'
import { authActionReducer } from '../../../store/auth/authSlice'
import { panelsActionReducer } from '../../../store/panels/panelsSlice'
import { modalsActionReducer } from '../../../store/modals/modalsSlice'
import {
  Button,
  HamburgerIconContainer,
  LogoContainer,
  MainHeaderContainer,
  MainHeaderContentContainer,
  MainHeaderControlsContainer,
  NavbarItem,
  StyledLink,
} from './MainHeader.style'
import clickEvents from '../../../services/analytics.service'

const MainHeader = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { user } = useSelector((state) => state.auth)

  const openHeaderPanel = () => dispatch(panelsActionReducer?.setHeaderPanelIsOpen(true))
  const openAuthModal = () => dispatch(modalsActionReducer.setAuthModalIsOpen(true))
  const setUser = (payload) => dispatch(authActionReducer?.setUser(payload))

  const userIsLoggedIn = !!user
  const userIsRealtor = user?.user?.type === USER_TYPE.REALTOR
  const userIsAdmin = user?.user?.is_superuser

  const handleLogoOnClick = () => {
    ReactGA.event({
      category: 'action',
      action: 'main-header-logo-clicked',
    })
    clickEvents.MAIN_HEADER_LOGO()
  }

  const handleSignInOnClick = () => {
    openAuthModal()
    ReactGA.event({
      category: 'action',
      action: 'main-header-register-clicked',
    })
  }

  const handleSignOutOnClick = () => {
    logout()
    setUser(null)
    const path = location?.pathname
    if (path.includes('account') || path.includes('realtors')) navigate('/')
  }

  return (
    <MainHeaderContainer id="header-nav-main">
      <HamburgerIconContainer className="hidden-desktop" onClick={openHeaderPanel}>
        <img src={HAMBUGER_ICON} alt="menu" />
      </HamburgerIconContainer>
      <StyledLink to="/" onClick={handleLogoOnClick}>
        <LogoContainer src={OCEAN_PADS_SVG} alt="oceanpads-logo" />
      </StyledLink>
      <MainHeaderContentContainer className="hidden-mobile">
        {userIsLoggedIn ? (
          <MainHeaderControlsContainer>
            <StyledLink to={userIsAdmin ? '/admin' : userIsRealtor ? '/realtors' : '/account'}>
              <NavbarItem>{userIsAdmin ? 'Admin' : userIsRealtor ? 'My Realtor Page' : 'Account'}</NavbarItem>
            </StyledLink>
            <Button onClick={handleSignOutOnClick}>
              Sign Out
              <img src={LOGOUT_ICON} alt="logout" />
            </Button>
          </MainHeaderControlsContainer>
        ) : (
          <Button onClick={handleSignInOnClick}>Sign in/Register</Button>
        )}
      </MainHeaderContentContainer>
    </MainHeaderContainer>
  )
}

export default MainHeader
