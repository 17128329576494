import styled from 'styled-components'
import THEME from '../../../../constants/themes'

export const PriceFilterContainer = styled.div.attrs({
  className: 'PriceFilterContainer',
})(
  ({ isOpen }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-color: ${THEME.backgroundGray};
    position: relative;
    &:hover {
      background-color: #fafafa;
    }
  `,
)

export const PriceFilterTriggerContainer = styled.div.attrs({
  className: 'PriceFilterTriggerContainer',
})`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0.5em;
`

export const PriceOptionsContainer = styled.div.attrs({
  className: 'PriceOptionContainer',
})`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
`
