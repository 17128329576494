import { configureStore } from '@reduxjs/toolkit'

import authSlice from './auth/authSlice'
import favoritesSlice from './favorites/favoritesSlice'
import listingReducer from './listing/listingSlice'
import mapSlice from './map/mapSlice'
import modalsSlice from './modals/modalsSlice'
import neighborhoodSlice from './neighborhood/neighborhoodSlice'
import pageSlice from './page/pageSlice'
import panelsSlice from './panels/panelsSlice'
import promotionsSlice from './promotions/promotionsSlice'
import searchSlice from './search/searchSlice'
import selectionsSlice from './selections/selectionsSlice'

export const store = configureStore({
  reducer: {
    auth: authSlice,
    favorites: favoritesSlice,
    listing: listingReducer,
    map: mapSlice,
    modals: modalsSlice,
    neighborhood: neighborhoodSlice,
    page: pageSlice,
    panels: panelsSlice,
    promotions: promotionsSlice,
    search: searchSlice,
    selections: selectionsSlice,
  },
})
