import heroMobile from '../assets/images/hero/mobile-hero.svg'

import logo from '../assets/images/logo.png'
import oceanPads from '../assets/images/oceanPads.png'
import oceanPadsSVG from '../assets/images/oceanPads.svg'
import text from '../assets/images/text.png'
import popArt from '../assets/images/pop-art-3.png'
import bannerHeader from '../assets/images/banner-header.png'
import bannerFooter from '../assets/images/banner-footer.png'

import miamiNight from '../assets/images/miami-night.jpg'
import miamiOceanTrees from '../assets/images/miami-ocean-trees.jpg'
import miamiSeaTowers from '../assets/images/miami-sea-towers.jpg'

import pet from '../assets/images/pet.svg'
import elevator from '../assets/images/elevator.svg'
import doorman from '../assets/images/doorman.svg'
import association from '../assets/images/association.png'

import savedListingIcon from '../assets/images/saved-listing-icon.svg'
import newListingIcon from '../assets/images/new-listing-icon.svg'
import homeSearchIcon from '../assets/images/home-search-icon.svg'
import questionsSupportIcon from '../assets/images/questions-support-icon.svg'
import floorsIcon from '../assets/images/floors.svg'
import pyramidIcon from '../assets/images/pyramid.svg'
import unitIcon from '../assets/images/unit.svg'
import bathIcon from '../assets/images/bath.svg'
import squareFootIcon from '../assets/images/square-foot.svg'
import bedIcon from '../assets/images/bed.svg'
import emailIcon from '../assets/images/email.svg'
import phoneIcon from '../assets/images/phone.svg'
import logoutIcon from '../assets/images/logout.svg'
import arrowDownIcon from '../assets/images/arrow-down.svg'
import arrowLeftIcon from '../assets/images/arrow-left.svg'

import bellIcon from '../assets/images/bell.svg'
import settingIcon from '../assets/images/setting.svg'
import userIcon from '../assets/images/user.svg'
import searchIcon from '../assets/images/searchIcon.svg'
import searchWhiteIcon from '../assets/images/searchWhiteIcon.svg'
import hambugerIcon from '../assets/images/hambuger.svg'
import closeMenuIcon from '../assets/images/close-menu.svg'
import bookmarkIcon from '../assets/images/bookmark.svg'
import heartOutline from '../assets/images/heart-outline.svg'
import heartSolid from '../assets/images/heart-solid.svg'
import heartOutlineBlue from '../assets/images/heart-outline-blue.svg'
import heartSolidBlue from '../assets/images/heart-solid-blue.svg'

import buildingDefault from '../assets/images/building-default.svg'
import buildingDefault2 from '../assets/images/building-default-2.svg'

import tableSortAscending from '../assets/images/table-sort-ascending.svg'
import tableSortDescending from '../assets/images/table-sort-descending.svg'

import doormanIcon from '../assets/images/icons/doorman.png'
import elevatorIcon from '../assets/images/icons/elevator.png'
import gymIcon from '../assets/images/icons/gym.png'
import kidsLoungeIcon from '../assets/images/icons/kids-lounge.png'
import loungeIcon from '../assets/images/icons/lounge.png'
import otherAmenitiesIcon from '../assets/images/icons/other-amenities.png'
import poolIcon from '../assets/images/icons/pool.png'
import spaIcon from '../assets/images/icons/spa.png'
import tennisIcon from '../assets/images/icons/tennis.png'
import valetIcon from '../assets/images/icons/valet.png'

import fileformsDesktop from '../assets/images/ads/fileforms-desktop.svg'
import fileformMobile from '../assets/images/ads/fileforms-mobile.svg'
// import fileformMobile from '../assets/images/ads/fileforms-mobile.png'

import notfoundDefault from '../assets/images/notfound.png'

import aiPier from '../assets/images/ai/pier.png'

export const HERO_MOBILE = heroMobile

export const LOGO = logo
export const OCEAN_PADS = oceanPads
export const OCEAN_PADS_SVG = oceanPadsSVG

export const TEXT = text
export const POP_ART = popArt
export const BANNER_HEADER = bannerHeader
export const BANNER_FOOTER = bannerFooter

export const MIAMI_NIGHT = miamiNight
export const MIAMI_OCEAN_TREES = miamiOceanTrees
export const MIAMI_SEA_TOWERS = miamiSeaTowers

export const PET = pet
export const ELEVATOR = elevator
export const DOORMAN = doorman
export const ASSOCIATION = association

export const QUESTIONS_SUPPORT_ICON = questionsSupportIcon
export const SAVED_LISTING_ICON = savedListingIcon
export const NEW_LISTING_ICON = newListingIcon
export const HOME_SEARCH_ICON = homeSearchIcon
export const SEARCH_ICON = searchIcon
export const SEARCH_ICON_WHITE = searchWhiteIcon
export const FLOORS_ICON = floorsIcon
export const PYRAMID_ICON = pyramidIcon
export const UNIT_ICON = unitIcon
export const BED_ICON = bedIcon
export const BATH_ICON = bathIcon
export const SQURE_FOOT_ICON = squareFootIcon
export const EMAIL_ICON = emailIcon
export const PHONE_ICON = phoneIcon
export const LOGOUT_ICON = logoutIcon
export const ARROW_DOWN_ICON = arrowDownIcon
export const BELL_ICON = bellIcon
export const SETTING_ICON = settingIcon
export const USER_ICON = userIcon
export const HAMBUGER_ICON = hambugerIcon
export const CLOSE_MENU_ICON = closeMenuIcon
export const ARROW_LEFT_ICON = arrowLeftIcon
export const BOOKMARK_ICON = bookmarkIcon
export const HEART_OUTLINE = heartOutline
export const HEART_SOLID = heartSolid
export const HEART_OUTLINE_BLUE = heartOutlineBlue
export const HEART_SOLID_BLUE = heartSolidBlue

export const BUILDING_DEFAULT = buildingDefault
export const BUILDING_DEFAULT_2 = buildingDefault2

export const TABLE_SORT_ASCENDING = tableSortAscending
export const TABLE_SORT_DESCENDING = tableSortDescending

export const ICONS_DOORMAN = doormanIcon
export const ICONS_ELEVATOR = elevatorIcon
export const ICONS_GYM = gymIcon
export const ICONS_KIDS_LOUNGE = kidsLoungeIcon
export const ICONS_LOUNGE = loungeIcon
export const ICONS_OTHER_AMENITIES = otherAmenitiesIcon
export const ICONS_POOL = poolIcon
export const ICONS_SPA = spaIcon
export const ICONS_TENNIS = tennisIcon
export const ICONS_VALET = valetIcon

export const ADS_FILEFORMS_DESKTOP = fileformsDesktop
export const ADS_FILEFORMS_MOBILE = fileformMobile

export const NOTFOUND_DEFAULT = notfoundDefault

export const AI_PIER = aiPier
