import styled from 'styled-components'

import THEME from '../../../constants/themes'

export const AuthContainer = styled.div.attrs({
  className: 'AuthContainer',
})`
  display: flex;
  background: ${THEME.backgroundGray};
  flex-direction: column;
  padding: 2em 3em;
  border-radius: 10px;
  @media (max-width: 992px) {
    height: 100%;
    width: 100%;
    border-radius: 0;
    padding: 1em;
  }
`

export const ContentContainer = styled.div.attrs({
  className: 'ContentContainer',
})`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
`

export const CloseButton = styled.button.attrs({
  className: 'CloseButton',
})`
  cursor: pointer;
  border: none;
  background: none;
  height: 25px;
  width: 25px;
  > img {
    height: 100%;
    width: 100%;
  }
`

export const HeaderContainer = styled.div.attrs({
  className: 'HeaderContainer',
})`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const TabBackground = styled.div.attrs({
  className: 'TabBackground',
})(
  ({ signInIsActive }) => `
    position: absolute;
    background: ${THEME.backgroundGray};
    border-bottom: 2px solid ${THEME.mediumBlue};
    left: ${signInIsActive ? 0 : 'calc(100% - 100px)'};
    height: 40px;
    width: 100px;
    transition: all 0.5s;
  `,
)

export const TabContentContainer = styled.div.attrs({
  className: 'TabContentContainer',
})`
  // padding: 10px;
  height: 500px;
`

export const TabContainer = styled.div.attrs({
  className: 'TabContainer',
})(
  ({ isActive }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    padding: 0.5em 1em;
    color: ${isActive ? THEME.mediumBlue : 'inherit'};
    height: 40px;
    width: 100px;
    z-index: 100;
  `,
)

export const TabControllerContainer = styled.div.attrs({
  className: 'TabControllerContainer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  width: 220px;
`
