const amenityOptions = [
  {
    label: 'Community Room',
    value: 'Community Room',
  },
  {
    label: 'Den',
    value: 'Den',
  },
  {
    label: 'High Impact Doors',
    value: 'High Impact Doors',
  },
  {
    label: 'Gym',
    value: 'Gym',
  },
  {
    label: 'Laundry',
    value: 'Laundry in Unit',
  },
  {
    label: 'Outdoor Space',
    value: 'Balcony/Outdoor Space',
  },
  {
    label: 'Parking',
    value: '1 or More Parking Spaces',
  },
  {
    label: 'Pool',
    value: 'Pool',
  },
  {
    label: 'Spa/Hot Tub',
    value: 'Spa/Hot Tub',
  },
  {
    label: 'Tennis Court',
    value: 'Tennis Court',
  },
]

export default amenityOptions
