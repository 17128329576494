import styled from 'styled-components'

import THEME from '../../../constants/themes'

export const CardsContainer = styled.div.attrs({
  className: 'CardsContainer',
})`
  margin-top: 1em;
  display: flex;
  gap: 2em;
  flex-direction: column;
`

export const HeaderContainer = styled.div.attrs({
  className: 'HeaderContainer',
})`
  position: fixed;
  top: 140px;
  right: 0;
  width: calc(100% - 570px);
  height: 70px;
  z-index: 250;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 992px) {
    background: white;
    height: 70px;
    width: 100%;
    box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.2);
  }
`

export const HeaderTitle = styled.div.attrs({
  className: 'HeaderTitle',
})`
  min-width: 50px;
`

export const OnMarketContainer = styled.div.attrs({
  className: 'OnMarketContainer',
})(
  ({ isActive }) => `
    display: flex;
    align-items: center;
    height: 100%;
    width: calc(100% - 200px);
    position: relative;
    background: ${isActive ? THEME.backgroundGray : 'none'};
    &:after {
      content: '';
      height: 80%;
      position: absolute;
      right: 0;
      width: 1px;
      border-right: 1px solid rgb(230, 230, 230);
    }
    &:hover {
      background: ${THEME.backgroundGray};
    }
    @media (max-width: 992px) {
      width: 50%
    }
  `,
)

export const OnMarketTrigger = styled.div.attrs({
  className: 'OnMarketTrigger',
})`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  padding: 0 1em;
  cursor: pointer;
  user-select: none;
  .MuiSwitch-switchBase {
    &:hover {
      background: none;
    }
  }
  .MuiTouchRipple-root {
    display: none;
  }
  @media (max-width: 992px) {
    justify-content: center;
  }
`

export const OrderByContainer = styled.div.attrs({
  className: 'OrderByContainer',
})(
  ({ isActive }) => `
    display: flex;
    align-items: center;
    height: 100%;
    width: 250px;
    background: ${isActive ? THEME.backgroundGray : 'none'};
    &:hover {
      background: ${THEME.backgroundGray};
    }
    @media (max-width: 992px) {
      width: 50%
    }
  `,
)

export const OrderByTrigger = styled.div.attrs({
  className: 'OrderByTrigger',
})`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 1em;
  cursor: pointer;
  position: relative;
`

export const SavedSalesContainer = styled.div.attrs({
  className: 'SavedSalesContainer',
})`
  padding: 1em;
  width: 100%;
  @media (max-width: 992px) {
    margin-top: 70px;
  }
`

export const ResultsTitle = styled.div.attrs({
  className: 'ResultsTitle',
})`
  font-size: 18px;
  font-weight: 600px;
`
