import styled from 'styled-components'

export const PriceHistoryContainer = styled.div.attrs({
  className: 'PriceHistoryContainer',
})`
  border-bottom: 0.5px solid rgb(196, 196, 196);
  padding-bottom: 1.5em;
  @media (max-width: 992px) {
    margin: 0 20px;
    padding-bottom: 1em;
  }
`

export const PriceHistoryContentContainer = styled.div.attrs({
  className: 'PriceHistoryContentContainer',
})`
  position: relative;
  @media (max-width: 992px) {
    width: calc(100% + 40px);
    left: -20px;
  }
`

export const PriceHistoryTitleContainer = styled.div.attrs({
  className: 'PriceHistoryTitleContainer',
})`
  margin-bottom: 1em;
`
