import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  featuredListing: null,
  randomReloadParam: null,
}

export const promotionsSlice = createSlice({
  name: 'featuredListing',
  initialState,
  reducers: {
    setFeaturedListing: (state, action) => {
      state.featuredListing = action.payload
    },
    setRandomReloadParam: (state, action) => {
      state.randomReloadParam = action.payload
    },
  },
})

export const promotionsActionReducer = promotionsSlice.actions
export default promotionsSlice.reducer
