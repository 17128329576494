import { SectionTitle } from '../../ListingDetail.style'
import { Content, Details, TaxesAndFeesContainer } from './TaxesAndFees.style'

import Calculator from './Calculator'

const TaxesAndFees = ({ listing }) => {
  const priceFormatter = () =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    })

  const isForSale = listing?.type === 'FOR_SALE'

  return (
    <TaxesAndFeesContainer>
      <SectionTitle>Taxes and Fees</SectionTitle>
      <Content>
        <Details>
          <div>
            Tax Amount: {priceFormatter().format(listing?.taxAnnualAmount)} (
            {priceFormatter().format(listing?.taxAnnualAmount / 12)}/month)
          </div>
          <div>Tax Year: {listing?.taxYear}</div>
          <div>HoA Fee: {priceFormatter().format(listing?.associationFee)}</div>
          <div>Frequency: {listing?.associationFeeFrequency}</div>
        </Details>
        {/* {isForSale && <Calculator listing={listing} />} */}
      </Content>
    </TaxesAndFeesContainer>
  )
}

export default TaxesAndFees
