import React, { lazy, useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { Container } from 'semantic-ui-react'
import { useLocation } from 'react-router-dom'
import { USER_TYPE } from '../../constants/app.const'
import ReactGA from 'react-ga4'
import Box from '@mui/material/Box'

import Account from '../Account'
// import Admin from '../Admin'
import BuildingDetails from '../BuildingDetails'
import Home from '../Home'
import ListingDetails from '../ListingDetails/'
import Privacy from '../Privacy'
// import Realtors from '../Realtors'
import Register from '../Realtors/Register'
import Search from '../Search'
import Terms from '../Terms'
import ScrollToTop from './ScrollToTop'
import NewDevelopments from '../NewDevelopments'
import Blog from '../Blog'
import Post from '../Blog/Post'

const Admin = lazy(() => import('../Admin'))
const Realtors = lazy(() => import('../Realtors'))

const NotFound = () => {
  return (
    <Container style={{ marginTop: '60px', display: 'flex', justifyContent: 'center' }}>
      <Box
        sx={{
          width: 400,
          height: 400,
        }}
      >
        <h1>404 - Page Not Found</h1>
        <p>The page you're looking for does not exist.</p>
      </Box>
    </Container>
  )
}

const Navigation = ({
  currentUser,
  refetchListings,
  listingsPreviewsResponse,
  listingsGroupsResponse,
  featuredListingsResponse,
  favoritesResponse,
  savedSearchesResponse,
  loadingFeaturedListingsResponse,
  loadingListingsGroupsResponse,
  loadingListingsPreviewsResponse,
  loadingFavoritesResponse,
  savedSearchesResponseIsLoading,
  getFavorites,
  getSearches,
}) => {
  const location = useLocation()

  useEffect(() => {
    if (!ReactGA?.isInitialized) return
    ReactGA.send({
      hitType: 'pageview',
      page: location?.pathname,
      title: location?.pathname,
    })
  }, [location])

  // const userIsLoggedIn = currentUser && true
  const userIsConsumer = currentUser?.user?.type === USER_TYPE.CONSUMER
  const userIsRealtor = currentUser?.user?.type === USER_TYPE.REALTOR
  const userIsAdmin = currentUser?.user?.is_superuser

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home refetchListings={refetchListings} />} />
        <Route exact path="/privacy" Component={Privacy} />
        <Route
          exact
          path="/search"
          element={
            <Search
              refetchListings={refetchListings}
              listingsPreviewsResponse={listingsPreviewsResponse}
              listingsGroupsResponse={listingsGroupsResponse}
              featuredListingsResponse={featuredListingsResponse}
              favoritesResponse={favoritesResponse}
              savedSearchesResponse={savedSearchesResponse}
              loadingFeaturedListingsResponse={loadingFeaturedListingsResponse}
              loadingListingsGroupsResponse={loadingListingsGroupsResponse}
              loadingListingsPreviewsResponse={loadingListingsPreviewsResponse}
              loadingFavoritesResponse={loadingFavoritesResponse}
              savedSearchesResponseIsLoading={savedSearchesResponseIsLoading}
              getFavorites={getFavorites}
              getSearches={getSearches}
            />
          }
        />
        <Route exact path="/new-developments" Component={NewDevelopments} />
        <Route exact path="/terms" Component={Terms} />
        {/* <Route exact path="/blog" Component={Blog} />
        <Route exact path="/blog/:slug" Component={Post} /> */}
        <Route
          exact
          path="/:neighborhoodName/:buildingName"
          element={<BuildingDetails refetchListings={refetchListings} />}
        />
        <Route
          exact
          path="/:neighborhoodName/:buildingName/:unitNumber/:listingType"
          element={<ListingDetails refetchListings={refetchListings} />}
        />
        <Route
          exact
          path="/:neighborhoodName/:buildingName/:towerName/:unitNumber/:listingType"
          Component={ListingDetails}
        />
        <Route exact path="/realtors/register" Component={Register} />
        <Route exact path="/details/:detailId" Component={ListingDetails} />
        <Route exact path="/buildings/:buildingId" Component={BuildingDetails} />
        {userIsConsumer && <Route exact path="/account" element={<Account currentUser={currentUser} />} />}
        {userIsConsumer && <Route exact path="/account/:tab" element={<Account currentUser={currentUser} />} />}
        {userIsRealtor && <Route exact path="/realtors" element={<Realtors currentUser={currentUser} />} />}
        {userIsRealtor && <Route exact path="/realtors/:tab" element={<Realtors currentUser={currentUser} />} />}
        {userIsAdmin && <Route exact path="/admin" Component={Admin} />}
        {userIsAdmin && <Route exact path="/admin/:tab" Component={Admin} />}
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate to={'/'} />} />
      </Routes>
    </>
  )
}

export default Navigation
