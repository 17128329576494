import styled from 'styled-components'

import THEME from '../../../../../constants/themes'

export const ControllerContainer = styled.div.attrs({
  className: 'ControllerContainer',
})`
  display: flex;
  gap: 0.5em;
  margin-bottom: 20px;
  @media (max-width: 992px) {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 -10px 20px -10px;
  }
`
export const RadioButton = styled.div.attrs({
  className: 'RadioButton',
})(
  ({ selected }) => `
  flex: 1;
  text-align: center;
  border-radius: 4px;
  border: 0.5px solid #C4C4C4;
  background: #FFF;
  padding: 11px 26px;
  font-family:  'Nunito Sans', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: ${selected ? THEME.mediumBlue : THEME.backgroundGray};
  color: ${selected ? '#fff' : '#333'};
  cursor: pointer;
  @media (max-width: 992px) {
    font-size: 13px;
    padding: 5px;
    // min-width: 110px;
    // max-width: calc(100% / 3 - 4em);
    flex: 1;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
  }
  &:hover {
    opacity: 0.8;
  }
  `,
)

export const UnitTableContainer = styled.div.attrs({
  className: 'UnitTableContainer',
})`
  height: 100%;
`
