import styled from 'styled-components'
import THEME from '../../constants/themes'
import professionalBG from '../../assets/images/professional-home.svg'
import professionalMobile from '../../assets/images/professional-home-mobile.svg'

export const AdsContainer = styled.div.attrs({
  className: 'AdsContainer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  max-width: 1240px;
  flex-wrap: wrap;
  margin: auto;
  border-radius: 10px;
  > div {
    width: 100%;
    height: 200px;
    min-height: 200px;
    border-radius: 10px;
    // width: calc(100% / 3);
  }
  @media (max-width: 992px) {
    > div {
      width: 100%;
      min-height: 350px;
    }
  }
`

export const HeroContainer = styled.div.attrs({
  className: `HeroContainer`,
})`
  top: -140px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, lightblue, #f8f9fa);
  background-size: 100% 75%;
  background-repeat: no-repeat;
  overflow: visible;
  @media (max-width: 992px) {
    overflow: hidden;
    top: -70px;
    background: ${THEME.backgroundBlue};
  }
`

export const HeroTitle = styled.div.attrs({
  className: 'HeroTitle',
})`
  text-transform: none;
  font-size: 40px;
  font-weight: 400;
  line-height: 50px;
  padding: 96px 0px 50px;
  text-align: center;
  color: ${THEME.darkBlue};
`

export const RealtorBannerButton = styled.div.attrs({
  className: 'RealtorBannerButton',
})`
  color: #00335f;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.3px;
  padding: 12px 39px;
  border-radius: 4px;
  background: rgb(118, 193, 206);
  background: #76c1ce;
  border: 1px solid #76c1ce;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &:last-child {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
  }
`

export const RealtorBannerButtonsContainer = styled.div.attrs({
  className: `RealtorBannerButtonsContainer`,
})`
  display: flex;
  gap: 15px;
`

export const RealtorBannerContainer = styled.div.attrs({
  className: `RealtorBannerContainer`,
})`
  background: url(${professionalBG});
  border-radius: 20px;
  padding: 40px;
  text-align: center;
  margin: 40px auto;
  width: 1277px;
  max-width: 90%;
  height: 325px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 992px) {
    border-radius: 10px;
    width: calc(100vw - 40px) !important;
    margin: 60px 20px 50px !important;
    padding: 50px 17px !important;
    height: 433px !important;
    background-repeat: no-repeat !important;
    background-color: #00335f !important;
    background-position-y: 259px !important;
    background-position-x: 20px !important;
    justify-content: flex-start !important;
    background-image: url(${professionalMobile});
  }
`

export const RealtorBannerSubtitle = styled.h6.attrs({
  className: 'RealtorBannerSubtitle',
})`
  color: rgba(255, 255, 255, 0.85);
  text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.8px;
  font-family: 'Nunito Sans', sans-serif;
  margin-bottom: 35px;
  @media (max-width: 992px) {
    text-align: center;
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
  }
`

export const RealtorBannerTitle = styled.h4.attrs({
  className: 'RealtorBannerTitle',
})`
  color: #fff;
  text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 109.6%;
  letter-spacing: -0.8px;
  font-family: 'Objectivity', 'Nunito Sans', sans-serif;
  margin-bottom: 10pt;
  > span {
    color: #76c1ce;
  }
  @media (max-width: 992px) {
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.5px;
  }
`

export const Title = styled.h1.attrs({
  className: 'Title',
})`
  margin-bottom: 20px !important;
`

export const TitleContainer = styled.div.attrs({
  className: 'TitleContainer',
})`
  position: absolute;
  top: 195px;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  @media (max-width: 992px) {
    padding-top: 0;
    top: 80px;
  }
  h1 {
    color: #000;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 109.6%;
    letter-spacing: -0.72px;
    max-width: 843px;
    margin-bottom: 48px;
    font-family: 'Nunito Sans', sans-serif;
    span {
      color: #226597;
    }
    @media (max-width: 992px) {
      font-size: 22px;
      padding: 10px 35px;
      margin-bottom: 25px;
    }
    z-index: 10;
  }
`
