import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import useAxios from 'axios-hooks'
import moment from 'moment'

import { promotionsActionReducer } from '../../../store/promotions/promotionsSlice'
import { modalsActionReducer } from '../../../store/modals/modalsSlice'
import CLOSE_ICON from '../../../assets/images/close.svg'
import { REALTOR_FEATURED_LISTINGS } from '../../../constants/api'
import {
  Button,
  ButtonsContainer,
  CancelButton,
  CloseButton,
  ContentContainer,
  FeaturedListingContainer,
  HeaderContainer,
  Message,
  SubTitleColumn,
  SubTitleContainer,
  TitleContainer,
} from './FeaturedListing.style'

const FeaturedListing = () => {
  const containerRef = useRef()

  const dispatch = useDispatch()

  const { featuredListing } = useSelector((state) => state.promotions)

  const closeFeaturedListingModal = () => dispatch(modalsActionReducer?.setFeaturedListingModalIsOpen(false))
  const setFeaturedListing = (payload) => dispatch(promotionsActionReducer.setFeaturedListing(payload))
  const setRandomReloadParam = (payload) => dispatch(promotionsActionReducer.setRandomReloadParam(payload))

  const listing = featuredListing?.listing

  const statuses = {
    OPEN: 'Open/Available',
    PENDING: 'Pending Contract',
    CLOSED: 'Off Market',
  }

  const types = {
    FOR_RENT: 'For Rent',
    FOR_SALE: 'For Sale',
  }

  const rates = {
    FOR_RENT: '$89',
    FOR_SALE: '$179',
  }
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumSignificantDigits: 1,
  })

  const status = statuses[featuredListing?.status]
  const type = types[listing?.type]
  const rate = rates[listing?.type]

  const lastBillDate = featuredListing?.lastBillDate
    ? `Last bill on ${moment(featuredListing?.lastBillDate).format('MMM Do')}`
    : ''
  const nextBillDate = featuredListing?.nextBillDate ? moment(featuredListing?.nextBillDate).format('MMMM Do YYYY') : ''

  const title = featuredListing?.isFeatured ? 'Remove Featured Listing' : 'Feature this Listing'
  const message = featuredListing?.isFeatured
    ? `By clicking "remove" below, you will no longer be charged for this featured listing. Your listing
  will remain featured until ${nextBillDate}.`
    : `By clicking "feature" below your card will be charged ${rate} monthly until either you remove this listing from your featured listings or the listing is no longer on the market.`
  const confirmButton = featuredListing?.isFeatured ? 'Remove' : 'Feature'
  const cancelButton = featuredListing?.isFeatured ? 'Cancel and Keep Featuring' : 'Cancel and Do Not Feature'

  const [{ loading: addFeaturedListingIsLoading }, addFeaturedListing] = useAxios(
    {
      url: `${REALTOR_FEATURED_LISTINGS}`,
      method: 'POST',
      data: {
        listing: featuredListing?.listing?.uuid,
      },
    },
    { manual: true },
  )

  const [{ loading: removeFeaturedListingIsLoading }, removeFeaturedListing] = useAxios(
    {
      url: `${REALTOR_FEATURED_LISTINGS}${featuredListing?.uuid}/cancel/`,
      method: 'POST',
    },
    { manual: true },
  )

  const cancelButtonIsEnabled = !removeFeaturedListingIsLoading && !addFeaturedListingIsLoading
  const confirmButtonIsEnabled = !removeFeaturedListingIsLoading && !addFeaturedListingIsLoading

  const handleRemoveOnClick = async () => {
    await removeFeaturedListing()
    await closeFeaturedListingModal()
    await setFeaturedListing(null)
    await setRandomReloadParam(Math.random())
    toast.success('Successfully removed.')
  }

  const handleFeatureOnClick = async () => {
    const { data } = await addFeaturedListing()
    await closeFeaturedListingModal()
    await setFeaturedListing(data)
    await setRandomReloadParam(Math.random())
    toast.success('Successfully featured.')
  }

  const handleConfirmOnClick = featuredListing?.isFeatured ? handleRemoveOnClick : handleFeatureOnClick

  return (
    <FeaturedListingContainer ref={containerRef}>
      <HeaderContainer>
        <TitleContainer>{title}</TitleContainer>
        <CloseButton onClick={closeFeaturedListingModal}>
          <img src={CLOSE_ICON} alt="close" />
        </CloseButton>
      </HeaderContainer>
      <ContentContainer>
        <SubTitleContainer>
          <SubTitleColumn>
            <span>{listing?.building?.name}</span>
            <span>{listing?.address?.value}</span>
            <span>Unit {listing?.unit?.number}</span>
          </SubTitleColumn>
          <SubTitleColumn>
            <span>{type}</span>
            <span>{currencyFormatter.format(listing?.price)}</span>
          </SubTitleColumn>
        </SubTitleContainer>
        <Message>{message}</Message>
        <ButtonsContainer>
          <Button disabled={!confirmButtonIsEnabled} onClick={handleConfirmOnClick}>
            {confirmButton}
          </Button>
          <CancelButton disabled={!cancelButtonIsEnabled} onClick={closeFeaturedListingModal}>
            {cancelButton}
          </CancelButton>
        </ButtonsContainer>
      </ContentContainer>
    </FeaturedListingContainer>
  )
}

export default FeaturedListing
