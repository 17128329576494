import styled from 'styled-components'

export const BuildingLink = styled.a.attrs({
  className: 'BuildingLink',
})`
  // display: flex;
  // align-items: center;
`

export const LinksContainer = styled.div.attrs({
  className: 'LinksContainer',
})`
  @media (max-width: 992px) {
    padding: 0 20px;
  }
`

export const LinksContentContainer = styled.div.attrs({
  className: 'LinksContentContainer',
})`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  // gap: 5px;
  padding-bottom: 1.5em;
  border-bottom: 0.5px solid rgb(196, 196, 196);
`

export const NewWindowIconContainer = styled.svg.attrs({
  className: 'NewWindowIconContainer',
})`
  user-select: none;
  cursor: pointer;
  margin-left: 5px;
  padding-top: 7px;
  margin-bottom: -1px;
`
