import styled from 'styled-components'
import Skeleton from '@mui/material/Skeleton'

const buildingIsInFocusStyle = `
 .leaflet-pane.leaflet-marker-pane:before {
    content: '';
    width: 999vw;
    height: 999vh;
    background: #22222269;
    z-index: 600;
    top: 0;
    position: absolute;
    transform: translate(-50%, -50%);
  }
`

export const MapContainer = styled.div.attrs({
  className: 'MapContainer',
})(
  ({ isActive, buildingIsInFocus }) => `
    position: fixed;
    left: 700px;
    overflow: hidden;
    width: calc(100vw - 700px);
    height: calc(100vh - 220px);
    box-shadow: -4px -4px 4px -4px rgba(0, 0, 0, 0.5);
    @media (max-width: 1240px) {
      width: calc(100vw - 350px);
      left: 350px;
    }
    @media (max-width: 992px) {
      top: 134px;
      left: 0;
      width: 100vw;
      height: calc(100vh - 140px);
      border-radius: 0;
      box-shadow: none;
      z-index: ${isActive ? 100 : -100};
      .leaflet-control-container {
        display: none;
      }
    }
    ${buildingIsInFocus && buildingIsInFocusStyle}
  `,
)

export const StyledMapLoadingSkeleton = styled(Skeleton).attrs({
  className: 'StyledMapLoadingSkeleton',
})`
  width: 100% !important;
  height: 100% !important;
  display: block !important;
  position: relative !important;
  transform: none !important;
`
