import styled from 'styled-components'

import THEME from '../../../../constants/themes'

export const Button = styled.button.attrs({
  className: 'Button',
})`
  width: 100%;
  border: none;
  background: ${THEME.mediumBlue};
  padding: 10px;
  color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 10px;
  cursor: pointer;
  min-height: 44px;
  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`

export const Divider = styled.div.attrs({
  className: 'Divider',
})`
  position: relative;
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background: #eee;
  span {
    position: absolute;
    padding: 0px 10px;
    background: ${THEME.backgroundGray};
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
`

export const FormContainer = styled.form.attrs({
  className: 'FormContainer',
})`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const GoogleButton = styled.button.attrs({
  className: 'GoogleButton',
})`
  width: 100%;
  border: none;
  background: white;
  padding: 10px;
  color: #333;
  border: 1px solid #333;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 10px;
  cursor: pointer;
  min-height: 44px;
  &:hover {
    color: black;
    border-color: black;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`

export const MessageContainer = styled.div.attrs({
  className: `MessageContainer`,
})(
  ({ isError }) => `
    color: ${isError ? 'red' : 'inherit'};
    height: 20px;
    padding: 0 0.5em;
    font-size: 12px;
  `,
)

export const RegisterContainer = styled.div.attrs({
  className: 'RegisterContainer',
})`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 1em;
`

export const StyledInput = styled.input.attrs({
  className: 'StyledInput',
})(
  ({ isError }) => `
    border: 1px solid rgb(230, 230, 230);
    border-color: ${isError ? 'red' : 'default'};
    outline: none;
    box-shadow: none;
    width: 100%;
    height: 40px;
    padding: 0.5em;
    border-radius: 5px;
    &:focus {
      border-color: ${isError ? 'red' : THEME.lightBlue};
    }
  `,
)

export const TextContainer = styled.div.attrs({
  className: 'TextContainer',
})`
  text-align: center;
  height: 20px;
`
