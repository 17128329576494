const findSavedSearch = (activeParams, searches) => {
  // helper method to compare active params to saved searches

  const listsAreEqual = (list1, list2) => {
    const defaultList1 = list1 || []
    const defaultList2 = list2 || []
    if (defaultList1.length !== defaultList2.length) return false
    const sortedList1 = [...defaultList1].sort()
    const sortedList2 = [...defaultList2].sort()
    for (let i = 0; i < sortedList1.length; i++) {
      if (sortedList1[i] !== sortedList2[i]) return false
    }
    return true
  }

  const savedSearch = searches?.find((search, index) => {
    const searchAmenitiesValue = search?.amenities || {}
    const searchBathroomsValue = search?.bathrooms || null
    const searchBedsValue = search?.beds || []
    const searchMaxPriceValue = search?.maxPrice || null
    const searchMinPriceValue = search?.minPrice || null
    const searchNeighborhoodsValue = search?.neighborhoods || []
    const searchPetsValue = search?.pets || {}
    const searchSquareFeetValue = search?.squareFeet || null
    const searchTypeValue = search?.type || null
    const searchYearBuiltValue = search?.yearBuilt || null

    const activeParamsAmenitiesValue = activeParams?.amenities || {}
    const activeParamsBathroomsValue = activeParams?.bathrooms || null
    const activeParamsBedsValue = activeParams?.beds || []
    const activeParamsMaxPriceValue = activeParams?.maxPrice || null
    const activeParamsMinPriceValue = activeParams?.minPrice || null
    const activeParamsNeighborhoodsValue = activeParams?.neighborhoods || []
    const activeParamsPetsValue = activeParams?.pets || {}
    const activeParamsSquareFeetValue = activeParams?.squareFeet || null
    const activeParamsTypeValue = activeParams?.type || null
    const activeParamsYearBuiltValue = activeParams?.yearBuilt || null

    const isEqual =
      listsAreEqual(searchAmenitiesValue, activeParamsAmenitiesValue) &&
      searchBathroomsValue === activeParamsBathroomsValue &&
      listsAreEqual(searchBedsValue, activeParamsBedsValue) &&
      searchMaxPriceValue === activeParamsMaxPriceValue &&
      searchMinPriceValue === activeParamsMinPriceValue &&
      listsAreEqual(searchNeighborhoodsValue, activeParamsNeighborhoodsValue) &&
      listsAreEqual(searchPetsValue, activeParamsPetsValue) &&
      searchSquareFeetValue === activeParamsSquareFeetValue &&
      searchTypeValue === activeParamsTypeValue &&
      searchYearBuiltValue === activeParamsYearBuiltValue

    // // DEBUG:
    // console.table({
    //   amenities: {
    //     params: JSON.stringify(activeParamsAmenitiesValue),
    //     search: JSON.stringify(searchAmenitiesValue),
    //     equality: listsAreEqual(searchAmenitiesValue, activeParamsAmenitiesValue),
    //   },
    //   bathrooms: {
    //     params: activeParamsBathroomsValue,
    //     search: searchBathroomsValue,
    //     equality: searchBathroomsValue === activeParamsBathroomsValue,
    //   },
    //   beds: {
    //     params: JSON.stringify(activeParamsBedsValue),
    //     search: JSON.stringify(searchBedsValue),
    //     equality: listsAreEqual(searchBedsValue, activeParamsBedsValue),
    //   },
    //   maxPrice: {
    //     params: activeParamsMaxPriceValue,
    //     search: searchMaxPriceValue,
    //     equality: searchMaxPriceValue === activeParamsMaxPriceValue,
    //   },
    //   minPrice: {
    //     params: activeParamsMinPriceValue,
    //     search: searchMinPriceValue,
    //     equality: searchMinPriceValue === activeParamsMinPriceValue,
    //   },
    //   neighborhoods: {
    //     params: JSON.stringify(activeParamsNeighborhoodsValue),
    //     search: JSON.stringify(searchNeighborhoodsValue),
    //     equality: listsAreEqual(searchNeighborhoodsValue, activeParamsNeighborhoodsValue),
    //   },
    //   pets: {
    //     params: JSON.stringify(activeParamsPetsValue),
    //     search: JSON.stringify(searchPetsValue),
    //     equality: listsAreEqual(searchPetsValue, activeParamsPetsValue),
    //   },
    //   squareFeet: {
    //     params: activeParamsSquareFeetValue,
    //     search: searchSquareFeetValue,
    //     equality: searchSquareFeetValue === activeParamsSquareFeetValue,
    //   },
    //   type: {
    //     params: activeParamsTypeValue,
    //     search: searchTypeValue,
    //     equality: searchTypeValue === activeParamsTypeValue,
    //   },
    //   yearBuilt: {
    //     params: searchYearBuiltValue,
    //     search: activeParamsYearBuiltValue,
    //     equality: searchYearBuiltValue === activeParamsYearBuiltValue,
    //   },
    // })
    // console.log('search', index, '| isEqual', isEqual, '\n\n')

    return isEqual
  })

  return savedSearch
}

export default findSavedSearch
