import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  searchPage: 'list',
}

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setSearchPage: (state, action) => {
      state.searchPage = action.payload
    },
  },
})

export const pageActionReducer = pageSlice.actions
export default pageSlice.reducer
