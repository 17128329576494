import styled from 'styled-components'

import THEME from '../../../../constants/themes'

export const BedOption = styled.div.attrs({
  className: 'BedOption',
})(
  ({ selected }) => `
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding: 10px 14px;
    border: 1px solid #E5E5E5;
    font-weight: bold;
    text-align: center;   
    background: white;
    border-color: ${selected ? '#00669A' : null};
    z-index: ${selected ? 185 : 180};
    background-color: ${selected ? '#fafafa' : null}; 
    margin-top: 0;
    margin-bottom: 0;
    margin-right: -1px;
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
      margin-right: 0;      
    }
    &:hover {
      border-color: ${selected ? '#00669A' : null};
      background-color: ${selected ? 'white' : '#fafafa'};
      z-index: 1000;
    } 
  `,
)

export const BedOptionsContainer = styled.div.attrs({
  className: 'BedOptionsContainer',
})`
  display: flex;
  flex-direction: column;
  flex-direction: row;
  margin: 0.5em 0;
  width: 100%;
  height: 42px;
`

export const BedsFilterContainer = styled.div.attrs({
  className: 'BedsFilterContainer',
})(
  ({ isOpen }) => `
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-color: ${THEME.backgroundGray};
    &:hover {
      background-color: #fafafa;
    }
    &:after {
      content: '';
      height: 80%;
      width: 1px;
      border-right: 1px solid rgb(230, 230, 230);
    }
  `,
)

export const BedsFilterTriggerContainer = styled.div.attrs({
  className: 'BedsFilterTriggerContainer',
})`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0.5em;
`
