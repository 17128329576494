import styled from 'styled-components'

import THEME from '../../../../constants/themes'

export const Button = styled.div.attrs({
  className: 'Button',
})(
  ({ isFavorited }) => `  
    width: 150px;
    border: 1px solid ${isFavorited ? 'rgb(230, 230, 230)' : THEME.mediumBlue};
    background: ${isFavorited ? THEME.mediumBlue : 'white'};
    color: ${isFavorited ? 'white' : THEME.mediumBlue};
    padding: 1em;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  `,
)

export const ButtonsContainer = styled.div.attrs({
  className: 'ButtonsContainer',
})`
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  @media (max-width: 992px) {
    height: auto;
  }
`

export const CardContainer = styled.div.attrs({
  className: 'CardContainer',
})`
  background: white;
  border-radius: 10px;
  border: 1px solid rgb(230, 230, 230);
  cursor: pointer;
  width: 100%;
  max-width: 500px;
  > a {
    display: flex;
    padding: 1em;
    color: #333;
    height: 100%;
    width: 100%;
    gap: 1em;
    justify-content: space-between;
  }
  &:hover {
    box-shadow: 2px 4px 17px 0px rgba(0, 0, 0, 0.12);
  }
`

export const ItemContainer = styled.div.attrs({
  className: 'ItemContainer',
})`
  display: flex;
  gap: 1em;
  align-items: center;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`
