import React from 'react'
import { useDispatch } from 'react-redux'

import { searchActionReducer } from '../../../../store/search/searchSlice'
import { BED_ICON, BATH_ICON, SQURE_FOOT_ICON } from '../../../../constants/image.const'
import {
  AddressAndDetailsContianer,
  BuildingName,
  ListingAddress,
  ListingPriceAndType,
  ListingType,
  IconsContainer,
  Item,
  UnitText,
  PriceAndDetailsContainer,
  DetailsContainer,
  DetailsIconContainer,
  StyledLink,
} from './AddressAndDetails.style'
import { useNavigate } from 'react-router-dom'

const AddressAndDetails = ({ listing }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const setNeighborhoods = (payload) => dispatch(searchActionReducer.setNeighborhoods(payload))

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumSignificantDigits: 1,
  })

  const numberFormatter = new Intl.NumberFormat('en-US', {
    minimumSignificantDigits: 1,
  })

  const price = currencyFormatter.format(listing?.price)
  const listingType = listing?.type === 'FOR_RENT' ? 'For Rent' : 'For Sale'
  const size = `${numberFormatter.format(listing?.unit?.squareFootage || 0)} ft²`
  const beds = `${listing?.unit?.numberOfBedrooms || 0} ${listing?.unit?.numberOfBedrooms === 1 ? 'Bed' : 'Beds'}`
  const baths = `${listing?.unit?.numberOfBathrooms || 0} ${listing?.unit?.numberOfBathrooms === 1 ? 'Bath' : 'Baths'}`

  const handleNeighborhoodOnClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setNeighborhoods([listing?.neighborhood?.uuid])
    navigate('/search')
  }

  return (
    <AddressAndDetailsContianer>
      <BuildingName>
        {/* Unit {listing?.unit?.number} */}
        <StyledLink to={listing?.building?.url}>{listing?.building?.name}</StyledLink>
      </BuildingName>
      <ListingAddress>
        {listing?.address?.value}
        <UnitText>Unit {listing?.unit?.number}</UnitText>
        {/* <UnitText>
          <StyledLink to={listing?.building?.url}>{listing?.building?.name}</StyledLink>
        </UnitText> */}
      </ListingAddress>
      <PriceAndDetailsContainer>
        <ListingPriceAndType>
          {price}
          <ListingType>{listingType}</ListingType>
        </ListingPriceAndType>
        <DetailsContainer>
          <UnitText>
            Rental Unit in{' '}
            <StyledLink to={'/search'} onClick={handleNeighborhoodOnClick}>
              {listing?.neighborhood?.name}
            </StyledLink>
          </UnitText>
          <IconsContainer>
            <Item>
              <DetailsIconContainer src={SQURE_FOOT_ICON} alt="square-feet" />
              {size}
            </Item>
            <Item>
              <DetailsIconContainer src={BED_ICON} alt="beds" />
              {beds}
            </Item>
            <Item>
              <DetailsIconContainer src={BATH_ICON} alt="baths" />
              {baths}
            </Item>
          </IconsContainer>
        </DetailsContainer>
      </PriceAndDetailsContainer>
    </AddressAndDetailsContianer>
  )
}

export default AddressAndDetails
