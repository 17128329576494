import styled from 'styled-components'

export const MapContainer = styled.div.attrs({
  className: 'MapContainer',
})`
  @media (max-width: 992px) {
    padding: 0 20px;
  }
`

export const MapContentContainer = styled.div.attrs({
  className: 'MapContentContainer',
})`
  height: 400px;
  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 20px;
  }
  @media (max-width: 786px) {
    grid-template-columns: 1fr 1fr;
    height: 350px;
    margin: 0 -10px -10px -10px;
  }
`
