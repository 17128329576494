import styled from 'styled-components'

import THEME from '../../../constants/themes'

export const Button = styled.button.attrs({
  className: 'Button',
})`
  width: 100%;
  border: none;
  background: ${THEME.mediumBlue};
  padding: 10px;
  color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 10px;
  cursor: pointer;
  border-radius: 5px;
  min-height: 44px;
  flex: 1;
  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`

export const ButtonsContainer = styled.div.attrs({
  className: 'ButtonsContainer',
})`
  display: flex;
  wodth: 100%;
  gap: 0.5em;
  width: 100%;
`

export const CancelButton = styled.button.attrs({
  className: 'CancelButton',
})`
  width: 100%;
  border: none;
  color: ${THEME.mediumBlue};
  border: 1px solid ${THEME.mediumBlue};
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 10px;
  cursor: pointer;
  min-height: 44px;
  flex: 1;
  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`

export const ContentContainer = styled.div.attrs({
  className: 'ContentContainer',
})`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
`

export const CloseButton = styled.button.attrs({
  className: 'CloseButton',
})`
  cursor: pointer;
  border: none;
  background: none;
  height: 25px;
  width: 25px;
  position: absolute;
  right: 25px;
  top: 25px;
  > img {
    height: 100%;
    width: 100%;
  }
`

export const FeaturedListingContainer = styled.div.attrs({
  className: 'FeaturedListingContainer',
})`
  display: flex;
  background: ${THEME.backgroundGray};
  flex-direction: column;
  padding: 2em 3em;
  border-radius: 10px;
  position: relative;
  max-width: 420px;
  @media (max-width: 992px) {
    height: 100%;
    width: 100%;
    border-radius: 0;
    padding: 1em;
  }
`

export const HeaderContainer = styled.div.attrs({
  className: 'HeaderContainer',
})`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const Message = styled.p.attrs({
  className: 'Message',
})`
  // max-width: 330px;
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  width: calc(100% + 30px);
`

export const OptionContainer = styled.div.attrs({
  className: 'OptionContainer',
})`
  display: flex;
  flex-direction: column;
`

export const OptionTitle = styled.div.attrs({
  className: 'OptionTitle',
})`
  font-weight: 600;
  font-size: 15px;
`

export const OptionsContainer = styled.div.attrs({
  className: 'OptionsContainer',
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
  width: 100%;
  padding-bottom: 10px;
  // margin-bottom: 10px;
  border-bottom: 1px solid rgb(230, 230, 230);
`

export const StyledInput = styled.input.attrs({
  className: 'StyledInput',
})`
  border: 1px solid rgb(230, 230, 230);
  outline: none;
  box-shadow: none;
  width: 100%;
  height: 40px;
  padding: 0.5em;
  border-radius: 5px;
  min-width: 200px;
  &:focus {
    border-color: ${THEME.lightBlue};
  }
`

export const SubTitleContainer = styled.div.attrs({
  className: 'SubTitleContainer',
})`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  font-size: 15px;
`

export const SubTitleColumn = styled.div.attrs({
  className: 'SubTitleColumn',
})`
  display: flex;
  flex-direction: column;
  &:last-child {
    align-items: flex-end;
  }
`

export const TitleContainer = styled.div.attrs({
  className: 'TitleContainer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  padding: 0.5em 1em;
  height: 40px;
  width: 100%;
  z-index: 100;
`
