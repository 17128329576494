import Grid from '@mui/material/Unstable_Grid2'

import styled from 'styled-components'

export const ActionCardBodyContainer = styled.div.attrs({
  className: 'ActionCardBodyContainer',
})`
  border-radius: 15px;
  background: #113f67;
  padding: 90px 40px;
  position: relative;
  max-width: 401px;
  height: 100%;
  top: 0;
  .wave-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  transition: all 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
    transform: scale(1.03);
  }
`

export const ActionCardContainer = styled(Grid).attrs({
  className: 'ActionCardContainer',
})`
  position: relative;
`

export const ActionCardContentContainer = styled.div.attrs({
  className: 'ActionCardContentContainer',
})`
  color: #f3f3f3;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 22px;
`

export const ActionCardHeaderImage = styled.img.attrs({
  className: 'ActionCardHeaderImage',
})`
  position: absolute;
  top: -40px;
  left: 40px;
  z-index: 150;
`

export const ActionCardsContainer = styled.div.attrs({
  className: 'ActionCardsContainer',
})`
  margin: 80px;
  @media (max-width: 992px) {
    margin: 80px 20px;
    & > div {
      gap: 60px !important;
    }
  }
`

export const ActionCardTitleContainer = styled.div.attrs({
  className: 'ActionCardTitleContainer',
})`
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 109.6%;
  letter-spacing: -0.5px;
  color: #fff;
  text-align: left;
  margin-bottom: 30px;
  font-family: 'Nunito Sans', sans-serif;
`

export const CallToActionContainer = styled.div.attrs({
  className: 'CallToActionContainer',
})`
  color: rgb(255, 255, 255);
  font-family: 'Nunito Sans', sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.17px;
  text-align: left;
  margin-top: 74px;
  display: flex;
  gap: 8px;
`
