import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga4'

import SaveSearchButton from './SaveSearchButton'
import { SubHeaderContainer, SubHeaderButton } from './SubHeader.style'
import { ReactComponent as SORT_ICON } from '../../../assets/images/sort.svg'
import { ReactComponent as FILTER_ICON } from '../../../assets/images/filter.svg'
import { panelsActionReducer } from '../../../store/panels/panelsSlice'
import clickEvents from '../../../services/analytics.service'

const SubHeader = ({ savedSearchesResponseIsLoading }) => {
  const dispatch = useDispatch()

  const { ordering } = useSelector((state) => state.search)
  const selectedFilterOptions = useSelector((state) => state.search)

  const openFilterPanel = () => dispatch(panelsActionReducer.setNewDevelopmentsFilterPanelIsOpen(true))
  const openOrderingPanel = () => dispatch(panelsActionReducer.setNewDevelopmentsOrderingPanelIsOpen(true))

  const defaultSortSelected = ordering === '-on_market_date'
  const atLeastOneFilterSelected = !!(
    Object.keys(selectedFilterOptions?.amenities || {})?.filter((key) => selectedFilterOptions?.amenities[key])
      ?.length ||
    selectedFilterOptions?.bathrooms ||
    selectedFilterOptions?.beds?.length ||
    selectedFilterOptions?.maxPrice ||
    selectedFilterOptions?.minPrice ||
    selectedFilterOptions?.neighborhoods?.length ||
    Object.keys(selectedFilterOptions?.pets || {})?.filter((key) => selectedFilterOptions?.pets[key])?.length ||
    selectedFilterOptions?.squareFeet ||
    selectedFilterOptions?.yearBuilt
  )

  const handleFiltersOnClick = () => {
    openFilterPanel()
    ReactGA.event({
      category: 'action',
      action: 'new-developments-page-mobile-filter-panel-clicked',
    })
    clickEvents.NEW_DEVELOPMENTS_MOBILE_FILTER_PANEL()
  }

  const handleSortByOnClick = () => {
    openOrderingPanel()
    ReactGA.event({
      category: 'action',
      action: 'new-developments-page-mobile-ordering-panel-clicked',
    })
    clickEvents.NEW_DEVELOPMENTS_MOBILE_ORDERING_PANEL()
  }

  return (
    <SubHeaderContainer className="hidden-desktop">
      <SubHeaderButton onClick={handleFiltersOnClick} isActive={atLeastOneFilterSelected}>
        <FILTER_ICON /> Filters
      </SubHeaderButton>
      <SubHeaderButton onClick={handleSortByOnClick} isActive={!defaultSortSelected}>
        <SORT_ICON /> Sort By
      </SubHeaderButton>
      <SaveSearchButton isLoading={savedSearchesResponseIsLoading} />
    </SubHeaderContainer>
  )
}

export default SubHeader
