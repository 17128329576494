import styled from 'styled-components'

import THEME from '../../../../constants/themes'

export const HeartIconButton = styled.button.attrs({
  className: 'HeartIconButton',
})`
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  border: none;
  height: 100%;
  width: 100%;
  color: rgb(3, 39, 101);
  white-space: nowrap;
  border-radius: 100%;
  background: #00669a;
  color: #fff;
  &:hover {
    background-color: #006195;
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  > img {
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const HeartIconButtonMobile = styled.button.attrs({
  className: 'HeartIconButtonMobile',
})`
  height: 40px;
  width: 40px;
  white-space: nowrap;
  border-radius: 4px;
  border: none;
  background: #fff;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.28px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  gap: 6px;
`

export const SaveSearchButtonContainer = styled.div.attrs({
  className: 'SaveSearchButtonContainer',
})`
  position: absolute;
  right: 0;
  right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
`

export const StyledInput = styled.input.attrs({
  className: 'StyledInput',
})`
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(148, 148, 148);
  border-radius: 0px;
  color: rgb(26, 31, 44);
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  line-height: 22px;
  padding: 8px 15px;
  text-transform: none;
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
`

export const SubmitSaveSearchButton = styled.button.attrs({
  className: 'SubmitSaveSearchButton',
})(
  ({ selection }) => `
  text-transform: uppercase;
  display: flex;
  min-width: auto;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  height: 100%;
  cursor: pointer;
  border: none;
  min-height: 38px;
  color: rgb(3, 39, 101);
  &:hover {
    background-color: #006195;
  }
  width: 100%;
  background: #00669A;
  color: #fff;
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  ${selection ? `background-color: ${THEME.whiteBlue};` : null}
  `,
)
