import styled from 'styled-components'

export const HeroImagesContainer = styled.div.attrs({
  className: 'HeroImagesContainer',
})`
  width: 100%;
  height: 100vh;
  min-height: 450px;
  position: relative;
  top: 70px;
  overflow: visible;
  @media (max-width: 992px) {
    height: 100svh;
  }
`

export const SVGImg = styled.img.attrs({
  className: 'SVGImg',
})`
  position: absolute;
  bottom: 50px;
  z-index: 0;
  width: 100%;
  overflow: visible;
`
