import styled from 'styled-components'
import { Link } from 'react-router-dom'

import THEME from '../../../../../../../constants/themes'

export const Icon = styled.div.attrs({
  className: 'Icon',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  width-space: nowrap;
  text-align: center;

  > svg {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
`

export const IconImage = styled.img.attrs({
  className: 'IconImage',
})`
  width: 12px;
  height: 12px;
  margin-right: 3px;
`

export const ListingCardContainer = styled.div.attrs({
  className: 'ListingCardContainer',
})`
  overflow: hidden;
  cursor: pointer;
  display: flex;
  height: 85px;
  min-height: 85px;
  border-radius: 10px;
  border: 1px solid #dee2e7;
  box-shadow: none;
  background: white;
  &:first-child {
    margin-top: 10px;
  }
  &:last-child {
    margin-bottom: 10px;
  }
  &:hover {
    background: ${THEME.backgroundGray};
  }
`

export const ListingCardContent = styled.div.attrs({
  className: 'ListingCardContent',
})`
  color: #333;
  padding: 10px;
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ListingCardLink = styled(Link).attrs({
  className: 'ListingCardLink',
})`
  display: contents;
`

export const ListingCardImage = styled.img.attrs({
  className: 'ListingCardImage',
})`
  width: 120px;
  min-width: 120px;
  height: 100%;
  object-fit: cover;
`

export const UnitDetails = styled.div.attrs({
  className: 'UnitDetails',
})`
  display: flex;
  justify-content: space-between;
  color: rgb(51, 51, 51);
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const UnitTitle = styled.div.attrs({
  className: 'UnitTitle',
})`
  display: flex;
  justify-content: space-between;
`
