import styled from 'styled-components'

import THEME from '../../../../constants/themes'

export const BedOption = styled.div.attrs({
  className: 'BedOption',
})(
  ({ selected }) => `
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding: 10px 0;
    border: 1px solid #E5E5E5;
    font-weight: bold;
    text-align: center;
    margin-top: -1px;
    background: white;
    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-bottom: 1px;
    }
    &:hover {
      border-color: ${selected ? '#00669A' : null};
      background-color: ${selected ? 'white' : '#fafafa'};
      z-index: 1000;
    } 
    border-color: ${selected ? '#00669A' : null};
    z-index: ${selected ? 1500 : 500};
    background-color: ${selected ? '#fafafa' : null};    
  `,
)

export const BedOptionsContainer = styled.div.attrs({
  className: 'BedOptionsContainer',
})`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const BedsDropdownContainer = styled.div.attrs({
  className: 'BedsDropdownContainer',
})(
  ({ isOpen }) => `
    visibility: ${isOpen ? 'visible' : 'hidden'};
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    z-index: 185 !important;
    border-radius: 0 0 10px 10px !important;
    margin-top: -10px !important;
    width: calc(100% + 1px);
    max-width: calc(100% + 1px);
    padding: 0.5em;
    left: -1px;
    background: ${THEME.backgroundGray} !important;
    border: 1px solid rgb(230, 230, 230) !important;
    border-top: none !important;
    position: absolute;
    top: 75px;  
    // box-shadow: 0px -4px -4px 1px rgba(0, 0, 0, 0.2);
  `,
)

export const BedsFilterContainer = styled.div.attrs({
  className: 'BedsFilterContainer',
})(
  ({ isOpen }) => `
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    min-width: 120px;
    background-color: ${isOpen ? '#fafafa' : 'white'};
    &:hover {
      background-color: #fafafa;
    }
    &:after {
      content: '';
      height: 80%;
      width: 1px;
      border-right: 1px solid rgb(230, 230, 230);
    }
  `,
)

export const BedsFilterTriggerContainer = styled.div.attrs({
  className: 'BedsFilterTriggerContainer',
})`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 1em;
  @media (max-width: 1240px) {
    padding: 0 0.5em;
  }
`

export const ButtonsContainer = styled.div.attrs({
  className: 'ButtonsContainer',
})`
  display: flex;
  align-items: center;
  border-radius: 4px;
  gap: 5px;
  margin-top: 5px;
  flex-direction: column;
  @media (max-width: 992px) {
    flex-direction: row;
  }
`

export const DoneButton = styled.div.attrs({
  className: 'DoneButton',
})`
  display: flex;
  min-width: auto;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  height: 100%;
  cursor: pointer;
  border-radius: 4px;
  min-height: 41px;
  width: 100%;
  background: ${THEME.mediumBlue};
  color: #fff;
  &:hover {
    background: ${THEME.darkBlue};
  }
`

export const IconContainer = styled.div.attrs({
  className: 'IconContainer',
})(
  ({ isHidden }) => `
    display: ${isHidden ? 'none' : 'flex'};
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  `,
)

export const IconsContainer = styled.div.attrs({
  className: 'IconsContainer',
})`
  height: 100%;
  display: flex;
  align-items: center;
`

export const ResetButton = styled.div.attrs({
  className: 'ResetButton',
})`
  display: flex;
  min-width: auto;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  height: 100%;
  cursor: pointer;
  border-radius: 4px;
  min-height: 41px;
  color: ${THEME.mediumBlue};
  width: 100%;
  &:hover {
    background-color: #eee;
  }
`
