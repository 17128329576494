import styled from 'styled-components'

export const StyledInput = styled.input.attrs({
  className: 'StyledInput',
})`
  background: white;
  color: black;
  height: 34px;
  border: none;
  border-bottom: 0.5px solid #aaa;
  padding: 10px;
  width: 100%;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  // transition: 0.2s all ease-in-out;
  border-radius: 3px;
  &::-webkit-input-placeholder {
    color: #ccc;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
`
