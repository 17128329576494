import {
  DetailsTableContainer,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
} from './DetailsTable.style'

const DetailsTable = ({ columns, data }) => {
  const headerCells = columns?.map((column) => {
    return (
      <TableHeaderCell key={column.value} numberOfColumns={columns?.length} numberOfRows={data?.length}>
        {column.label}
      </TableHeaderCell>
    )
  })

  const tableRows = data?.map((row, index) => {
    const tableCells = columns?.map((column) => (
      <TableBodyCell key={`${column.value}-${index}`}>{row[column?.value] || ' '}</TableBodyCell>
    ))

    return (
      <TableBodyRow index={index} key={index}>
        {tableCells}
      </TableBodyRow>
    )
  })

  return (
    <DetailsTableContainer>
      <TableContainer>
        <TableHeader>
          <TableHeaderRow>{headerCells}</TableHeaderRow>
        </TableHeader>
        <TableBody>{tableRows}</TableBody>
      </TableContainer>
    </DetailsTableContainer>
  )
}

export default DetailsTable
