import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Loader } from 'semantic-ui-react'
import useAxios from 'axios-hooks'
import moment from 'moment'

import { CONSUMER_SAVED_SEARCHES } from '../../../../constants/api'
import { searchActionReducer } from '../../../../store/search/searchSlice'
import { Button, CardContainer, ItemContainer } from './Item.style'

const Item = ({ savedSearch }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [favoriteIsLoading, setFavoriteIsLoading] = useState(false)
  const [isFavorited, setIsFavorited] = useState(true)

  const [{ data: newSavedSearch }, addFavorite] = useAxios(
    {
      url: CONSUMER_SAVED_SEARCHES,
      method: 'POST',
      data: savedSearch,
    },
    { manual: true },
  )

  const [, removeFavorite] = useAxios(
    {
      url: `${CONSUMER_SAVED_SEARCHES}${newSavedSearch?.uuid || savedSearch?.uuid}/`,
      method: 'DELETE',
    },
    { manual: true },
  )

  const handleFavoriteOnClick = () => {
    if (favoriteIsLoading) return
    setFavoriteIsLoading(true)
    if (isFavorited)
      removeFavorite().then(() => {
        setFavoriteIsLoading(false)
        setIsFavorited(false)
      })
    else
      addFavorite().then(() => {
        setFavoriteIsLoading(false)
        setIsFavorited(true)
      })
  }

  const formatCurrency = (value) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      // minimumSignificantDigits: 1,
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: value > 999 && value < 9999 ? 1 : 0,
      maximumFractionDigits: 1,
    })

  const setSearchParams = () => {
    dispatch(searchActionReducer.setAmenities(savedSearch?.amenities))
    dispatch(searchActionReducer.setBathrooms(savedSearch?.bathrooms))
    dispatch(searchActionReducer.setBeds(savedSearch?.beds))
    dispatch(searchActionReducer.setMaxPrice(savedSearch?.maxPrice))
    dispatch(searchActionReducer.setMinPrice(savedSearch?.minPrice))
    dispatch(searchActionReducer.setNeighborhoods(savedSearch?.neighborhoods))
    dispatch(searchActionReducer.setPets(savedSearch?.pets))
    dispatch(searchActionReducer.setSquareFeet(savedSearch?.squareFeet))
    dispatch(searchActionReducer.setType(savedSearch?.type))
    dispatch(searchActionReducer.setYearBuilt(savedSearch?.yearBuilt))
  }

  const setAmenitiesFilter = () => {
    const amenities = savedSearch?.amenities
    const numberOfOptionsSelected = amenities?.length
    const message =
      numberOfOptionsSelected === 1
        ? '1 Option Selected'
        : numberOfOptionsSelected > 1
        ? `${numberOfOptionsSelected} Options Selected`
        : 'Select Amenities'
    dispatch(searchActionReducer?.setAmenitiesContainer(message))
  }

  const setBedFilter = () => {
    const beds = savedSearch?.beds
    const minBeds = beds?.length > 0 && Math.min(...beds)
    const maxBeds = beds?.length > 0 && Math.max(...beds)
    const message = beds?.length
      ? minBeds === maxBeds
        ? minBeds === 4
          ? '4+ beds'
          : minBeds === 1
          ? `${minBeds} bed`
          : `${minBeds} beds`
        : maxBeds === 4
        ? `${minBeds}+ beds`
        : `${minBeds} - ${maxBeds}`
      : 'Any'
    dispatch(searchActionReducer.setBedsContainer(message))
  }

  const setMoreFilter = () => {
    const { bathrooms, pets, squareFeet, yearBuilt } = savedSearch
    const petsSelected = !!pets?.length
    const selection = bathrooms || squareFeet || yearBuilt || petsSelected
    const bathroomsInput = !!bathrooms ? `${bathrooms} or more` : 'Any'
    const squareFeetInput = !!squareFeet ? `${squareFeet}+` : 'Any'
    const yearBuiltInput = !!yearBuilt ? `${yearBuilt}` : 'In or After'
    const message = selection ? '1+ Options Selected' : 'Browse Other Options'
    dispatch(searchActionReducer.setBathroomsInput(bathroomsInput))
    dispatch(searchActionReducer.setSquareFeetInput(squareFeetInput))
    dispatch(searchActionReducer.setYearBuiltInput(yearBuiltInput))
    dispatch(searchActionReducer.setMoreContainer(message))
  }

  const setPriceFilter = () => {
    const maxFormatted = !!savedSearch?.minPrice
      ? formatCurrency(savedSearch?.maxPrice).format(savedSearch?.maxPrice)
      : ''
    const minFormatted = !!savedSearch?.minPrice
      ? formatCurrency(savedSearch?.minPrice).format(savedSearch?.minPrice)
      : ''
    const priceMessage =
      !!savedSearch?.minPrice && !!savedSearch?.maxPrice
        ? `${minFormatted} - ${maxFormatted}`
        : !!savedSearch?.minPrice
        ? `At least ${minFormatted}`
        : !!savedSearch?.maxPrice
        ? `${maxFormatted} or less`
        : 'Any'
    dispatch(searchActionReducer.setMaxPriceInput(maxFormatted))
    dispatch(searchActionReducer.setMinPriceInput(minFormatted))
    dispatch(searchActionReducer.setPriceContainer(priceMessage))
  }

  const handleSavedSearchOnClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setSearchParams()
    setAmenitiesFilter()
    setBedFilter()
    setMoreFilter()
    setPriceFilter()
    navigate('/search/')
  }

  const date = moment(savedSearch?.createdAt).format('MMM Do, YYYY')

  return (
    <ItemContainer>
      <CardContainer>
        <Link to="/search" onClick={handleSavedSearchOnClick}>
          <span>{date}</span> <span>{savedSearch.name}</span>
        </Link>
      </CardContainer>
      <Button onClick={handleFavoriteOnClick} isFavorited={isFavorited}>
        {favoriteIsLoading ? (
          <Loader size="mini" inline="centered" active color="white" inverted={isFavorited} />
        ) : isFavorited ? (
          'Remove'
        ) : (
          'Restore'
        )}
      </Button>
    </ItemContainer>
  )
}

export default Item
