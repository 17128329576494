import styled from 'styled-components'
import { Skeleton } from '@mui/material'

export const ImagesContainer = styled.div.attrs({
  className: 'ImagesContainer',
})`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5em;
  padding: 0;
  width: 100%;
  @media (max-width: 992px) {
    position: relative;
  }
`

export const LoaderContainer = styled.div.attrs({
  className: 'LoaderContainer',
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  @media (max-width: 992px) {
    gap: 1em;
    margin-bottom: 20px;
  }
`

export const LoadingSkeleton = styled(Skeleton)`
  width: 100% !important;
  display: block !important;
  position: relative !important;
  transform: none !important;
  width: 100% !important;
  height: 100% !important;
`

export const PrimaryImageContainer = styled.div.attrs({
  className: 'PrimaryImageContainer',
})`
  cursor: pointer;
  overflow: unset;
  container-type: inline-size;
  height: 430px;
  flex: 1 1 53%;
  @media (max-width: 992px) {
    height: 300px;
  }
`

export const SecondaryImageContainer = styled.div.attrs({
  className: 'SecondaryImageContainer',
})`
  height: 430px;
  flex: 1 1 47%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1.5em;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: 992px) {
    display: none;
  }
`

export const TextContainer = styled.div.attrs({
  className: 'TextContainer',
})`
  height: 40px;
  @media (max-width: 992px) {
    margin: 0 20px;
  }
`
