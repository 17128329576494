import styled from 'styled-components'

export const BackgroundContainer = styled.div.attrs({
  className: 'BackgroundContainer',
})(
  ({ isOpen }) => `
    display: ${isOpen ? 'flex' : 'none'};
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0 , 0, 0, 0.8);
    z-index: 600;
    align-items: center;
    justify-content: center;
  `,
)
