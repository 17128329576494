import React, { useState } from 'react'
import ReactGA from 'react-ga4'

import { BUILDING_DEFAULT, BUILDING_DEFAULT_2 } from '../../../../constants/image.const'
import SlideShow from '../../../SlideShow'
import {
  Image,
  ImagesContainer,
  PhotoBadge,
  PrimaryImageContainer,
  SecondaryImageContainer,
  StatusBadge,
} from './Images.style'
import clickEvents from '../../../../services/analytics.service'

export const Images = ({ building }) => {
  const [slideShowIsOpen, setSlideShowIsOpen] = useState(false)

  const sortedListingMedias = building?.medias?.sort((a, b) => a.order - b.order)

  const numberOfImages = sortedListingMedias?.length
  const buildingHasImages = !!numberOfImages
  const showTwoImages = !buildingHasImages || numberOfImages > 1
  const firstImage = buildingHasImages && sortedListingMedias[0]
  const secondImage = buildingHasImages && sortedListingMedias.length > 1 && sortedListingMedias[1]
  const firstImageSrc =
    firstImage?.file?.replace('https://dvvjkgh94f2v6.cloudfront.netl/', 'https://images.oceanpads.com/tr:w-760') ||
    BUILDING_DEFAULT
  const secondImageSrc =
    secondImage?.file?.replace('https://dvvjkgh94f2v6.cloudfront.netl/', 'https://images.oceanpads.com/tr:w-450') ||
    BUILDING_DEFAULT_2
  const numberOfExtraPhotos = sortedListingMedias?.length - (firstImage ? 1 : 0) - (secondImage ? 1 : 0)
  const buildingHasMorePhotos = !!numberOfExtraPhotos

  const handleImageOnClick = () => {
    if (!firstImage) return
    setSlideShowIsOpen(true)
    ReactGA.event({
      category: 'action',
      action: `building-page-building-image-clicked`,
    })
    clickEvents.BUILDING_IMAGE()
  }

  const primaryAlt = `Primary photo of ${building?.name} in ${building?.neighborhood?.name}`
  const secondaryAlt = `Secondary photo of ${building?.name} in ${building?.neighborhood?.name}`

  return (
    <ImagesContainer>
      <PrimaryImageContainer>
        <Image onClick={handleImageOnClick} src={firstImageSrc} alt={primaryAlt} />
      </PrimaryImageContainer>
      {showTwoImages && (
        <SecondaryImageContainer>
          <Image onClick={handleImageOnClick} src={secondImageSrc} alt={secondaryAlt} />
          {buildingHasMorePhotos && (
            <PhotoBadge onClick={handleImageOnClick}>+ {numberOfExtraPhotos} photos</PhotoBadge>
          )}
        </SecondaryImageContainer>
      )}
      {slideShowIsOpen && <SlideShow setSlideShowIsOpen={setSlideShowIsOpen} images={sortedListingMedias} />}
      {building?.isANewDevelopment && <StatusBadge>Pre Construction</StatusBadge>}
    </ImagesContainer>
  )
}

export default Images
