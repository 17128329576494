const buildingAmenityOptions = [
  {
    label: '24-Hour Doorman',
    value: '24-Hour Doorman',
  },
  {
    label: 'Elevator',
    value: 'Elevator',
  },
  {
    label: 'Fitness Center',
    value: 'Fitness Center',
  },
  {
    label: "Kid's Lounge",
    value: "Kid's Lounge",
  },
  {
    label: 'Lounge',
    value: 'Lounge',
  },
  {
    label: 'Other Amenities',
    value: 'Other Amenities',
  },
  {
    label: 'Outdoor Pool',
    value: 'Outdoor Pool',
  },
  {
    label: 'Spa / Wellness',
    value: 'Spa / Wellness',
  },
  {
    label: 'Tennis Court',
    value: 'Tennis',
  },
  {
    label: 'Valet Parking',
    value: 'Valet Parking',
  },
]

export default buildingAmenityOptions
