import { useState, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'
import 'react-toastify/dist/ReactToastify.css'

import { authActionReducer } from '../store/auth/authSlice'
import SearchController from './SearchController'
import DataProvider from './DataProvider'
import * as AuthService from '../services/auth.service'
import '../fonts/fonts.css'

ReactPixel.init('450332831315604')
// ReactPixel.revokeConsent() // GDPR compliance

const OceanPads = () => {
  const [isLoading, setLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState()
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    let userData = AuthService.getCurrentUser()
    if (user) {
      userData = user
    }
    if (userData) {
      setCurrentUser(userData)
      dispatch(authActionReducer.setUser(userData))
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  if (isLoading) return <></>

  return (
    <>
      <SearchController currentUser={currentUser} setCurrentUser={setCurrentUser} />
      <ToastContainer
        draggable
        closeOnClick
        pauseOnHover
        hideProgressBar
        position="top-center"
        autoClose={5000}
        newestOnTop={false}
        rtl={false}
        theme="colored"
      />
      <DataProvider />
    </>
  )
}

export default OceanPads
