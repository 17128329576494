import styled from 'styled-components'
import THEME from '../../../constants/themes'

export const DoneButton = styled.button.attrs({
  className: 'DoneButton',
})`
  display: flex;
  height: 50px;
  width: 50%;
  align-items: center;
  justify-content: center;
  padding: 11px;
  border-radius: 4px;
  background: #226597;
  border: none;
  color: #fff;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const HeaderTitle = styled.div.attrs({
  className: 'HeaderTitle',
})`
  margin: 0;
  color: #000;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const FilterPanelBodyContainer = styled.div.attrs({
  className: 'FilterPanelBodyContainer',
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
  gap: 1em;
  margin-top: 70px;
`

export const FilterPanelContainer = styled.div.attrs({
  className: 'FilterPanelContainer',
})(
  ({ isOpen }) => `
  position: fixed;
  left: ${isOpen ? 0 : '-100vw'};
  background: #fff;
  flex-direction: column;
  width: 100vw;
  z-index: 300;
  align-items: start;
  justify-content: start;
  transition: 0.5s left;
  overflow: auto;
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  top: 0;
  background: rgb(248, 249, 250);
`,
)

export const FilterPanelFooterContainer = styled.div.attrs({
  className: 'FilterPanelFooterContainer',
})`
  display: flex;
  align-items: flex-start;
  padding: 1em;
  width: 100%;
  gap: 1em;
  height: 140px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  margin-top: auto;
  background: ${THEME.backgroundGray};
`

export const FilterPanelHeaderContainer = styled.div.attrs({
  className: 'FilterPanelHeaderContainer',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 27px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  // background: #fff;
  z-index: 600;
  position: fixed;
  background: ${THEME.backgroundGray};
`

export const ResetButton = styled.button.attrs({
  className: 'ResetButton',
})`
  display: flex;
  height: 50px;
  width: 50%;
  align-items: center;
  justify-content: center;
  padding: 11px;
  border-radius: 4px;
  background: #fff;
  border: none;
  color: #226597;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`
