import {
  ICONS_DOORMAN,
  ICONS_ELEVATOR,
  ICONS_GYM,
  ICONS_KIDS_LOUNGE,
  ICONS_LOUNGE,
  ICONS_OTHER_AMENITIES,
  ICONS_POOL,
  ICONS_SPA,
  ICONS_TENNIS,
  ICONS_VALET,
} from '../../../../constants/image.const'
import { SectionTitle } from '../../BuildingDetails.style'
import {
  AmenitiesContainer,
  AmenityContainer,
  AmenitiesContentContainer,
  AmenityIcon,
  AmenityText,
} from './Amenities.style'

const Amenities = ({ building }) => {
  const iconsMap = {
    '24-Hour Doorman': ICONS_DOORMAN,
    Elevator: ICONS_ELEVATOR,
    'Fitness Center': ICONS_GYM,
    "Kid's Lounge": ICONS_KIDS_LOUNGE,
    Lounge: ICONS_LOUNGE,
    'Other Amenities': ICONS_OTHER_AMENITIES,
    'Outdoor Pool': ICONS_POOL,
    'Spa / Wellness': ICONS_SPA,
    Tennis: ICONS_TENNIS,
    'Valet Parking': ICONS_VALET,
  }

  const amenities = building?.amenities?.map((amenity, index) => {
    const icon = iconsMap[amenity]
    return (
      <AmenityContainer key={index}>
        {icon && <AmenityIcon src={icon} alt={amenity} />}
        <AmenityText>{amenity}</AmenityText>
      </AmenityContainer>
    )
  })

  return (
    <AmenitiesContainer>
      <SectionTitle>Amenities</SectionTitle>
      <AmenitiesContentContainer>{amenities}</AmenitiesContentContainer>
    </AmenitiesContainer>
  )
}

export default Amenities
