import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga4'

import SearchBar from '../SearchBar'
import { modalsActionReducer } from '../../../store/modals/modalsSlice'
import { searchActionReducer } from '../../../store/search/searchSlice'
import { NavbarItem, StyledLink, SubHeaderContainer, SubHeaderContentContainer } from './SubHeader.style'
import clickEvents from '../../../services/analytics.service'

const SubHeader = ({ refetchListings }) => {
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth)

  const setType = (payload) => dispatch(searchActionReducer.setType(payload))
  const resetMaxPrice = () => dispatch(searchActionReducer.setMaxPrice(null))
  const resetMinPrice = () => dispatch(searchActionReducer.setMaxPrice(null))
  const resetMaxPriceInput = () => dispatch(searchActionReducer.setMaxPriceInput(''))
  const resetMinPriceInput = () => dispatch(searchActionReducer.setMinPriceInput(''))
  const resetPriceContainer = () => dispatch(searchActionReducer.setPriceContainer('Any'))

  const openAuthModal = () => dispatch(modalsActionReducer.setAuthModalIsOpen(true))
  const setListingType = (payload) => dispatch(searchActionReducer.setType(payload))

  const userIsLoggedIn = !!user

  const handleTypeOnChange = (type) => {
    setType(type)
    setListingType(type)
    resetMaxPrice()
    resetMinPrice()
    resetMaxPriceInput()
    resetMinPriceInput()
    resetPriceContainer()
    refetchListings(
      {
        page: 1,
        price__gte: null,
        price__lte: null,
        type: type,
      },
      'TypeFilter.handleTypeChange',
    )
    ReactGA.event({
      category: 'action',
      action: 'search-page-type-filter-clicked',
    })
  }

  const handleRentOnClick = () => {
    handleTypeOnChange('FOR_RENT')
    ReactGA.event({
      category: 'action',
      action: 'sub-header-rent-clicked',
    })
    clickEvents.SUB_HEADER_RENT()
  }

  const handleBuyOnClick = () => {
    handleTypeOnChange('FOR_SALE')
    ReactGA.event({
      category: 'action',
      action: 'sub-header-buy-clicked',
    })
    clickEvents.SUB_HEADER_BUY()
  }

  const handleFavoritesOnClick = (event) => {
    if (!userIsLoggedIn) {
      event.preventDefault()
      event.stopPropagation()
      openAuthModal()
    }
    ReactGA.event({
      category: 'action',
      action: 'sub-header-favorites-clicked',
    })
    clickEvents.SUB_HEADER_FAVORITES()
  }

  const handleNewDevelopmentsOnClick = () => {
    ReactGA.event({
      category: 'action',
      action: 'sub-header-new-developments-clicked',
    })
    clickEvents.SUB_HEADER_NEW_DEVELOPMENTS()
  }

  return (
    <SubHeaderContainer className="hidden-mobile">
      <SubHeaderContentContainer>
        <StyledLink to="/search" onClick={handleBuyOnClick} onContextMenu={handleBuyOnClick}>
          <NavbarItem>Buy</NavbarItem>
        </StyledLink>
        <StyledLink to="/search" onClick={handleRentOnClick} onContextMenu={handleRentOnClick}>
          <NavbarItem>Rent</NavbarItem>
        </StyledLink>
        <StyledLink to="/account/sales" onClick={handleFavoritesOnClick}>
          <NavbarItem>Favorites</NavbarItem>
        </StyledLink>
        <StyledLink to="/new-developments" onClick={handleNewDevelopmentsOnClick}>
          <NavbarItem>New Developments</NavbarItem>
        </StyledLink>
      </SubHeaderContentContainer>
      <SubHeaderContentContainer>
        <NavbarItem>
          <SearchBar />
        </NavbarItem>
      </SubHeaderContentContainer>
    </SubHeaderContainer>
  )
}

export default SubHeader
