import { Link } from 'react-router-dom'
import ReactGA from 'react-ga4'

import { TABLE_SORT_ASCENDING, TABLE_SORT_DESCENDING } from '../../../../../../constants/image.const'
import clickEvents from '../../../../../../services/analytics.service'
import { useListingContext } from '../../../../../../context/ListingContext'
import useAxios from 'axios-hooks'
import { PUBLIC_LISTINGS } from '../../../../../../constants/api'
import {
  DetailsTableContainer,
  SortImage,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
} from './DetailsTable.style'

const DetailsTable = ({ activeTab, columns, data, sortBy, setSortBy }) => {
  const { listings, setListings } = useListingContext()

  const [{ data: listingDetails }, getListing] = useAxios(`${PUBLIC_LISTINGS}`, { autoCancel: false, manual: true })

  const columnsToUse = columns?.filter((column) => column.visible)

  const headerCells = columnsToUse?.map((column) => {
    const ascendingSort = column.value
    const descendingSort = `-${column.value}`
    const isSorted = [ascendingSort, descendingSort].includes(sortBy)
    const imageSource = sortBy === ascendingSort ? TABLE_SORT_ASCENDING : TABLE_SORT_DESCENDING

    const handleHeaderCellOnClick = () => {
      setSortBy(sortBy === descendingSort ? ascendingSort : descendingSort)
      ReactGA.event({
        category: 'action',
        action: 'building-page-table-header-clicked',
      })
      clickEvents.TABLE_HEADER()
    }

    return (
      <TableHeaderCell
        key={column.value}
        onClick={handleHeaderCellOnClick}
        numberOfColumns={columnsToUse?.length}
        numberOfRows={data?.length}
      >
        {column.label}
        <SortImage src={imageSource} isSorted={isSorted} alt="sort-icon" />
      </TableHeaderCell>
    )
  })

  const tableRows = data?.map((row, index) => {
    const isHoverable = row?.url

    const handleRowOnClick = () => {
      ReactGA.event({
        category: 'action',
        action: 'building-page-listing-row-clicked',
      })
      clickEvents.LISTING_ROW()
    }

    const handleRowOnHover = () => {
      if (!row?.uuid || !row?.url) return
      getListing({ url: `${PUBLIC_LISTINGS}${row?.uuid}/` })?.then((response) => {
        setListings(row?.url, response?.data)

        // Preload images
        response?.data?.medias?.slice(5).forEach((item) => {
          const imgSrc = item?.file?.replace('https://dvvjkgh94f2v6.cloudfront.net/', 'https://images.oceanpads.com/')
          if (imgSrc) {
            const img = new Image()
            img.src = imgSrc // Triggers preloading
          }
        })
      })
    }

    const tableCells = columnsToUse?.map((column) => (
      <TableBodyCell key={`${column.value}-${index}`}>
        {isHoverable ? <Link to={row?.url}>{row[column?.value] || ' '}</Link> : <div>{row[column?.value] || ' '}</div>}
      </TableBodyCell>
    ))

    return (
      <TableBodyRow
        isHoverable={isHoverable}
        index={index}
        key={index}
        onClick={handleRowOnClick}
        onMouseEnter={handleRowOnHover}
      >
        {tableCells}
      </TableBodyRow>
    )
  })

  const tabMap = {
    'For Rent': 'active rentals',
    'For Sale': 'active sales',
    'All Units': 'units',
    'Past Rentals': 'past rentals',
    'Past Sales': 'past sales',
  }

  const noData = (
    <TableBodyRow>
      <TableBodyCell
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        No {tabMap[activeTab]} available.
      </TableBodyCell>
    </TableBodyRow>
  )

  return (
    <DetailsTableContainer>
      <TableContainer>
        <TableHeader>
          <TableHeaderRow>{headerCells}</TableHeaderRow>
        </TableHeader>
        <TableBody>{tableRows?.length ? tableRows : noData}</TableBody>
      </TableContainer>
    </DetailsTableContainer>
  )
}

export default DetailsTable
