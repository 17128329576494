import styled from 'styled-components'
import THEME from '../../constants/themes'
import { Link } from 'react-router-dom'

export const BlogContainer = styled.div.attrs({
  className: 'BlogContainer',
})`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
  max-width: 1440px;
  width: 100%;
  // padding: 20px 80px;
  // margin: auto;
  display: flex;
  flex-direction: column;
  // gap: 1.5em;
  @media (max-width: 992px) {
    padding: 20px;
    background-color: #f8f9fa;
    margin-top: 60px;
  }
  margin: auto;
  margin-top: 80px;
`

export const Button = styled.div.attrs({
  className: 'Button',
})`
  border: none;
  cursor: pointer;
  background-color: ${THEME.mediumBlue};
  border-radius: 4px;
  color: white;
  padding: 0.5rem 1rem;
  width: 100px;
  text-align: center;
`

export const ButtonsContainer = styled.div.attrs({
  className: 'ButtonsContainer',
})`
  display: flex;
  gap: 1rem;
  margin-left: auto;
  padding: 1rem;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const HeaderBarContainer = styled.div.attrs({
  className: 'HeaderBarContainer',
})`
  width: 100%;
  position: fixed;
  top: 140px;
  height: 80px;
  display: flex;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 4px -2px;
  z-index: 100;
  @media (max-width: 992px) {
    top: 70px;
    height: 70px;
    background-color: ${THEME.darkBlue};
  }
`

export const PreviewContainer = styled(Link).attrs({
  className: 'PreviewContainer',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // margin-top: 2rem;
  height: 650px;
  // width: 100%;
  color: inherit;
  cursor: pointer;
  &:hover {
    // text-decoration: none;
    color: inherit;
    opacity: 0.9;
  }
`

export const TitleContainer = styled.div.attrs({
  className: 'TitleContainer',
})`
  display: flex;
  align-items: center;
  // justify-content: center;
  padding: 15px;
  width: 100%;
  font-size: 20px;
`

export const ParallaxContainer = styled.div.attrs({
  className: 'ParallaxContainer',
})`
  position: fixed;
  top: 0;
  left: 0;
  // width: 100%;
  z-index: 0;
  height: 100vh;
  img {
    // width: 100%;
    height: 100%;
  }
  opacity: 0.1;
`
