import { ListingsContainer, ListingsContentContainer, ListingsTitleContainer } from './Listings.style'
import { SectionTitle } from '../../BuildingDetails.style'
import UnitTable from './UnitsTable'

const Listings = ({ building }) => {
  return (
    <ListingsContainer>
      <ListingsTitleContainer>
        <SectionTitle>Listings</SectionTitle>
      </ListingsTitleContainer>
      <ListingsContentContainer>
        <UnitTable building={building} />
      </ListingsContentContainer>
    </ListingsContainer>
  )
}

export default Listings
