import styled from 'styled-components'

export const StyledSwitchViewButton = styled.div.attrs({
  className: 'StyledSwitchViewButton',
})(
  ({ isMobileMapView }) => `
    border-radius: 32px;
    background: ${isMobileMapView ? 'white' : '#00669a'};
    border: 2px solid rgba(255, 255, 255, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 20px 13px 24px;
    color: ${isMobileMapView ? '#00669a' : 'white'};
    gap: 5px;
    white-space: nowrap;
    font-weight: 600;
    > svg {
      path {
        fill: ${isMobileMapView ? '#00669a' : 'white'};
      }
    }
    ${isMobileMapView ? 'position: fixed; bottom: 50px;' : ''}
  `,
)

export const SwitchViewButtonContainer = styled.div.attrs({
  className: 'SwitchViewButtonContainer',
})(
  ({ isMobileMapView }) => `
    width:  ${isMobileMapView ? '100%' : 'fit-content'};
    position: sticky;
    bottom: 50px;
    z-index: ${isMobileMapView ? 400 : 150};
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    left: ${isMobileMapView ? null : 'calc(50% - 73px)'};
  `,
)
