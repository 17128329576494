import axios from 'axios'

import { PUBLIC_CLICK } from '../constants/api'

const clickEvents = {
  // search page
  AMENITIES_FILTER: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'amenities filter',
      pageType: 'Search',
      url: window.location.href,
    })
  },
  BEDS_FILTER: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'beds filter',
      pageType: 'Search',
      url: window.location.href,
    })
  },
  MORE_FILTER: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'more filter',
      pageType: 'Search',
      url: window.location.href,
    })
  },
  NEIGHBORHOODS_FILTER: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'neighborhood filter',
      pageType: 'Search',
      url: window.location.href,
    })
  },
  PRICE_FILTER: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'price filter',
      pageType: 'Search',
      url: window.location.href,
    })
  },
  TYPE_FILTER: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'type filter',
      pageType: 'Search',
      url: window.location.href,
    })
  },
  LISTING_CARD: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'listing card',
      pageType: 'Search',
      url: window.location.href,
    })
  },
  POPUP_BUILDING_IMAGE: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'popup building image',
      pageType: 'Search',
      url: window.location.href,
    })
  },
  POPUP_LISTING_CARD: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'popup listing card',
      pageType: 'Search',
      url: window.location.href,
    })
  },
  SHOW_ON_MAP: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'show on map',
      pageType: 'Search',
      url: window.location.href,
    })
  },
  MOBILE_FILTER_PANEL: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'mobile filter panel',
      pageType: 'Search',
      url: window.location.href,
    })
  },
  MOBILE_ORDERING_PANEL: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'mobile ordering panel',
      pageType: 'Search',
      url: window.location.href,
    })
  },
  NEW_DEVELOPMENTS_MOBILE_FILTER_PANEL: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'mobile filter panel',
      pageType: 'New Developments',
      url: window.location.href,
    })
  },
  NEW_DEVELOPMENTS_MOBILE_ORDERING_PANEL: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'mobile ordering panel',
      pageType: 'New Developments',
      url: window.location.href,
    })
  },

  // new developments page
  NEW_DEVELOPMENTS_AMENITIES_FILTER: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'amenities filter',
      pageType: 'New Developments',
      url: window.location.href,
    })
  },
  NEW_DEVELOPMENTS_NEIGHBORHOODS_FILTER: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'neighborhood filter',
      pageType: 'New Developments',
      url: window.location.href,
    })
  },
  NEW_DEVELOPMENTS_POPUP_BUILDING_IMAGE: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'popup building image',
      pageType: 'New Developments',
      url: window.location.href,
    })
  },
  NEW_DEVELOPMENTS_SHOW_ON_MAP: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'show on map',
      pageType: 'New Developments',
      url: window.location.href,
    })
  },
  NEW_DEVELOPMENTS_SEARCH_CONDUCTED: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'search conducted',
      pageType: 'New Developments',
      url: window.location.href,
    })
  },

  // building page
  BUILDING_IMAGE: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'building image',
      pageType: 'Building',
      url: window.location.href,
    })
  },
  SAVE_BUILDING: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'save building',
      pageType: 'Building',
      url: window.location.href,
    })
  },
  SHARE_BUILDING: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'share building',
      pageType: 'Building',
      url: window.location.href,
    })
  },
  LISTING_ROW: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'listing row',
      pageType: 'Building',
      url: window.location.href,
    })
  },
  TABLE_HEADER: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'table header',
      pageType: 'Building',
      url: window.location.href,
    })
  },
  ALL_UNITS_TAB: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'all units tab',
      pageType: 'Building',
      url: window.location.href,
    })
  },
  FOR_RENT_TAB: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'for rent tab',
      pageType: 'Building',
      url: window.location.href,
    })
  },
  FOR_SALE_TAB: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'for sale tab',
      pageType: 'Building',
      url: window.location.href,
    })
  },
  PAST_RENTALS_TAB: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'past rentals tab',
      pageType: 'Building',
      url: window.location.href,
    })
  },
  PAST_SALES_TAB: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'past sales tab',
      pageType: 'Building',
      url: window.location.href,
    })
  },
  MANAGEMENT_EMAIL: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'management email',
      pageType: 'Building',
      url: window.location.href,
    })
  },
  MANAGEMENT_PHONE: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'management phone',
      pageType: 'Building',
      url: window.location.href,
    })
  },
  MANAGEMENT_WEBSITE: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'management website',
      pageType: 'Building',
      url: window.location.href,
    })
  },

  // listing page
  LISTING_IMAGE: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'listing image',
      pageType: 'Listing',
      url: window.location.href,
    })
  },
  SAVE_LISTING: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'save listing',
      pageType: 'Listing',
      url: window.location.href,
    })
  },
  SHARE_LISTING: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'share listing',
      pageType: 'Listing',
      url: window.location.href,
    })
  },
  AGENT_EMAIL: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'agent email',
      pageType: 'Listing',
      url: window.location.href,
    })
  },
  AGENT_NAME: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'agent name',
      pageType: 'Listing',
      url: window.location.href,
    })
  },
  AGENT_PHONE: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'agent phone',
      pageType: 'Listing',
      url: window.location.href,
    })
  },
  ABOUT_THE_BUILDING: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'about the building',
      pageType: 'Listing',
      url: window.location.href,
    })
  },
  SIMILAR_LISTINGS: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'similar listings',
      pageType: 'Listing',
      url: window.location.href,
    })
  },

  // home:
  SEARCH_BUTTON: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'search button',
      pageType: 'Home',
      url: window.location.href,
    })
  },
  FILEFORMS: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'fileforms',
      pageType: 'Home',
      url: window.location.href,
    })
  },
  HOME_BEDS_FILTER: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'beds filter',
      pageType: 'Home',
      url: window.location.href,
    })
  },
  HOME_NEIGHBORHOODS_FILTER: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'neighborhood filter',
      pageType: 'Home',
      url: window.location.href,
    })
  },
  HOME_PRICE_FILTER: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'price filter',
      pageType: 'Home',
      url: window.location.href,
    })
  },
  HOME_TYPE_FILTER: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'type filter',
      pageType: 'Home',
      url: window.location.href,
    })
  },

  // headers:
  MAIN_HEADER_LOGO: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'main header logo',
      url: window.location.href,
    })
  },
  SUB_HEADER_RENT: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'sub header rent',
      url: window.location.href,
    })
  },
  SUB_HEADER_BUY: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'sub header buy',
      url: window.location.href,
    })
  },
  SUB_HEADER_FAVORITES: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'sub header favorites',
      url: window.location.href,
    })
  },
  SUB_HEADER_NEW_DEVELOPMENTS: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'sub header new developments',
      url: window.location.href,
    })
  },
  SEARCH_BAR: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'search bar',
      url: window.location.href,
    })
  },
  SEARCH_BAR_ADDRESS: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'search bar address',
      url: window.location.href,
    })
  },
  SEARCH_BAR_BUILDING: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'search bar building',
      url: window.location.href,
    })
  },
  SEARCH_BAR_NEIGHBORHOOD: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'search bar neighborhood',
      url: window.location.href,
    })
  },
  SEARCH_CONDUCTED: () => {
    return axios.post(PUBLIC_CLICK, {
      clickType: 'search conducted',
      pageType: 'Search',
      url: window.location.href,
    })
  },
}

export default clickEvents
