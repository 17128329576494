import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { OCEAN_PADS_SVG } from '../../constants/image.const'
import footerBackgroundDesktop from '../../assets/images/footer/desktopBackground.svg'
import footerBackgroundMobile from '../../assets/images/footer/mobileBackground.svg'
import instagramLogo from '../../assets/images/footer/instagramLogo.svg'
import mobileLogo from '../../assets/images/footer/mobileLogo.svg'
import {
  ContactUsContainer,
  ContentContainer,
  FooterContainer,
  FooterItem,
  LogoImage,
  SubFooterContainer,
  WaveImage,
} from './Footer.style'

export const Footer = () => {
  const { searchPage } = useSelector((state) => state.page)
  const isMobileMapView = searchPage === 'map'

  const termsPrivacyAndSocial = (
    <>
      <Link to="/terms">
        <FooterItem>Terms</FooterItem>
      </Link>
      <Link to="/privacy">
        <FooterItem>Privacy</FooterItem>
      </Link>
      <Link to="https://instagram.com/ocean_pads/">
        <FooterItem>
          <img src={instagramLogo} alt="instagram" /> Instagram
        </FooterItem>
      </Link>
    </>
  )

  const contactUs = (
    <Link to="mailto:support@oceanpads.com">
      <FooterItem>Questions? Contact us @ support@oceanpads.com</FooterItem>
    </Link>
  )

  return (
    <FooterContainer isMobileMapView={isMobileMapView}>
      <WaveImage src={footerBackgroundDesktop} className="hidden-mobile" alt="footer-background" />
      <WaveImage src={footerBackgroundMobile} className="hidden-desktop" alt="footer-background" />
      <ContactUsContainer className="hidden-desktop">
        <ContentContainer>
          <img src={mobileLogo} alt="logo" />
          {termsPrivacyAndSocial}
        </ContentContainer>
        <ContentContainer>{contactUs}</ContentContainer>
      </ContactUsContainer>
      <ContactUsContainer className="hidden-mobile">
        <Link to="/">
          <LogoImage src={OCEAN_PADS_SVG} alt="logo" />
        </Link>
        {contactUs}
      </ContactUsContainer>
      <SubFooterContainer>
        <FooterItem>Copyright © 2023 OceanPads. All Rights Reserved.</FooterItem>
        <ContentContainer className="hidden-mobile">{termsPrivacyAndSocial}</ContentContainer>
      </SubFooterContainer>
    </FooterContainer>
  )
}
