import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  building: null,
  lat: null,
  lon: null,
}

export const selectionsSlice = createSlice({
  name: 'selections',
  initialState,
  reducers: {
    setBuilding: (state, action) => {
      state.building = action.payload
    },
    setLat: (state, action) => {
      state.lat = action.payload
    },
    setLon: (state, action) => {
      state.lon = action.payload
    },
  },
})

export const selectionsActionReducer = selectionsSlice.actions
export default selectionsSlice.reducer
