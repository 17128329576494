import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from 'axios-hooks'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

import { selectionsActionReducer } from '../../store/selections/selectionsSlice'
import { CONSUMER_SAVED_BUILDINGS, PUBLIC_NEW_DEVELOPMENTS } from '../../constants/api'
import Map from './Map'
import Filters from './Filters'
import List from './List'
import SubHeader from './SubHeader'
import { NewDevelopmentsContainer } from './NewDevelopments.style'
import neighborhoodOptions from '../../constants/neighborhoods'
import clickEvents from '../../services/analytics.service'

const NewDevelopments = () => {
  const dispatch = useDispatch()

  const { neighborhoods, buildingAmenities, newDevelopmentsOrdering } = useSelector((state) => state.search)
  const { user } = useSelector((state) => state.auth)
  const {
    newDevelopmentsFilterPanelIsOpen: filterPanelIsOpen,
    newDevelopmentsOrderingPanelIsOpen: orderingPanelIsOpen,
  } = useSelector((state) => state.panels)

  const resetSelectedBuilding = () => dispatch(selectionsActionReducer?.setBuilding(null))

  const userIsLoggedIn = !!user?.user

  const [map, setMap] = useState(null)
  const [page, setPage] = useState(1)
  const [sortMethod, setSortMethod] = useState('name')

  const newDevelopmentsParams = useMemo(
    () => ({
      paginate: false,
    }),
    [],
  )

  const [{ data: newDevelopmentsResponse }, getNewDevelopments] = useAxios(
    { url: PUBLIC_NEW_DEVELOPMENTS, params: newDevelopmentsParams },
    { manual: true },
  )

  const [{ data: favoritesResponse, loading: loadingFavoritesResponse }, getFavorites] = useAxios(
    CONSUMER_SAVED_BUILDINGS,
    { manual: true },
  )

  const isLoading = !newDevelopmentsResponse

  const buildings = newDevelopmentsResponse || []
  const favorites = favoritesResponse?.results

  const ordering = {
    soonest: (a, b) => a.yearBuilt - b.yearBuilt,
    name: (a, b) => a.name?.localeCompare(b.name),
    neighborhood: (a, b) => a.neighborhood?.name?.localeCompare(b.neighborhood?.name),
  }

  const filteredBuildings = buildings
    ?.filter((building) => !neighborhoods?.length || neighborhoods?.includes(building?.neighborhood?.uuid))
    ?.filter(
      (building) =>
        !buildingAmenities?.length || buildingAmenities?.every((amenity) => building?.amenities?.includes(amenity)),
    )
  const sortedBuildings = filteredBuildings?.sort(ordering[sortMethod])
  const slicedBuildings = sortedBuildings?.slice((page - 1) * 10, page * 10)

  const count = filteredBuildings?.length

  const scrollToTop = () => window.scrollTo({ top: 0 })

  useEffect(() => {
    setPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingAmenities, neighborhoods])

  useEffect(() => {
    scrollToTop()
    resetSelectedBuilding()
    clickEvents.NEW_DEVELOPMENTS_SEARCH_CONDUCTED()
    ReactGA.event({
      category: 'action',
      action: 'new-developments-page-search-conducted',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => setSortMethod(newDevelopmentsOrdering), [newDevelopmentsOrdering])

  useEffect(() => {
    if (newDevelopmentsResponse) return
    getNewDevelopments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newDevelopmentsResponse])

  useEffect(() => {
    if (!userIsLoggedIn) return
    if (favoritesResponse) return
    getFavorites()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsLoggedIn, favoritesResponse])

  const metaDescription = `Browse new developments in Miami on OceanPads. ${neighborhoodOptions
    ?.map((neighborhood) => neighborhood?.label)
    ?.join(', ')}`

  console.table({ filterPanelIsOpen, orderingPanelIsOpen })

  return (
    <NewDevelopmentsContainer>
      <Helmet>
        <title>OceanPads | New Developments</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href="https://oceanpads.com/new-developments" />
      </Helmet>
      <SubHeader />
      <Filters />
      <List
        loadingFavoritesResponse={loadingFavoritesResponse}
        favorites={favorites}
        getFavorites={getFavorites}
        buildings={slicedBuildings}
        map={map}
        isLoading={isLoading}
        page={page}
        count={count}
        sortMethod={sortMethod}
        setPage={setPage}
        setSortMethod={setSortMethod}
      />
      <Map buildings={filteredBuildings} isLoading={isLoading} setMap={setMap} />
    </NewDevelopmentsContainer>
  )
}

export default NewDevelopments
