import { useState } from 'react'

import { CloseIcon, DownArrow } from '../../Containers'
import useOutsideClick from '../../../../../hooks/outsideClick'
import {
  IconContainer,
  PriceFilterDropdownContainer,
  PriceOptionContainer,
  PriceOptionsContainer,
  PriceSubtitleContainer,
  PriceValueContainer,
  PriceValueInputContainer,
} from './PriceFilterDropdown.style'

const PriceFilterDropdown = ({
  options,
  placeholder,
  title,
  priceValue,
  setPriceValue,
  inputValue,
  setInputValue,
  inputRef,
  dropdownRef,
}) => {
  const formatCurrency = (value) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      // minimumSignificantDigits: 1,
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: value > 999 && value < 9999 ? 1 : 0,
      maximumFractionDigits: 2,
    })

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  const closeIconIsHidden = !inputValue

  const handleInputOnClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (dropdownIsOpen) handleInputOnBlur()
    else handleInputOnFocus()
  }

  const handleInputOnChange = (event) => {
    const value = event?.target?.value?.replace(/\D/g, '')
    setInputValue(value)
    setPriceValue(value)
  }

  const handleInputOnFocus = () => {
    inputRef?.current?.focus()
    setDropdownIsOpen(true)
    setInputValue(priceValue || '')
  }

  const handleInputOnBlur = () => {
    inputRef?.current?.blur()
    const formattedValue = priceValue ? formatCurrency(priceValue).format(priceValue) : ''
    setInputValue(formattedValue)
    setDropdownIsOpen(false)
  }

  const handleCloseIconOnClick = () => {
    inputRef?.current?.blur()
    setDropdownIsOpen(false)
    setPriceValue(null)
    setInputValue('')
  }

  const handleDownArrowOnClick = () => {
    if (dropdownIsOpen) handleInputOnBlur()
    else handleInputOnFocus()
  }

  const handleOptionOnClick = (event, option) => {
    event.stopPropagation()
    event.preventDefault()
    const formattedValue = formatCurrency(option?.value).format(option?.value)
    setInputValue(formattedValue)
    setPriceValue(option?.value)
    setDropdownIsOpen(false)
    inputRef.current.blur()
  }

  const handleInputOnKeyDown = (event) => {
    if (event.key === 'Enter') handleInputOnBlur()
  }

  useOutsideClick(
    dropdownRef,
    () => {
      handleInputOnBlur()
    },
    [],
  )

  const priceOptions = options
    ?.filter((option) => !priceValue || `${option?.value}`?.includes(priceValue))
    ?.map((option) => (
      <PriceOptionContainer key={`${title}-${option?.value}`} onClick={(event) => handleOptionOnClick(event, option)}>
        {formatCurrency(option?.value).format(option.value)}
      </PriceOptionContainer>
    ))

  return (
    <PriceFilterDropdownContainer ref={dropdownRef}>
      <PriceSubtitleContainer>{title}</PriceSubtitleContainer>
      <PriceValueContainer>
        <PriceValueInputContainer
          ref={inputRef}
          placeholder={placeholder}
          onChange={handleInputOnChange}
          value={inputValue}
          onKeyDown={handleInputOnKeyDown}
          onClick={handleInputOnClick}
        />
        <IconContainer isHidden={closeIconIsHidden} onClick={handleCloseIconOnClick}>
          <CloseIcon />
        </IconContainer>
        <IconContainer onClick={handleDownArrowOnClick}>
          <DownArrow />
        </IconContainer>
      </PriceValueContainer>
      <PriceOptionsContainer isOpen={dropdownIsOpen}>{priceOptions}</PriceOptionsContainer>
    </PriceFilterDropdownContainer>
  )
}

export default PriceFilterDropdown
