import styled from 'styled-components'

export const AdsContainer = styled.div.attrs({
  className: 'AdsContainer',
})`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  > div {
    width: 350px;
  }
  @media (max-width: 992px) {
    margin-left: -10px;
    margin-bottom: 100px;
    width: calc(100% + 20px);
    > div {
      width: 100%;
    }
  }
`

export const ListContainer = styled.div.attrs({
  className: 'ListContainer',
})(
  ({ isActive }) => `
    min-width: 700px;
    width: 100%;
    max-width: 700px;

    @media (max-width: 1240px) {
      min-width: 350px;
      width: 350px;
      max-width: 350px;
    }
    @media (max-width: 992px) {
      margin-top: 70px;
      width: 100%;
      max-width: 100%;
      position: relative;
      z-index: ${isActive ? 100 : -100};
      padding: 15px 10px;
      background-color: #f8f9fa;
    }
  `,
)

export const SearchListContainer = styled.div.attrs({
  className: 'SearchListContainer',
})`
  display: flex;
  flex-direction: column;
  gap: 1em;
  opacity: 1;
  overflow: unset;
  padding: 15px;
  width: 100%;
  position: relative;

  @media (max-width: 992px) {
    padding: 5px;
    margin-bottom: calc(20px + 1em);
    box-shadow: none;
    border: none;
    background: transparent;
  }
`
