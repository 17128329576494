import styled from 'styled-components'
import THEME from '../../../../constants/themes'

export const ExtraChipsContainer = styled.div.attrs({
  className: 'ExtraChipsContainer',
})`
  border: 1px solid rgb(230, 230, 230);
  padding: 2px;
  border-radius: 10px;
  background: white;
`

export const IconContainer = styled.div.attrs({
  className: 'IconContainer',
})(
  ({ isHidden }) => `
    display: ${isHidden ? 'none' : 'flex'};
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  `,
)

export const IconsContainer = styled.div.attrs({
  className: 'IconsContainer',
})`
  height: 100%;
  display: flex;
  align-items: center;
`

export const NeighborhoodChipContainer = styled.div.attrs({
  className: 'NeighborhoodChipContainer',
})`
  display: flex;
  align-items: center;
  color: rgb(0, 0, 0);
  font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  border-radius: 0 25px 25px 0;
  background: lightblue;
  min-width: 0px;
`

export const NeighborhoodChipIcon = styled.div.attrs({
  className: 'NeighborhoodChipIcon',
})`
  display: flex;
  height: 100%;
  width: 25px;
  align-items: center;
  border-radius: 0 25px 25px 0;
  &:hover {
    background: ${THEME.lightBlue};
  }
  > svg {
    height: 17px;
    width: 17px;
    > path {
      fill: #777 !important;
    }
  }
`

export const NeighborhoodChipValue = styled.div.attrs({
  className: 'NeighborhoodChipValue',
})`
  height: 100%;
  width: 100%;
  align-items: center;
  padding: 3px 6px;
  border-right: 1px solid rgb(230, 230, 230);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const NeighborhoodOption = styled.div.attrs({
  className: 'NeighborhoodOption',
})(
  ({ isSelected }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Nunito Sans', sans-serif;
    width: ${isSelected ? 'fit-content' : '100%'};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
    background: ${isSelected ? 'lightblue' : THEME.backgroundGray};
    padding: ${isSelected ? '3px 0 3px 5px' : '3px 10px'};
    margin-left: ${isSelected ? '5px' : '0'};
    border-radius: ${isSelected ? '0 25px 25px 0' : '0'};
    &:hover {
      background: ${isSelected ? THEME.lightBlue : 'white'};
    }
  `,
)

export const NeighborhoodsChipsContainer = styled.div.attrs({
  className: 'NeighborhoodsChipsContainer',
})(
  ({ inFocus }) => `
    width: fit-content;
    display: ${inFocus ? 'none' : 'flex'};
    align-items: center;
    height: 100%;
    margin-top: 0px;
    max-width: 360px;
    gap: 5px;
    > div {
      &:last-child {
        margin-right: 0.5em;
      }
    }
    @media (max-width: 992px) {
      max-width: 230px;
    }
  `,
)

export const NeighborhoodsContentContainer = styled.div.attrs({
  className: 'NeighborhoodsContentContainer',
})`
  display: flex;
  align-items: center;
  height: 100%;
  @media (max-width: 992px) {
    max-width: 100%;
  }
`

export const NeighborhoodsInput = styled.input.attrs({
  className: 'NeighborhoodsInput',
})(
  ({ inFocus }) => `
    width: ${inFocus ? 'auto' : '0px'};
    border: none;
    outline: none;
    box-shadow: none;
    background: transparent;
    height: 30px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    @media (max-width: 992px) {
      max-width: 100px;
    }
  `,
)

export const NeighborhoodsFilterContainer = styled.div.attrs({
  className: 'NeighborhoodsFilterContainer',
})(
  ({ isOpen }) => `
    position: relative;
    cursor: text;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: ${isOpen ? '#fafafa' : 'white'};
    border-radius: 60px 0 0 60px;
    &:hover {
      background-color: #fafafa;
    }
    &:after {
      content: '';
      height: 80%;
      width: 1px;
      border-right: 1px solid rgb(230, 230, 230);
    }
    @media (max-width: 992px) {
      flex-direction: column;
      grid-column: span 2;
      border-radius: 0;
      border-right: ${isOpen ? '1px solid rgb(230, 230, 230)' : '1px solid white'};
      border-left: ${isOpen ? '1px solid rgb(230, 230, 230)' : '1px solid white'};
      &:after {
        content: '';
        height: 1px;
        width: 90%;
        border-right: none;
        border-bottom: 1px solid rgb(230, 230, 230);
      }
    }
  `,
)

export const NeighborhoodsOptionsContainer = styled.div.attrs({
  className: 'NeighborhoodsOptionsContainer',
})(
  ({ isOpen }) => `
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    padding-bottom: 10px;
    width: calc(100% - 30px);
    top: 70px;
    right: 0;
    position: absolute;
    background: #fafafa;
    border: 1px solid rgb(230, 230, 230);
    border-top: none;
    border-radius: 0 0 10px 10px;
    z-index: 185;
    max-height: 200px;
    overflow-y: auto;
    gap: 1px;
    @media (max-width: 992px) {
      width: calc(100% + 2px);
      right: -1px;
    }
  `,
)

export const NeighborhoodsTriggerContainer = styled.div.attrs({
  className: 'NeighborhoodsTriggerContainer',
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 0.5em 0 40px;
  cursor: text;
  white-space: nowrap;
  border-radius: 60px 0 0 60px;

  @media (max-width: 992px) {
    padding: 0 0.5em;
    border-radius: 0;
  }
`

export const OptionText = styled.div.attrs({
  className: 'OptionText',
})(
  ({ isSelected }) => `
    border-right: ${isSelected ? '1px solid rgb(230, 230, 230)' : 'none'};
    padding-right: ${isSelected ? '1em' : '0'};
  `,
)
