import React from 'react'

import MoreFilter from './MoreFilter'
import PriceFilter from './PriceFilter'
import BedsFilter from './BedsFilter'
import AmenitiesFilter from './AmenitiesFilter'
import TypeFilter from './TypeFilter'
import NeighborhoodsFilter from './NeighborhoodsFilter'
import SaveSearchButton from './SaveSearchButton'
import { FilterWrapper } from './Filters.style'

const Filters = ({ refetchListings, savedSearchesResponseIsLoading }) => {
  return (
    <FilterWrapper>
      <TypeFilter refetchListings={refetchListings} />
      <NeighborhoodsFilter refetchListings={refetchListings} />
      <BedsFilter refetchListings={refetchListings} />
      <PriceFilter refetchListings={refetchListings} />
      <AmenitiesFilter refetchListings={refetchListings} />
      <MoreFilter refetchListings={refetchListings} />
      <SaveSearchButton isLoading={savedSearchesResponseIsLoading} />
    </FilterWrapper>
  )
}

export default Filters
