import styled from 'styled-components'

export const ListingsContainer = styled.div.attrs({
  className: 'ListingsContainer',
})`
  @media (max-width: 992px) {
    // margin: 0 20px;
    // padding: 20px;
    // padding-bottom: 0;
    .SectionTitle {
      padding: 20px;
      padding-bottom: 0;
    }
  }
`

export const ListingsContentContainer = styled.div.attrs({
  className: 'ListingsContentContainer',
})`
  position: relative;
  @media (max-width: 992px) {
    // width: calc(100%);
    // left: -20px;
  }
`

export const ListingsTitleContainer = styled.div.attrs({
  className: 'ListingsTitleContainer',
})`
  margin-bottom: 1em;
  @media (max-width: 992px) {
    display: none;
  }
`
