import styled from 'styled-components'

export const AmenitiesContainer = styled.div.attrs({
  className: 'AmenitiesContainer',
})`
  @media (max-width: 992px) {
    padding: 0 20px;
  }
`

export const AmenitiesContentContainer = styled.div.attrs({
  className: 'AmenitiesContentContainer',
})`
  display: grid;
  gap: 3px 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-bottom: 1.5em;
  border-bottom: 0.5px solid rgb(196, 196, 196);
  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 20px;
  }
  @media (max-width: 786px) {
    grid-template-columns: 1fr 1fr;
  }
`

export const AmenityContainer = styled.div.attrs({
  className: 'AmenityContainer',
})`
  display: flex;
  align-items: center;
  gap: 0.5em;
`

export const AmenityIcon = styled.img.attrs({
  className: 'AmenityIcon',
})`
  width: 25px;
  height: 25px;
  object-fit: cover;
`

export const AmenityText = styled.div.attrs({
  className: 'AmenityText',
})``
