import SubHeader from './SubHeader'
import MainHeader from './MainHeader'

const Header = ({ refetchListings }) => {
  return (
    <>
      <MainHeader />
      <SubHeader refetchListings={refetchListings} />
    </>
  )
}

export default Header
