import { memo } from 'react'

import { CardsContainer } from './Cards.style'
import Item from './Item'
import Loader from './Loader'
import NoOptions from './NoOptions'

const Cards = ({ listings, map, favorites, getFavorites, loadingFavoritesResponse, featuredListings, isLoading }) => {
  const flatFeaturedListings = featuredListings?.map((fl) => ({ ...fl?.listing, isFeatured: true })) || []
  const flatListings =
    listings?.filter(
      (listing) => !flatFeaturedListings?.find((featuredListing) => featuredListing?.uuid === listing?.uuid),
    ) || []
  const allListings = [...flatFeaturedListings, ...flatListings]

  const listingCards = allListings?.map((listing, index) => {
    const savedListing = favorites?.find((favorite) => favorite?.listing === listing.uuid)
    return (
      <Item
        listing={listing}
        key={`${listing?.uuid}-${index}`}
        map={map}
        savedListing={savedListing}
        getFavorites={getFavorites}
        loadingFavoritesResponse={loadingFavoritesResponse}
      />
    )
  })

  if (isLoading) return <Loader />

  if (listings?.length === 0) return <NoOptions />

  return <CardsContainer>{listingCards}</CardsContainer>
}

export default memo(Cards)
