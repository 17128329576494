import { ReactComponent as SearchIcon } from '../../../../assets/images/search.svg'
import ReactGA from 'react-ga4'

import { SearchButtonContainer, StyledLink, StyledSearchButton } from './SearchButton.style'
import clickEvents from '../../../../services/analytics.service'

const SearchButton = () => {
  const handleSearchButtonOnClick = () => {
    ReactGA.event({
      category: 'action',
      action: 'home-page-search-button-clicked',
    })
    clickEvents.SEARCH_BUTTON()
  }

  return (
    <SearchButtonContainer onClick={handleSearchButtonOnClick}>
      <StyledLink to="/search">
        <StyledSearchButton>
          <SearchIcon /> <span>Search</span>
        </StyledSearchButton>
      </StyledLink>
    </SearchButtonContainer>
  )
}

export default SearchButton
