import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  // search results:
  count: null,
  nextUrl: null,
  previousUrl: null,
  savedSearch: null,
  url: '',

  // search options:
  amenities: [],
  bathrooms: null,
  buildingAmenities: [],
  beds: [],
  maxPrice: null,
  minPrice: null,
  neighborhoods: [],
  newDevelopmentsOrdering: 'soonest',
  ordering: '-on_market_date',
  page: 1,
  pets: [],
  squareFeet: null,
  type: 'FOR_SALE',
  yearBuilt: null,

  // filter statuses:
  amenitiesCheckboxes: [],
  bathroomsInput: '',
  buildingAmenitiesCheckboxes: [],
  maxPriceInput: '',
  minPriceInput: '',
  petsCheckboxes: [],
  squareFeetInput: '',
  yearBuiltInput: '',

  // filter messages:
  amenitiesContainer: 'Select Amenities',
  buildingAmenitiesContainer: 'Select Amenities',
  bedsContainer: 'Any',
  priceContainer: 'Any',
  moreContainer: 'Browse Other Options',

  // hover states:
  hoveredNeighborhood: null,
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    // search results:
    setCount: (state, action) => {
      state.count = action.payload
    },
    setNextUrl: (state, action) => {
      state.nextUrl = action.payload
    },
    setPreviousUrl: (state, action) => {
      state.previousUrl = action.payload
    },
    setSavedSearch: (state, action) => {
      state.savedSearch = action.payload
    },
    setUrl: (state, action) => {
      state.url = action.payload
    },

    // search options:
    setAmenities: (state, action) => {
      state.amenities = action.payload
    },
    setBathrooms: (state, action) => {
      state.bathrooms = action.payload
    },
    setBuildingAmenities: (state, action) => {
      state.buildingAmenities = action.payload
    },
    setBeds: (state, action) => {
      state.beds = action.payload
    },
    setMaxPrice: (state, action) => {
      state.maxPrice = action.payload
      if (action.payload && action.payload <= state.minPrice) {
        state.minPrice = null
        state.minPriceInput = ''
      }
    },
    setMinPrice: (state, action) => {
      state.minPrice = action.payload
      if (action.payload && action.payload >= state.maxPrice) {
        state.maxPrice = null
        state.maxPriceInput = ''
      }
    },
    setNeighborhoods: (state, action) => {
      state.neighborhoods = action.payload
    },
    setNewDevelopmentsOrdering: (state, action) => {
      state.newDevelopmentsOrdering = action.payload
    },
    setOrdering: (state, action) => {
      state.ordering = action.payload
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setPets: (state, action) => {
      state.pets = action.payload
    },
    setSquareFeet: (state, action) => {
      state.squareFeet = action.payload
    },
    setType: (state, action) => {
      state.type = action.payload
    },
    setYearBuilt: (state, action) => {
      state.yearBuilt = action.payload
    },

    // filter statuses:
    setAmenitiesCheckboxes: (state, action) => {
      state.amenitiesCheckboxes = action.payload
    },
    setBathroomsInput: (state, action) => {
      state.bathroomsInput = action.payload
    },
    setBuildingAmenitiesCheckboxes: (state, action) => {
      state.buildingAmenitiesCheckboxes = action.payload
    },
    setMaxPriceInput: (state, action) => {
      state.maxPriceInput = action.payload
    },
    setMinPriceInput: (state, action) => {
      state.minPriceInput = action.payload
    },
    setPetsInput: (state, action) => {
      state.petsCheckboxes = action.payload
    },
    setSquareFeetInput: (state, action) => {
      state.squareFeetInput = action.payload
    },
    setYearBuiltInput: (state, action) => {
      state.yearBuiltInput = action.payload
    },

    // filter messages:
    setAmenitiesContainer: (state, action) => {
      state.amenitiesContainer = action.payload
    },
    setBedsContainer: (state, action) => {
      state.bedsContainer = action.payload
    },
    setBuildingAmenitiesContainer: (state, action) => {
      state.buildingAmenitiesContainer = action.payload
    },
    setPriceContainer: (state, action) => {
      state.priceContainer = action.payload
    },
    setMoreContainer: (state, action) => {
      state.moreContainer = action.payload
    },

    // hovered states:
    setHoveredNeighborhood: (state, action) => {
      state.hoveredNeighborhood = action.payload
    },
  },
})

export const searchActionReducer = searchSlice.actions
export default searchSlice.reducer
