import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from 'axios-hooks'

import { modalsActionReducer } from '../../../store/modals/modalsSlice'
import CLOSE_ICON from '../../../assets/images/close.svg'
import { CONSUMER_SAVED_SEARCHES } from '../../../constants/api'
import amenityOptions from '../../../constants/amenities'
import neighborhoodOptions from '../../../constants/neighborhoods'
import { searchActionReducer } from '../../../store/search/searchSlice'
import {
  Button,
  CloseButton,
  ContentContainer,
  HeaderContainer,
  OptionContainer,
  OptionTitle,
  OptionsContainer,
  SavedSearchContainer,
  StyledInput,
  TitleContainer,
} from './SavedSearch.style'

const SavedSearch = () => {
  const containerRef = useRef()

  const dispatch = useDispatch()

  const {
    amenities,
    bathrooms,
    beds,
    neighborhoods,
    maxPrice,
    minPrice,
    pets,
    savedSearch,
    squareFeet,
    type,
    yearBuilt,
  } = useSelector((state) => state?.search)

  const closeSavedSearchModal = () => dispatch(modalsActionReducer?.setSavedSearchModalIsOpen(false))
  const setSavedSearch = (payload) => dispatch(searchActionReducer?.setSavedSearch(payload))

  const [name, setName] = useState('')

  const isASavedSearch = !!savedSearch

  const [{ loading: savedSearchResponseIsLoading }, submitSavedSearch] = useAxios(
    {
      url: CONSUMER_SAVED_SEARCHES,
      method: 'POST',
      data: {
        amenities,
        bathrooms,
        beds,
        maxPrice,
        minPrice,
        neighborhoods,
        name,
        pets,
        squareFeet,
        type,
        yearBuilt,
      },
    },
    { manual: true },
  )

  const [{ loading: deleteSavedSearchIsLoading }, deleteSavedSearch] = useAxios(
    {
      url: `${CONSUMER_SAVED_SEARCHES}${savedSearch?.uuid}/`,
      method: 'DELETE',
    },
    { manual: true },
  )

  const title = isASavedSearch ? 'Delete this Search' : 'Save this Search'

  const submitButtonIsEnabled = name && !savedSearchResponseIsLoading
  const deleteButtonIsEnabled = !deleteSavedSearchIsLoading

  const typeMessage = type === 'FOR_RENT' ? 'For Rent' : 'For Sale'

  const neighborhoodsMessage = neighborhoodOptions
    ?.filter((option) => neighborhoods?.includes(option?.value))
    ?.map((option) => <span key={option.value}>{option?.label}</span>)

  const minBeds = Math.min(...beds)
  const maxBeds = Math.max(...beds)
  const bedsMessage = beds?.length
    ? minBeds === maxBeds
      ? minBeds === 4
        ? '4+ beds'
        : minBeds === 1
        ? `${minBeds} bed`
        : `${minBeds} beds`
      : maxBeds === 4
      ? `${minBeds}+ beds`
      : `${minBeds} - ${maxBeds}`
    : 'Any'

  const formatCurrency = (value) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      // minimumSignificantDigits: 1,
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: value > 999 && value < 9999 ? 1 : 0,
      maximumFractionDigits: 1,
    })

  const minFormatted = formatCurrency(minPrice).format(minPrice)
  const maxFormatted = formatCurrency(maxPrice).format(maxPrice)

  const priceMessage =
    !!minPrice && !!maxPrice
      ? `${minFormatted} - ${maxFormatted}`
      : !!minPrice
      ? `At least ${minFormatted}`
      : !!maxPrice
      ? `${maxFormatted} or less`
      : 'Any'

  const amenitiesMessage = amenityOptions
    ?.filter((option) => amenities?.includes(option?.value))
    ?.map((option) => <span key={option.value}>{option?.label}</span>)

  const petsSelected = !!pets?.length
  const moreSelection = bathrooms || squareFeet || yearBuilt || petsSelected
  const moreMessage = moreSelection ? '1+ Options Selected' : null

  const neighborhoodsAreFiltered = !!neighborhoods?.length
  const bedsAreFiltered = !!beds?.length
  const priceIsFiltered = !!(maxPrice || minPrice)
  const amenitiesAreFiltered = !!amenities?.length
  const moreOptionsAreFiltered = !!moreSelection

  const handleInputOnChange = (event) => setName(event?.target?.value)

  const handleSubmitOnClick = () => {
    submitSavedSearch().then((response) => {
      setSavedSearch(response?.data)
      closeSavedSearchModal()
    })
  }

  const handleDeleteOnClick = () => {
    deleteSavedSearch().then((r) => {
      setSavedSearch(null)
      closeSavedSearchModal()
    })
  }

  return (
    <SavedSearchContainer ref={containerRef}>
      <HeaderContainer>
        <TitleContainer>{title}</TitleContainer>
        <CloseButton onClick={closeSavedSearchModal}>
          <img src={CLOSE_ICON} alt="close" />
        </CloseButton>
      </HeaderContainer>
      <ContentContainer>
        <OptionsContainer>
          <OptionContainer>
            <OptionTitle>Type</OptionTitle>
            {typeMessage}
          </OptionContainer>
          {neighborhoodsAreFiltered && (
            <OptionContainer>
              <OptionTitle>Neighborhoods</OptionTitle>
              {neighborhoodsMessage}
            </OptionContainer>
          )}
          {bedsAreFiltered && (
            <OptionContainer>
              <OptionTitle>Beds</OptionTitle>
              {bedsMessage}
            </OptionContainer>
          )}
          {priceIsFiltered && (
            <OptionContainer>
              <OptionTitle>Price</OptionTitle>
              {priceMessage}
            </OptionContainer>
          )}
          {amenitiesAreFiltered > 0 && (
            <OptionContainer>
              <OptionTitle>Amenities</OptionTitle>
              {amenitiesMessage}
            </OptionContainer>
          )}
          {moreOptionsAreFiltered && (
            <OptionContainer>
              <OptionTitle>More</OptionTitle>
              {moreMessage}
            </OptionContainer>
          )}
        </OptionsContainer>
        {isASavedSearch ? (
          <>
            <StyledInput value={savedSearch?.name} disabled />
            <Button onClick={handleDeleteOnClick} disabled={!deleteButtonIsEnabled}>
              Delete
            </Button>
          </>
        ) : (
          <>
            <StyledInput value={name} onChange={handleInputOnChange} placeholder="Enter a name for this search" />
            <Button onClick={handleSubmitOnClick} disabled={!submitButtonIsEnabled}>
              Submit
            </Button>
          </>
        )}
      </ContentContainer>
    </SavedSearchContainer>
  )
}

export default SavedSearch
