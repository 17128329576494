import { LoaderContainer, LoadingSkeleton, SectionContainer } from './MiddleSectionLoader.style'

const MiddleSectionLoader = () => {
  return (
    <LoaderContainer>
      <SectionContainer>
        <LoadingSkeleton />
      </SectionContainer>
      <SectionContainer>
        <LoadingSkeleton />
      </SectionContainer>
      <SectionContainer>
        <LoadingSkeleton />
      </SectionContainer>
    </LoaderContainer>
  )
}

export default MiddleSectionLoader
