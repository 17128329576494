import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import OrderingPanel from './OrderingPanel'
import HeaderPanel from './HeaderPanel'
import FiltersPanel from './FiltersPanel'
import NewDevelopmentsFilteringPanel from './NewDevelopmentsFiltersPanel'
import NewDevelopmentsOrderingPanel from './NewDevelopmentsOrderingPanel'

const MobilePanels = ({ refetchListings }) => {
  const {
    filterPanelIsOpen,
    headerPanelIsOpen,
    orderingPanelIsOpen,
    newDevelopmentsFilteringPanelIsOpen,
    newDevelopmentsOrderingPanelIsOpen,
  } = useSelector((state) => state.panels)

  useEffect(() => {
    if (
      filterPanelIsOpen ||
      orderingPanelIsOpen ||
      headerPanelIsOpen ||
      newDevelopmentsFilteringPanelIsOpen ||
      newDevelopmentsOrderingPanelIsOpen
    )
      document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'visible'
  }, [
    filterPanelIsOpen,
    headerPanelIsOpen,
    orderingPanelIsOpen,
    newDevelopmentsFilteringPanelIsOpen,
    newDevelopmentsOrderingPanelIsOpen,
  ])

  return (
    <>
      <FiltersPanel refetchListings={refetchListings} />
      <HeaderPanel refetchListings={refetchListings} />
      <OrderingPanel refetchListings={refetchListings} />
      <NewDevelopmentsFilteringPanel />
      <NewDevelopmentsOrderingPanel />
    </>
  )
}

export default MobilePanels
