import { Skeleton } from '@mui/material'
import { ListingsHeaderContainer, OrderingDropdownContainer } from './Loader.style'

const Loader = () => (
  <ListingsHeaderContainer>
    <Skeleton width="200px" height="20px" className="hidden-desktop" />
    <Skeleton width="220px" height="80%" className="hidden-mobile" />
    <OrderingDropdownContainer className="hidden-mobile">
      <Skeleton width="50px" height="80%" />
      <Skeleton width="165px" height="80%" />
    </OrderingDropdownContainer>
  </ListingsHeaderContainer>
)

export default Loader
