import { OrderingDropdownContainer, OrderingDropdownTitle } from './Ordering.style'
import OrderingDropdown from './OrderingDropdown'

const Ordering = ({ buildings, sortMethod, setSortMethod }) =>
  buildings?.length > 0 && (
    <OrderingDropdownContainer>
      <OrderingDropdownTitle>Sort by</OrderingDropdownTitle>
      <OrderingDropdown sortMethod={sortMethod} setSortMethod={setSortMethod} />
    </OrderingDropdownContainer>
  )

export default Ordering
