import ReactGA from 'react-ga4'
import { useEffect } from 'react'

import {
  ListingCardContent,
  Icon,
  UnitDetails,
  UnitTitle,
  ListingCardLink,
  ListingCardContainer,
  ListingCardImage,
  IconImage,
} from './ListingCard.style'
import { BED_ICON, BATH_ICON, SQURE_FOOT_ICON } from '../../../../../../../constants/image.const'
import clickEvents from '../../../../../../../services/analytics.service'
import { useListingContext } from '../../../../../../../context/ListingContext'
import useAxios from 'axios-hooks'
import { PUBLIC_LISTINGS } from '../../../../../../../constants/api'

const ListingCard = ({ listing }) => {
  const { listings, setListings } = useListingContext()

  const [{ data: listingDetails }] = useAxios(`${PUBLIC_LISTINGS}${listing?.uuid}/`, { autoCancel: false })

  useEffect(() => {
    if (listingDetails && !listings?.[listing?.url]) {
      setListings(listing?.url, listingDetails)

      // Preload images
      listingDetails?.medias?.slice(5).forEach((item) => {
        const imgSrc = item?.file?.replace('https://dvvjkgh94f2v6.cloudfront.net/', 'https://images.oceanpads.com/')
        if (imgSrc) {
          const img = new Image()
          img.src = imgSrc // Triggers preloading
        }
      })
    }
  }, [listing, listingDetails, listings, setListings])

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumSignificantDigits: 1,
    notation: 'standard',
  })

  const numberFormatter = new Intl.NumberFormat('en-US', {
    minimumSignificantDigits: 1,
  })

  const handleListingLinkOnClick = () => {
    ReactGA.event({
      category: 'action',
      action: 'search-page-popup-listing-card-clicked',
    })
    clickEvents.POPUP_LISTING_CARD()
  }

  return (
    <ListingCardContainer>
      <ListingCardLink to={listing?.url} onClick={handleListingLinkOnClick}>
        <ListingCardImage src={listing.medias[0]?.file} alt="listing" />
        <ListingCardContent>
          <UnitTitle>
            <div>Unit {listing?.unit?.number}</div>
            <div>{currencyFormatter.format(listing?.price)}</div>
          </UnitTitle>
          <UnitDetails>
            <Icon>
              <IconImage src={BED_ICON} alt="beds" />
              {listing?.unit?.numberOfBedrooms} Beds
            </Icon>
            <Icon>
              <IconImage src={BATH_ICON} alt="baths" />
              {listing?.unit?.numberOfBathrooms} Baths
            </Icon>
            <Icon>
              <IconImage src={SQURE_FOOT_ICON} alt="fts" />
              {numberFormatter.format(listing?.unit?.squareFootage)} ft²
            </Icon>
          </UnitDetails>
        </ListingCardContent>
      </ListingCardLink>
    </ListingCardContainer>
  )
}

export default ListingCard
