import { PUBLIC_FEATURED_LISTING_PREVIEWS, PUBLIC_LISTING_GROUPS, PUBLIC_LISTING_PREVIEWS } from '../constants/api'

export const generateSearchURLs = (activeParams, extraParams) => {
  // this method converts the active search values into a searchUrl for the
  // listings-previews, listings-groups, and featured-listings endpoint

  const createBedsQueryParams = (bedsFilterArray, prefix = '') => {
    if (!bedsFilterArray?.length) return

    if (bedsFilterArray.length === 1 && bedsFilterArray[0] === 4)
      return {
        [`${prefix}unit__number_of_bedrooms__gte`]: 4,
      }

    if (bedsFilterArray.length === 1) return { [`${prefix}unit__number_of_bedrooms`]: bedsFilterArray[0] }

    const minBedrooms = Math.min(...bedsFilterArray)
    const maxBedrooms = Math.max(...bedsFilterArray)

    if (minBedrooms === 0)
      return {
        [`${prefix}unit__number_of_bedrooms__lte`]: maxBedrooms,
      }

    if (maxBedrooms === 4)
      return {
        [`${prefix}unit__number_of_bedrooms__gte`]: minBedrooms,
      }

    return {
      [`${prefix}unit__number_of_bedrooms__gte`]: minBedrooms,
      [`${prefix}unit__number_of_bedrooms__lte`]: maxBedrooms,
    }
  }

  const hashstring = (s) => {
    return s.split('').reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0)
      return a & a
    }, 0)
  }

  const convertToURLParams = (obj) => {
    const params = new URLSearchParams()
    for (const key in obj) obj.hasOwnProperty(key) && (obj[key] || obj[key] === 0) && params.append(key, obj[key])
    return params.toString()
  }

  const addPrefixToKeys = (obj, prefix) => {
    const modifiedObject = {}
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        modifiedObject[`${prefix}${key}`] = obj[key]
      }
    }
    return modifiedObject
  }

  const {
    amenities,
    bathrooms,
    beds,
    maxPrice,
    minPrice,
    neighborhoods,
    ordering,
    page,
    pets,
    squareFeet,
    type,
    url,
    yearBuilt,
  } = activeParams

  const amenitiesFilterString = amenities.join(',') || null
  const neighborhoodsFilterString = neighborhoods?.join(',') || null
  const petsFilterString = pets.join(',') || null

  const bedsQueryParams = createBedsQueryParams(extraParams?.beds || beds)
  const filteredListingsBedsQueryParams = createBedsQueryParams(extraParams?.beds || beds, 'listing__')

  delete extraParams?.beds

  const listingsPreviewsQueryParams = {
    amenities: amenitiesFilterString,
    building__neighborhood__uuid__in: neighborhoodsFilterString,
    building__year_built__gte: yearBuilt,
    ordering: ordering,
    page: page,
    pets: petsFilterString,
    price__gte: minPrice,
    price__lte: maxPrice,
    size: 10,
    status: 'OPEN',
    type: type,
    unit__number_of_bathrooms__gte: bathrooms,
    unit__square_footage__gte: squareFeet,
    ...bedsQueryParams,
  }

  const listingsGroupsQueryParams = {
    amenities: amenitiesFilterString,
    building__neighborhood__uuid__in: neighborhoodsFilterString,
    building__year_built__gte: yearBuilt,
    pets: petsFilterString,
    price__gte: minPrice,
    price__lte: maxPrice,
    status: 'OPEN',
    type: type,
    unit__number_of_bathrooms__gte: bathrooms,
    unit__square_footage__gte: squareFeet,
    ...bedsQueryParams,
  }

  const featuredListingsPreviewsQueryParams = {
    listing__amenities: amenitiesFilterString,
    listing__building__neighborhood__uuid__in: neighborhoodsFilterString,
    listing__building__year_built__gte: yearBuilt,
    listing__pets: petsFilterString,
    listing__price__gte: minPrice,
    listing__price__lte: maxPrice,
    listing__status: 'OPEN',
    listing__type: type,
    listing__unit__number_of_bathrooms__gte: bathrooms,
    listing__unit__square_footage__gte: squareFeet,
    ...filteredListingsBedsQueryParams,
  }

  const newListingsPreviewsParams = {
    ...listingsPreviewsQueryParams,
    page: 1, // reset page to 1 unless it is explicity set in extraParams
    ...(extraParams || {}),
  }
  const newListingsGroupsParams = {
    ...listingsGroupsQueryParams,
    ...(extraParams || {}),
  }

  const newFeaturedListingsPreviewsParams = {
    ...featuredListingsPreviewsQueryParams,
    ...addPrefixToKeys(extraParams, 'listing__'),
    z: hashstring(`${ordering}-${page}`), // include this to force reload of featured listings on rerender
  }

  const featuredListingsPreviewsUrlParamsString = convertToURLParams(newFeaturedListingsPreviewsParams)
  const listingsGroupsUrlParamsString = convertToURLParams(newListingsGroupsParams)
  const listingsPreviewsUrlParamsString = convertToURLParams(newListingsPreviewsParams)

  const newFeaturedListingsPreviewsUrl = `${PUBLIC_FEATURED_LISTING_PREVIEWS}?${featuredListingsPreviewsUrlParamsString}`
  const newListingsGroupsUrl = `${PUBLIC_LISTING_GROUPS}?${listingsGroupsUrlParamsString}`
  const newListingsPreviewsUrl = `${PUBLIC_LISTING_PREVIEWS}?${listingsPreviewsUrlParamsString}`

  const isNew = !(url === newListingsPreviewsUrl)

  // console.table(newListingsPreviewsParams)

  return {
    featuredListingsPreviewsQueryParams: newFeaturedListingsPreviewsParams,
    featuredListingsPreviewsUrl: newFeaturedListingsPreviewsUrl,
    isNew,
    listingsGroupsQueryParams: newListingsGroupsParams,
    listingsGroupsUrl: newListingsGroupsUrl,
    listingsPreviewsQueryParams: newListingsPreviewsParams,
    listingsPreviewsUrl: newListingsPreviewsUrl,
    page: newListingsPreviewsParams?.page,
  }
}
