import styled from 'styled-components'

export const OrderingDropdownContainer = styled.div.attrs({
  className: 'OrderingDropdownContainer hidden-mobile',
})`
  display: flex;
  align-items: center;
  gap: 0.5em;
  z-index: 165;
  height: 40px;
`

export const OrderingDropdownTitle = styled.div.attrs({ className: 'OrderingDropdownTitle' })`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  min-width: 50px;
`
