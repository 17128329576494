import React from 'react'

import AmenitiesFilter from './AmenitiesFilter'
import NeighborhoodsFilter from './NeighborhoodsFilter'
import { FilterWrapper } from './Filters.style'

const Filters = () => {
  return (
    <FilterWrapper>
      <NeighborhoodsFilter />
      <AmenitiesFilter />
    </FilterWrapper>
  )
}

export default Filters
