import { useState } from 'react'

import { CloseIcon, DownArrow } from '../../Containers'
import useOutsideClick from '../../../../../hooks/outsideClick'
import {
  IconContainer,
  PriceFilterDropdownContainer,
  PriceOptionContainer,
  PriceOptionsContainer,
  PriceSubtitleContainer,
  PriceValueContainer,
  PriceValueInputContainer,
} from './PriceFilterDropdown.style'

const PriceFilterDropdown = ({
  options,
  placeholder,
  title,
  priceValue,
  setPriceValue,
  inputValue,
  setInputValue,
  priceQueryParam,
  refetchListings,
  inputRef,
  dropdownRef,
  minPrice,
  maxPrice,
  setPriceContainer,
}) => {
  const formatCurrency = (value) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      // minimumSignificantDigits: 1,
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: value > 999 && value < 9999 ? 1 : 0,
      maximumFractionDigits: 2,
    })

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  const closeIconIsHidden = !inputValue

  const getPriceMessage = (price) => {
    let minPriceValue
    let maxPriceValue
    if (title === 'Min Price') {
      minPriceValue = price
      maxPriceValue = maxPrice
    } else {
      minPriceValue = minPrice
      maxPriceValue = price
    }
    const minFormatted = formatCurrency(minPriceValue).format(minPriceValue)
    const maxFormatted = formatCurrency(maxPriceValue).format(maxPriceValue)
    return !!minPriceValue && !!maxPriceValue
      ? `${minFormatted} - ${maxFormatted}`
      : !!minPriceValue
      ? `At least ${minFormatted}`
      : !!maxPriceValue
      ? `${maxFormatted} or less`
      : 'Any'
  }

  const handleInputOnClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (dropdownIsOpen) handleInputOnBlur()
    else handleInputOnFocus()
  }

  const handleInputOnChange = (event) => {
    const value = event?.target?.value?.replace(/\D/g, '')
    setInputValue(value)
    setPriceValue(value)
  }

  const handleInputOnFocus = () => {
    inputRef?.current?.focus()
    setDropdownIsOpen(true)
    setInputValue(priceValue || '')
  }

  const handleInputOnBlur = () => {
    inputRef?.current?.blur()
    const formattedValue = priceValue ? formatCurrency(priceValue).format(priceValue) : ''
    setInputValue(formattedValue)
    setDropdownIsOpen(false)
  }

  const handleCloseIconOnClick = () => {
    inputRef?.current?.blur()
    setDropdownIsOpen(false)
    setPriceValue(null)
    setInputValue('')
    refetchListings({ [priceQueryParam]: null }, 'PriceFilter.handleCloseIconOnClick')
    setPriceContainer(getPriceMessage(null))
  }

  const handleDownArrowOnClick = () => {
    if (dropdownIsOpen) handleInputOnBlur()
    else handleInputOnFocus()
  }

  const handleOptionOnClick = (event, option) => {
    event.stopPropagation()
    event.preventDefault()
    const formattedValue = formatCurrency(option?.value).format(option?.value)
    setInputValue(formattedValue)
    setPriceValue(option?.value)
    setDropdownIsOpen(false)
    inputRef.current.blur()
    const payload = { [priceQueryParam]: option?.value }
    if (title === 'Min Price' && maxPrice && option?.value >= maxPrice) payload.price__lte = null
    if (title === 'Max Price' && minPrice && option?.value <= minPrice) payload.price__gte = null
    refetchListings(payload, 'PriceFilter.handleOptionOnClick')
    setPriceContainer(getPriceMessage(option?.value))
  }

  const handleInputOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      const value = event?.target?.value?.replace(/\D/g, '')
      handleInputOnBlur()
      const payload = { [priceQueryParam]: value }
      if (title === 'Min Price' && maxPrice && value > maxPrice) payload.price__lte = null
      if (title === 'Max Price' && minPrice && value < minPrice) payload.price__gte = null
      refetchListings(payload, 'PriceFilter.handleInputOnKeyDown')
      setPriceContainer(getPriceMessage(value))
    }
  }

  useOutsideClick(
    dropdownRef,
    () => {
      handleInputOnBlur()
    },
    [],
  )

  const priceOptions = options
    ?.filter((option) => !priceValue || `${option?.value}`?.includes(priceValue))
    ?.map((option) => (
      <PriceOptionContainer key={`${title}-${option?.value}`} onClick={(event) => handleOptionOnClick(event, option)}>
        {formatCurrency(option?.value).format(option.value)}
      </PriceOptionContainer>
    ))

  return (
    <PriceFilterDropdownContainer ref={dropdownRef}>
      <PriceSubtitleContainer>{title}</PriceSubtitleContainer>
      <PriceValueContainer isOpen={dropdownIsOpen} hasOptions={!!priceOptions?.length}>
        <PriceValueInputContainer
          ref={inputRef}
          placeholder={placeholder}
          onChange={handleInputOnChange}
          value={inputValue}
          onKeyDown={handleInputOnKeyDown}
          onClick={handleInputOnClick}
        />
        <IconContainer isHidden={closeIconIsHidden} onClick={handleCloseIconOnClick}>
          <CloseIcon />
        </IconContainer>
        <IconContainer onClick={handleDownArrowOnClick}>
          <DownArrow />
        </IconContainer>
      </PriceValueContainer>
      {priceOptions?.length ? (
        <PriceOptionsContainer isOpen={dropdownIsOpen}>{priceOptions}</PriceOptionsContainer>
      ) : (
        ''
      )}
    </PriceFilterDropdownContainer>
  )
}

export default PriceFilterDropdown
