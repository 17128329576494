import styled from 'styled-components'

import THEME from '../../../../../constants/themes'

export const DetailsTableContainer = styled.div.attrs({
  className: 'DetailsTableContainer',
})`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  @media (max-width: 992px) {
    border-radius: 0;
    border-bottom: 1px solid rgb(230, 230, 230);
  }
`

export const TableBody = styled.tbody.attrs({
  className: 'TableBody',
})`
  display: block;
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 300px);
`

export const TableBodyCell = styled.td.attrs({
  className: 'TableBodyCell',
})`
  margin: auto;

  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 1em 0;

  @media (max-width: 500px) {
    font-size: 12px;
  }

  flex: 1;
`

export const TableBodyRow = styled.tr.attrs({
  className: 'TableBodyRow',
})(
  ({ index }) => `
    background: ${index % 2 === 0 ? THEME.backgroundGray : '#f7f6f7'};
    &:hover {
      background: #eceff1;
    }

    display: flex;
  `,
)

export const TableContainer = styled.table.attrs({
  className: 'TableContainer',
  cellSpacing: 0,
  cellPadding: 0,
})`
  width: 100%;
`

export const TableHeader = styled.thead.attrs({
  className: 'TableHeader',
})`
  background: ${THEME.mediumBlue};
  color: white;
  position: sticky;
  top: 0;
  z-index: 1;

  display: block;
`

export const TableHeaderCell = styled.th.attrs({
  className: 'TableHeaderCell',
})(
  ({ numberOfColumns, numberOfRows }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    height: 40px !important;
    cursor: pointer;
    user-select: none;
    flex: 1;
    border-right: 1px solid ${THEME.lightBlue};
    &:hover {
      opacity: 0.9;
    }
    @media (max-width: 500px) {
      font-size: 12px;
    }
    &:last-child {
      flex: 0 0 calc(100% / ${numberOfColumns} + 10px);
      ${numberOfRows <= 10 ? 'flex: 1;' : ' '}
      border-right: none;
    }
  `,
)

export const TableHeaderRow = styled.tr.attrs({
  className: 'TableHeaderRow',
})`
  display: flex;
  height: 100%;
`
