import styled from 'styled-components'

export const MiddleSectionContainer = styled.div.attrs({
  className: 'MiddleSectionContainer',
})`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  @media (max-width: 992px) {
    gap: 1em;
  }
`
