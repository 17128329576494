import styled from 'styled-components'

export const CloseIconContainer = styled.svg.attrs({
  className: 'CloseIconContainer',
})`
  user-select: none;
  cursor: pointer;
  &:hover {
    > path {
      fill: #b7b7b7;
    }
  }
`

export const ContainerMessage = styled.div.attrs({
  className: 'ContainerMessage',
})(
  ({ selection }) => `
    height: 30px;
    font-family: 'Nunito Sans', sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 13px;
    font-weight: ${selection ? 600 : null};
    color: ${selection ? 'black' : '#808080'};
    user-select: none;
  `,
)

export const ContainerTitle = styled.div.attrs({
  className: 'ContainerTitle',
})`
  margin-bottom: 0;
  color: rgb(95, 95, 95);
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  user-select: false;
  user-select: none;
`

export const DownArrowContainer = styled.svg.attrs({
  className: 'DownArrowContainer',
})`
  user-select: none;
  cursor: pointer;
  &:hover {
    > path {
      fill: #b7b7b7;
    }
  }
`
