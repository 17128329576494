import axios from 'axios'
import { PUBLIC_BUILDINGS, PUBLIC_BUILDING_SEARCH, PUBLIC_LISTINGS, PUBLIC_LISTING_SEARCH } from '../constants/api'

export const getListingById = (listingUuid) => {
  return axios({
    method: 'get',
    url: `${PUBLIC_LISTINGS}${listingUuid}/`,
  })
}

export const getBuildingById = (buildingUuid) => {
  return axios({
    method: 'get',
    url: `${PUBLIC_BUILDINGS}${buildingUuid}/`,
  })
}

export const getBuildingBySearch = (neighborhoodName, buildingName) => {
  return axios({
    method: 'get',
    url: `${PUBLIC_BUILDING_SEARCH}${neighborhoodName}/${buildingName}/`,
  })
}

export const getListingBySearch = (neighborhoodName, buildingName, unitNumber, towerName, listingType) => {
  if (towerName)
    return axios({
      method: 'get',
      url: `${PUBLIC_LISTING_SEARCH}${neighborhoodName}/${buildingName}/${towerName}/${unitNumber}/${listingType}/`,
    })
  else
    return axios({
      method: 'get',
      url: `${PUBLIC_LISTING_SEARCH}${neighborhoodName}/${buildingName}/${unitNumber}/${listingType}/`,
    })
}
