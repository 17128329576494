import styled from 'styled-components'

export const ListingsHeaderContainer = styled.div.attrs({
  className: 'ListingsHeaderContainer',
})`
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: space-between;
`

export const OrderingDropdownContainer = styled.div.attrs({
  className: 'OrderingDropdownContainer',
})`
  display: flex;
  align-items: center;
  gap: 0.5em;
  z-index: 165;
  height: 100%;
`
