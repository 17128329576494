import styled from 'styled-components'

export const Content = styled.div.attrs({
  className: 'Content',
})`
  line-height: 24px;
  letter-spacing: -0.2px;
  padding-bottom: 1.5em;
  border-bottom: 0.5px solid rgb(196, 196, 196);
  white-space: pre-line;
  @media (max-width: 992px) {
    font-size: 14px;
    padding-bottom: 1em;
  }
`

export const DescriptionContainer = styled.div.attrs({
  className: 'DescriptionContainer',
})`
  @media (max-width: 992px) {
    padding: 20px 20px 0 20px;
  }
`
