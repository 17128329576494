import styled from 'styled-components'

import THEME from '../../../constants/themes'

export const Button = styled.div.attrs({
  className: 'Button',
})(
  ({ disabled }) => `  
    width: 150px;
    border: 1px solid rgb(230, 230, 230);
    background: ${THEME.mediumBlue};
    color: white;
    padding: 1em;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  `,
)

export const CardContainer = styled.div.attrs({
  className: 'CardContainer',
})`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid var(--gray-300, #dee2e7);
  background: #fff;
  padding: 25px;
  width: 50%;
  z-index: 150;
  @media (max-width: 992px) {
    width: 100%;
  }
`

export const FormContainer = styled.form.attrs({
  className: 'FormContainer',
})`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const Image = styled.img.attrs({
  className: 'Image',
})`
  position: absolute;
  z-index: 100;
  width: 100%;
  // top: 0;
  // bottom: 0;
  left: 0;
  @media (max-width: 992px) {
    bottom: 100px;
    background: #e4f6f9;
  }
`

export const Message = styled.div.attrs({
  className: 'Message',
})`
  background: ${THEME.mediumBlue};
  color: white;
  padding: 1em;
  border-radius: 10px;
  margin-top: 1em;
`

export const MessageContainer = styled.div.attrs({
  className: `MessageContainer`,
})(
  ({ isError }) => `
    color: ${isError ? 'red' : 'inherit'};
    height: 20px;
    padding: 0 0.5em;
    font-size: 12px;
  `,
)

export const SettingsContainer = styled.div.attrs({
  className: 'SettingsContainer',
})`
  width: 100%;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  position: relative;
  background: rgb(134, 190, 203);
  padding-bottom: 240px;
  margin-bottom: -240px;
  @media (max-width: 992px) {
    background-color: rgb(134, 190, 203);
    margin-bottom: -170px;
    height: fit-content;
    padding-bottom: 200px;
  }
`

export const StyledInput = styled.input.attrs({
  className: 'StyledInput',
})(
  ({ isError }) => `
    border: 1px solid rgb(230, 230, 230);
    border-color: ${isError ? 'red' : 'default'};
    outline: none;
    box-shadow: none;
    width: 100%;
    height: 40px;
    padding: 0.5em;
    border-radius: 5px;
    &:focus {
      border-color: ${isError ? 'red' : THEME.lightBlue};
    }
  `,
)

export const WrapContent = styled.div.attrs({
  className: 'WrapContent',
})(
  ({ isError }) => `
    padding: 100px;
    background: rgb(229 246 249);
    @media (max-width: 992px) {
      padding: 20px;
    }
  `,
)
