import styled from 'styled-components'

export const StyledImage = styled.img.attrs({
  className: 'StyledImage',
})`
  border-radius: 10px;
  &:hover {
    opacity: 0.9;
    transform: scale(1.005);
    transition: all 0.2s ease-in-out 0s;
  }
`

export const StyledSVG = styled.svg.attrs({
  className: 'StyledSVG',
})`
  border-radius: 10px;
  &:hover {
    opacity: 0.9;
    transform: scale(1.005);
    transition: all 0.2s ease-in-out 0s;
  }
`
