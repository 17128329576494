import { useDispatch, useSelector } from 'react-redux'

import { SwitchViewButtonContainer, StyledSwitchViewButton } from './SwitchViewButton.style'
import { ReactComponent as SWITCH_ICON } from '../../../../assets/images/switch.svg'
import { pageActionReducer } from '../../../../store/page/pageSlice'

const SwitchViewButton = () => {
  const dispatch = useDispatch()

  const setCurrentMobileView = (payload) => dispatch(pageActionReducer.setSearchPage(payload))
  const { searchPage: currentMobileView } = useSelector((state) => state.page)

  const isMobileMapView = currentMobileView === 'map'

  const handleSwitchViewOnClick = () => setCurrentMobileView(currentMobileView === 'map' ? 'list' : 'map')

  return (
    <SwitchViewButtonContainer className="hidden-desktop" isMobileMapView={isMobileMapView}>
      <StyledSwitchViewButton onClick={handleSwitchViewOnClick} isMobileMapView={isMobileMapView}>
        <SWITCH_ICON /> {isMobileMapView ? 'LIST VIEW' : 'MAP VIEW'}
      </StyledSwitchViewButton>
    </SwitchViewButtonContainer>
  )
}

export default SwitchViewButton
