import { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga4'

import useOutsideClick from '../../../../hooks/outsideClick'
import { searchActionReducer } from '../../../../store/search/searchSlice'
import PriceFilterDropdown from './PriceFilterDropdown'
import { CloseIcon, DownArrow } from '../Containers'
import { ContainerMessage, ContainerTitle } from '../Containers/Containers.style'
import {
  ButtonsContainer,
  DoneButton,
  IconContainer,
  IconsContainer,
  PriceDropdownContainer,
  PriceFilterContainer,
  PriceFilterTriggerContainer,
  PriceOptionsContainer,
  ResetButton,
} from './PriceFilter.style'
import clickEvents from '../../../../services/analytics.service'

const PriceFilter = ({ refetchListings }) => {
  const closeIconRef = useRef()
  const containerRef = useRef()
  const minPriceInputRef = useRef()
  const maxPriceInputRef = useRef()
  const minPriceDropdownRef = useRef()
  const maxPriceDropdownRef = useRef()

  const dispatch = useDispatch()

  const { maxPrice, minPrice, page, type, url } = useSelector((state) => state.search)
  const { maxPriceInput, minPriceInput, priceContainer } = useSelector((state) => state.search)

  const setMaxPrice = (payload) => dispatch(searchActionReducer.setMaxPrice(payload))
  const setMinPrice = (payload) => dispatch(searchActionReducer.setMinPrice(payload))
  const setMaxPriceInput = (payload) => dispatch(searchActionReducer.setMaxPriceInput(payload))
  const setMinPriceInput = (payload) => dispatch(searchActionReducer.setMinPriceInput(payload))
  const setPriceContainer = (payload) => dispatch(searchActionReducer.setPriceContainer(payload))

  const [priceFilterIsOpen, setPriceFilterIsOpen] = useState(false)

  const formatCurrency = (value) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      // minimumSignificantDigits: 1,
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: value > 999 && value < 9999 ? 1 : 0,
      maximumFractionDigits: 1,
    })

  const minFormatted = formatCurrency(minPrice).format(minPrice)
  const maxFormatted = formatCurrency(maxPrice).format(maxPrice)

  const message =
    !!minPrice && !!maxPrice
      ? `${minFormatted} - ${maxFormatted}`
      : !!minPrice
      ? `At least ${minFormatted}`
      : !!maxPrice
      ? `${maxFormatted} or less`
      : 'Any'

  const closeIconIsHidden = priceContainer === 'Any'

  const maxSalePriceOptions = () => {
    return [
      { value: 250000, label: '250000' },
      { value: 500000, label: '500000' },
      { value: 1000000, label: '1000000' },
      { value: 2000000, label: '2000000' },
      { value: 5000000, label: '5000000' },
      { value: 10000000, label: '10000000' },
      { value: 20000000, label: '20000000' },
      { value: 50000000, label: '50000000' },
      { value: 100000000, label: '100000000' },
    ]
  }

  const minSalePriceOptions = () => {
    return [
      { value: 100000, label: '100000' },
      { value: 150000, label: '150000' },
      { value: 200000, label: '200000' },
      { value: 300000, label: '300000' },
      { value: 500000, label: '500000' },
      { value: 1000000, label: '1000000' },
      { value: 2000000, label: '2000000' },
      { value: 5000000, label: '5000000' },
      { value: 10000000, label: '10000000' },
    ]
  }

  const maxRentPriceOptions = () => {
    return [
      { value: 1500, label: '1500' },
      { value: 2000, label: '2000' },
      { value: 2500, label: '2500' },
      { value: 3000, label: '3000' },
      { value: 5000, label: '5000' },
      { value: 7500, label: '7500' },
      { value: 10000, label: '10000' },
      { value: 15000, label: '15000' },
      { value: 30000, label: '30000' },
    ]
  }

  const minRentPriceOptions = () => {
    return [
      { value: 500, label: '500' },
      { value: 1000, label: '1000' },
      { value: 1500, label: '1500' },
      { value: 2000, label: '2000' },
      { value: 2500, label: '2500' },
      { value: 3000, label: '3000' },
      { value: 5000, label: '5000' },
      { value: 7500, label: '7500' },
      { value: 10000, label: '10000' },
    ]
  }

  const minPriceOptions = type === 'FOR_RENT' ? minRentPriceOptions() : minSalePriceOptions()
  const maxPriceOptions = type === 'FOR_RENT' ? maxRentPriceOptions() : maxSalePriceOptions()

  const handlePriceFilterOnClose = () => {
    setPriceContainer(message)
    refetchListings({}, 'PriceFilter.handlePriceFilterOnClose')
  }

  const handleCloseIconOnClick = () => {
    setMinPrice(null)
    setMaxPrice(null)
    setMinPriceInput('')
    setMaxPriceInput('')
    setPriceContainer('Any')
    refetchListings({ price__gte: null, price__lte: null }, 'PriceFilter.handleCloseIconOnClick')
  }

  const handlePriceFilterOnClick = (event) => {
    if (closeIconRef?.current?.contains(event?.target)) return handleCloseIconOnClick()
    setPriceFilterIsOpen((priceFilterIsOpen) => !priceFilterIsOpen)
    ReactGA.event({
      category: 'action',
      action: 'search-page-price-filter-clicked',
    })
    clickEvents.PRICE_FILTER()
  }
  const handleDoneOnClick = () => {
    handlePriceFilterOnClose()
    setPriceFilterIsOpen(false)
  }
  const handleResetOnClick = () => {
    setMaxPrice(null)
    setMinPrice(null)
    setMinPriceInput('')
    setMaxPriceInput('')
  }

  useOutsideClick(
    containerRef,
    () => {
      if (!priceFilterIsOpen) return
      setPriceFilterIsOpen(false)
      handlePriceFilterOnClose()
    },
    [minPrice, maxPrice, page, priceFilterIsOpen, type, url],
  )

  return (
    <PriceFilterContainer ref={containerRef} isOpen={priceFilterIsOpen}>
      <PriceFilterTriggerContainer onClick={handlePriceFilterOnClick}>
        <ContainerTitle>Price</ContainerTitle>
        <ContainerMessage selection={priceContainer !== 'Any'}>
          {priceContainer}
          <IconsContainer>
            <IconContainer isHidden={closeIconIsHidden} ref={closeIconRef}>
              <CloseIcon />
            </IconContainer>
            <IconContainer>
              <DownArrow />
            </IconContainer>
          </IconsContainer>
        </ContainerMessage>
      </PriceFilterTriggerContainer>
      <PriceDropdownContainer isOpen={priceFilterIsOpen}>
        <PriceOptionsContainer>
          <PriceFilterDropdown
            options={minPriceOptions}
            placeholder="Min"
            title="Min Price"
            priceValue={minPrice}
            setPriceValue={setMinPrice}
            inputValue={minPriceInput}
            setInputValue={setMinPriceInput}
            inputRef={minPriceInputRef}
            dropdownRef={minPriceDropdownRef}
          />
          <PriceFilterDropdown
            options={maxPriceOptions}
            placeholder="Max"
            title="Max Price"
            priceValue={maxPrice}
            setPriceValue={setMaxPrice}
            inputValue={maxPriceInput}
            setInputValue={setMaxPriceInput}
            inputRef={maxPriceInputRef}
            dropdownRef={maxPriceDropdownRef}
          />
        </PriceOptionsContainer>
        <ButtonsContainer>
          <ResetButton onClick={handleResetOnClick}>Reset</ResetButton>
          <DoneButton onClick={handleDoneOnClick}>Done</DoneButton>
        </ButtonsContainer>
      </PriceDropdownContainer>
    </PriceFilterContainer>
  )
}

export default PriceFilter
