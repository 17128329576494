import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import useAxios from 'axios-hooks'

import * as api from '../../services'
import Ads from '../Ads'
import TopSection from './TopSection'
import MiddleSection from './MiddleSection'
import BottomSection from './BottomSection'
import transformToSchemaOrg from './schemaConvertor'
import { BuildingDetailsContainer, AdsContainer, CardContainer, NotFoundContainer } from './BuildingDetails.style'
import { PUBLIC_BUILDING_LISTINGS, PUBLIC_BUILDING_UNITS } from '../../constants/api'
import NotFound from './NotFound'
import { useBuildingContext } from '../../context/BuildingContext'

const BuildingDetails = ({ refetchListings }) => {
  const { buildingId, neighborhoodName, buildingName } = useParams()

  const { buildings } = useBuildingContext()

  const [building, setBuilding] = useState(null)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [buildingsPageMargin, setBuildingsPageMargin] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)

  const buildingListingsParams = useMemo(() => ({ building__uuid: building?.uuid }), [building])
  const buildingUnitsParams = useMemo(() => ({ building__uuid: building?.uuid }), [building])

  const [{ data: buildingListingsResponse, loading: buildingListingsLoading }, getBuildingListings] = useAxios(
    {
      url: PUBLIC_BUILDING_LISTINGS,
      params: buildingListingsParams,
    },
    {
      manual: true,
    },
  )
  const [{ data: buildingUnitsResponse, loading: buildingUnitsLoading }, getBuildingUnits] = useAxios(
    {
      url: PUBLIC_BUILDING_UNITS,
      params: buildingUnitsParams,
    },
    {
      manual: true,
    },
  )

  const tableIsLoading = buildingListingsLoading || buildingUnitsLoading || isLoading
  const listings = buildingListingsResponse
  const units = buildingUnitsResponse

  const isMobile = windowWidth <= 992
  const adsLeft = isMobile ? '-20px' : `-${buildingsPageMargin + 80}px`
  const adsWidth = isMobile ? 'calc(100% + 40px)' : `calc(100% + ${buildingsPageMargin * 2 + 160}px)`

  const schemaString = useMemo(() => {
    const schemaOrgJson = transformToSchemaOrg(building)
    const schemaString = JSON.stringify(schemaOrgJson)
    return schemaString
  }, [building])

  const retrieveMargin = (elm) => {
    if (elm != null) {
      let styles = window.getComputedStyle(elm)
      let ml = styles.getPropertyValue('margin-left')
      setBuildingsPageMargin(Number.parseInt(ml.replace('px', '')))
    }
  }

  useEffect(() => {
    const preloadedData = buildings?.[window.location.pathname]
    if (preloadedData) {
      setIsLoading(false)
      setBuilding(preloadedData)
      return
    }
    setIsLoading(true)
    const getBuildingFromUrl = () => {
      if (buildingId) return api.getBuildingById(buildingId)
      else return api.getBuildingBySearch(neighborhoodName, buildingName)
    }
    getBuildingFromUrl()
      .then((response) => {
        setBuilding(response?.data)
        setIsLoading(false)
      })
      .catch((error) => {
        // if (error?.response?.status === 404) navigate(`/${neighborhoodName}/${buildingName}`)
        // if (error?.response?.status === 404) navigate('/not-found')
        if (error?.response?.status === 404) setNotFound(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingId, neighborhoodName, buildingName])

  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions)
    return () => {
      window.removeEventListener('resize', setWindowDimensions)
    }
  }, [])

  useEffect(() => {
    if (!building) return
    if (buildingListingsResponse) return
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building])

  useEffect(() => {
    if (!building?.uuid) return
    getBuildingListings()
    getBuildingUnits()
  }, [building?.uuid, getBuildingListings, getBuildingUnits])

  useEffect(() => {
    if (!building) return
    if (buildingUnitsResponse) return
    getBuildingUnits()?.then((response) => setBuilding((building) => ({ ...building, units: response?.data })))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building])

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth)
  }

  const metaTitleType = building?.type ? building?.type?.charAt(0) + building?.type?.slice(1).toLowerCase() : 'Building'
  const metaTitle =
    building && !isLoading
      ? `${building?.name} - ${metaTitleType} in ${building?.neighborhood?.name} Miami`
      : 'OceanPads'
  const aOrAn = 'aeiou'?.includes(building?.amenities?.[0]?.[0]?.toLowerCase()) ? 'an' : 'a'
  const amenitiesString = building?.amenities?.length
    ? `Amenities include ${aOrAn} ${building?.amenities?.join(', ').toLowerCase()} and more.`
    : `Amenities include easy access to highways and local restaurants.`
  const metaDescription = `Explore ${building?.name} in ${
    building?.neighborhood?.name
  } with OceanPads. ${amenitiesString} Located at ${building?.addresses?.map((address) => address.value).join(', & ')}.`
  const canonicalUrl = building && !isLoading ? `https://www.oceanpads.com${building?.url}` : ''

  if (notFound)
    return (
      <NotFoundContainer ref={retrieveMargin} isNotFound>
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <link rel="canonical" href={canonicalUrl} />
          <script type="application/ld+json">{schemaString}</script>
        </Helmet>
        <NotFound building={building} />
      </NotFoundContainer>
    )

  return (
    <BuildingDetailsContainer ref={retrieveMargin}>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href={canonicalUrl} />
        <script type="application/ld+json">{schemaString}</script>
      </Helmet>
      <CardContainer>
        <TopSection building={building} isLoading={isLoading} refetchListings={refetchListings} />
      </CardContainer>
      <CardContainer>
        <MiddleSection building={building} isLoading={isLoading} />
      </CardContainer>
      <CardContainer className="hidden-mobile">
        <BottomSection building={{ listings, units, type: building?.type }} isLoading={tableIsLoading} />
      </CardContainer>
      <span className="hidden-desktop">
        <BottomSection building={{ listings, units, type: building?.type }} isLoading={tableIsLoading} />
      </span>
      <AdsContainer className="hidden-mobile" left={adsLeft} width={adsWidth}>
        <Ads variant="2" type="horizontal" />
      </AdsContainer>
      <AdsContainer className="hidden-desktop" left={adsLeft} width={adsWidth}>
        <Ads variant="3" />
      </AdsContainer>
    </BuildingDetailsContainer>
  )
}

export default BuildingDetails
