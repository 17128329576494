import styled from 'styled-components'

export const LinksContainer = styled.div.attrs({
  className: 'LinksContainer',
})`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const Neighborhood = styled.i.attrs({
  className: 'Neighborhood',
})``

export const NotFoundContainer = styled.div.attrs({
  className: 'NotFoundContainer',
})(
  () => `
  background: #74bfcc;
  img {
    width: 100%
  }
  `,
)

export const NotFoundHeader = styled.div.attrs({
  className: 'NotFoundHeader',
})(
  () => `
    background: #dff6f9;
    padding: 40px 80px;
    @media (max-width: 992px) {
      padding: 20px;
      height: 300px;
    }
  `,
)
