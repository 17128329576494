import styled from 'styled-components'

import THEME from '../../../constants/themes'

export const ActiveFormContainer = styled.div.attrs({
  className: 'ActiveFormContainer',
})`
  display: flex;
  flex-direction: column;
  height: 500px;
  // height: fit-content;
  width: 300px;
  overflow: hidden;
  margin: 20px;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: ${THEME.boxShadow};
`

export const Button = styled.button.attrs({
  className: 'Button',
})`
  width: 100%;
  border: none;
  background: ${THEME.mediumBlue};
  padding: 10px;
  color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 10px;
  cursor: pointer;
  min-height: 44px;
  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`

export const Divider = styled.div.attrs({
  className: 'Divider',
})`
  position: relative;
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background: #eee;
  span {
    position: absolute;
    padding: 0px 10px;
    background: ${THEME.backgroundGray};
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
`

export const FormContainer = styled.form.attrs({
  className: 'FormContainer',
})`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-end;
  gap: 5px;
  width: 50%;
  padding: 20px;
  // margin: 20px;
  height: 450px;
`

export const FormSliderContainer = styled.div.attrs({
  className: 'FormSliderContainer',
})(
  ({ activeForm }) => `
    display: flex;
    width: 200%;
    bottom: 0;
    position: absolute;
    transition: left 0.3s ease-in-out;
    left: ${activeForm === 0 ? 0 : '-100%'};
  `,
)

export const GoogleButton = styled.button.attrs({
  className: 'GoogleButton',
})`
  width: 100%;
  border: none;
  background: white;
  padding: 10px;
  color: #333;
  border: 1px solid #333;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 10px;
  cursor: pointer;
  min-height: 44px;
  &:hover {
    color: black;
    border-color: black;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`

export const MessageContainer = styled.div.attrs({
  className: `MessageContainer`,
})(
  ({ isError }) => `
    color: ${isError ? 'red' : 'inherit'};
    height: 20px;
    padding: 0 0.5em;
    font-size: 12px;
  `,
)

export const RegisterContainer = styled.div.attrs({
  className: 'RegisterContainer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const StyledInput = styled.input.attrs({
  className: 'StyledInput',
})(
  ({ isError }) => `
    border: 1px solid rgb(230, 230, 230);
    border-color: ${isError ? 'red' : 'default'};
    outline: none;
    box-shadow: none;
    width: 100%;
    height: 40px;
    padding: 0.5em;
    border-radius: 5px;
    &:focus {
      border-color: ${isError ? 'red' : THEME.lightBlue};
    }
  `,
)

export const SubtitleContainer = styled.div.attrs({
  className: 'SubtitleContainer',
})`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const TextContainer = styled.div.attrs({
  className: 'TextContainer',
})`
  text-align: center;
  height: 20px;
`

export const TitleContainer = styled.div.attrs({
  className: 'TitleContainer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // background: ${THEME.backgroundBlue};
  // color: white;
  height: 45px;
  font-size: 16px;
  font-weight: 600;
  // border-bottom: 4px solid rgb(230, 230, 230);
`
