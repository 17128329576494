import styled from 'styled-components'

export const CardsContainer = styled.div.attrs({
  className: 'CardsContainer',
})`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  gap: 1em;
`

export const SavedSearchesContainer = styled.div.attrs({
  className: 'SavedSearchesContainer',
})`
  padding: 1em;
  @media (max-width: 992px) {
    justify-content: center;
    width: 100%;
  }
`

export const Title = styled.div.attrs({
  className: 'Title',
})`
  font-size: 18px;
  font-weight: 600px;
`
