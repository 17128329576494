import { Content, DescriptionContainer } from './Description.style'
import { SectionTitle } from '../../BuildingDetails.style'

const Description = ({ building }) => (
  <DescriptionContainer>
    <SectionTitle>Description</SectionTitle>
    <Content>{building?.description || 'Coming soon.'}</Content>
  </DescriptionContainer>
)

export default Description
