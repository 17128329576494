import styled from 'styled-components'

export const ListingTypeFilterContainer = styled.div.attrs({
  className: 'ListingTypeFilterContainer',
})`
  display: flex;
  position: relative;
  border-bottom: 0.5px solid;
  margin-bottom: 17px;
`

export const RadioButton = styled.div.attrs({
  className: 'RadioButton',
})(
  ({ selected }) => `
    background: none;
    position: relative;
    text-align: center;
    z-index: 20;
    border: none;
    cursor: pointer;
    transition: all 0.8s ease 0s;
    padding: 11px;
    font-family:  'Nunito Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: ${selected ? '700' : '400'};;
    line-height: normal;
    letter-spacing: 1.82px;
    border-bottom: ${selected ? '2px solid #000' : '2px solid transparent'};
    color: #000;
  `,
)
