import styled from 'styled-components'
import THEME from '../../constants/themes'

export const AdsContainer = styled.div.attrs({
  className: 'AdContainer',
})(
  ({ variant }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${variant === '1' ? THEME.lightBlue : variant === '2' ? THEME.mediumBlue : THEME.darkBlue};
    min-height: 370px;
    min-width: 350px;
    padding: 20px;
    width: 100%;
    position: relative;
  `,
)

export const SponsorTag = styled.div.attrs({
  className: 'SponsorTag',
})(
  ({ variant }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${variant === '1' ? THEME.lightBlue : variant === '2' ? THEME.mediumBlue : THEME.darkBlue};
    position: absolute;
    top: -10px;
    left: 20px;
    width: 110px;
    height: 25px;
    padding: 5px;
    padding-bottom: 0;
    color: ${variant === '1' ? '#333' : '#ccc'};
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.18px;
    border-radius: 8px 8px 0 0;
  `,
)
