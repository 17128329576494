import { useDispatch, useSelector } from 'react-redux'
import { Loader } from 'semantic-ui-react'
import useAxios from 'axios-hooks'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import ReactGA from 'react-ga4'

import { CONSUMER_SAVED_BUILDINGS } from '../../../../constants/api'
import { Button, ButtonText, ButtonsContainer, SaveAndShareContainer, TextContainer } from './SaveAndShare.style'
import { useEffect } from 'react'
import { modalsActionReducer } from '../../../../store/modals/modalsSlice'
import clickEvents from '../../../../services/analytics.service'

function letterPosition(letter) {
  // Convert the letter to uppercase to handle both cases
  letter = (letter || '').toUpperCase()

  // Iterate until a valid letter is found
  while (letter.length > 0) {
    // Check if the character is a letter
    if (/^[A-Z]$/.test(letter)) {
      // Get the ASCII code of the letter
      var charCode = letter.charCodeAt(0)

      // Subtract the ASCII code of 'A' to get the position
      var position = charCode - 65 + 1

      return position
    } else {
      // Move to the next character
      letter = letter.slice(1)
    }
  }

  // If no valid letter is found, return 1
  return 1
}

const SaveAndShare = ({ building }) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  const openAuthModal = () => dispatch(modalsActionReducer.setAuthModalIsOpen(true))

  const userIsLoggedIn = !!user

  const [{ data: favoritesResponse, loading: loadingFavoritesResponse }, getFavorites] = useAxios(
    {
      url: CONSUMER_SAVED_BUILDINGS,
      data: {
        building__uuid: building?.uuid,
      },
      method: 'GET',
    },
    { manual: true },
  )

  const [{ loading: loadingAddFavorite }, addFavorite] = useAxios(
    {
      url: CONSUMER_SAVED_BUILDINGS,
      data: {
        building: building.uuid,
      },
      method: 'POST',
    },
    { manual: true },
  )

  const savedListing = favoritesResponse?.results?.find((e) => e.building === building.uuid)
  const isFavorite = !!savedListing

  const [{ loading: loadingRemoveFavorite }, removeFavorite] = useAxios(
    {
      url: `${CONSUMER_SAVED_BUILDINGS}${savedListing?.uuid}/`,
      method: 'DELETE',
    },
    { manual: true },
  )

  const saveButtonIsDisabled = loadingFavoritesResponse || loadingRemoveFavorite || loadingAddFavorite

  const handleSaveFavorite = () => {
    if (!userIsLoggedIn) openAuthModal()
    else {
      addFavorite().then((rs) => {
        getFavorites()
      })
    }
    ReactGA.event({
      category: 'action',
      action: `building-page-save-building-clicked`,
    })
    clickEvents.SAVE_BUILDING()
  }

  const handleRemoveFavorite = () => {
    if (!userIsLoggedIn) openAuthModal()
    else {
      removeFavorite().then((rs) => {
        getFavorites()
      })
    }
    ReactGA.event({
      category: 'action',
      action: `building-page-remove-building-clicked`,
    })
  }

  const handleShareOnClick = async () => {
    const dataShare = {
      title: document.title,
      url: window.location.href,
    }
    if (navigator.share && navigator.canShare(dataShare)) {
      try {
        await navigator.share(dataShare)
      } catch (error) {
        console.log(error)
      }
    }
    ReactGA.event({
      category: 'action',
      action: `building-page-share-building-clicked`,
    })
    clickEvents.SHARE_BUILDING()
  }

  useEffect(() => {
    if (!userIsLoggedIn) return
    getFavorites()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsLoggedIn])

  return (
    <SaveAndShareContainer>
      <TextContainer>
        <span>
          This building has been saved by {building?.numberOfSaves + letterPosition(building?.name?.[3]) + 5}{' '}
          {building?.numberOfSaves === 1 ? 'user' : 'users'}.
        </span>
        <span>
          See a problem with this building? Report it{' '}
          <a
            href={`mailto:help@oceanpads.com?subject=OceanPads | ${building?.name}&body=There is a problem with this building.`}
          >
            here.
          </a>
        </span>
      </TextContainer>
      <ButtonsContainer>
        {isFavorite ? (
          <Button onClick={handleRemoveFavorite} disabled={saveButtonIsDisabled}>
            {saveButtonIsDisabled ? (
              <Loader size="mini" inline="centered" active color="blue" inverted />
            ) : (
              <>
                <FavoriteIcon fontSize="small" />
                <ButtonText>Remove</ButtonText>
              </>
            )}
          </Button>
        ) : (
          <Button isOutline onClick={handleSaveFavorite} disabled={saveButtonIsDisabled}>
            {saveButtonIsDisabled ? (
              <Loader size="mini" inline="centered" active color="blue" />
            ) : (
              <>
                <FavoriteBorderIcon fontSize="small" />
                <ButtonText>Save</ButtonText>
              </>
            )}
          </Button>
        )}
        <Button onClick={handleShareOnClick}>
          <MailOutlineIcon fontSize="small" />
          <ButtonText>Share</ButtonText>
        </Button>
      </ButtonsContainer>
    </SaveAndShareContainer>
  )
}

export default SaveAndShare
