import { Link } from 'react-router-dom'
import styled from 'styled-components'

import THEME from '../../../../constants/themes'

export const AboutTheBuildingContainer = styled(Link).attrs({
  className: 'AboutTheBuildingContainer',
})`
  all: unset;
  cursor: pointer;
  margin: -15px;
  padding: 15px;
  margin-top: -20px;
  padding-top: 20px;
  border-radius: 0 0 10px 10px;
  &: hover {
    color: inherit;
    background: ${THEME.backgroundGray};
    cursor: pointer;
  }
  @media (max-width: 992px) {
    margin: 20px;
    margin-top: 0;
    padding: 0;
  }
`

export const BuildingAddresses = styled.div.attrs({
  className: 'BuildingAddresses',
})`
  display: flex;
  flex-direction: column;
`

export const BuildingDetails = styled.div.attrs({
  className: 'BuildingDetails',
})`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 200px;
  // gap: 1em;
`

export const BuildingImage = styled.img.attrs({
  className: 'BuildingImage',
})`
  height: 200px;
  width: 250px;
  object-fit: cover;
  border-radius: 10px;
`

export const BuildingImageMobile = styled.img.attrs({
  className: 'BuildingImageMobile',
})`
  height: 200px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`

export const BuildingName = styled.div.attrs({
  className: 'BuildingName',
})`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  color: ${THEME.linkColor};
`

export const DetailsContainer = styled.div.attrs({
  className: 'DetailsContainer',
})`
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
`

export const IconsContainer = styled.div.attrs({
  className: 'IconsContainer',
})`
  display: flex;
  gap: 2em;
  align-items: center;
`

export const Image = styled.img.attrs({
  className: 'Image',
})`
  height: 24px;
  width: 24px;
  margin-right: 7px;
`

export const Item = styled.div.attrs({
  className: 'Item',
})`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NeighborhoodName = styled.span.attrs({
  className: 'NeighborhoodName',
})`
  color: ${THEME.linkColor};
  font-weight: 650;
`

export const StyledLink = styled(Link).attrs({
  className: 'StyledLink',
})``
