import { createContext, useContext, useReducer } from 'react'

const BuildingContext = createContext()
export const useBuildingContext = () => useContext(BuildingContext)

const imageReducer = (state, action) => {
  switch (action.type) {
    case 'SET_BUILDING':
      return { ...state, [action.buildingsId]: action.buildings }
    default:
      return state
  }
}

export const BuildingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(imageReducer, {})

  const setBuildings = (buildingsId, buildings) => {
    dispatch({ type: 'SET_BUILDING', buildingsId, buildings })
  }

  return <BuildingContext.Provider value={{ buildings: state, setBuildings }}>{children}</BuildingContext.Provider>
}
