import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga4'

import { searchActionReducer } from '../../../../store/search/searchSlice'
import { ContainerMessage, ContainerTitle } from '../Containers/Containers.style'
import neighborhoodOptions from '../../../../constants/neighborhoods'
import {
  ExtraChipsContainer,
  IconContainer,
  IconsContainer,
  NeighborhoodsChipsContainer,
  NeighborhoodsContentContainer,
  NeighborhoodsInput,
  NeighborhoodsFilterContainer,
  NeighborhoodOption,
  NeighborhoodsOptionsContainer,
  NeighborhoodsTriggerContainer,
  NeighborhoodChipContainer,
  NeighborhoodChipValue,
  NeighborhoodChipIcon,
  OptionText,
} from './NeighborhoodsFilter.style'
import useOutsideClick from '../../../../hooks/outsideClick'
import { CloseIcon, DownArrow } from '../Containers'

const NeighborhoodsFilter = ({ refetchListings }) => {
  const closeIconRef = useRef()
  const containerRef = useRef()
  const inputRef = useRef()

  const dispatch = useDispatch()

  const { neighborhoods } = useSelector((state) => state.search)

  const [neighborhoodsFilterIsOpen, setNeighborhoodsFilterIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const setNeighborhoods = (payload) => dispatch(searchActionReducer.setNeighborhoods(payload))

  const closeIconIsHidden = !neighborhoods?.length

  const handleCloseIconOnClick = () => {
    setNeighborhoods([])
    setNeighborhoodsFilterIsOpen(false)
    setInputValue('')
    refetchListings({ building__neighborhood__uuid__in: null }, 'NeighborhoodsFilter.handleCloseIconOnClick')
  }

  const handleNeighborhoodFilterOnClick = (event) => {
    if (closeIconRef?.current?.contains(event?.target)) return handleCloseIconOnClick()
    setNeighborhoodsFilterIsOpen((neighborhoodsFilterIsOpen) => {
      neighborhoodsFilterIsOpen ? inputRef?.current?.blur() : inputRef?.current?.focus()
      return !neighborhoodsFilterIsOpen
    })
    ReactGA.event({
      category: 'action',
      action: 'home-page-neighborhood-filter-clicked',
    })
  }

  const handleInputOnChange = (event) => {
    setInputValue(event?.target?.value)
  }

  const handleOptionOnClick = (option, isSelected) => {
    const newNeighborhoods = isSelected
      ? neighborhoods?.filter((neighborhood) => neighborhood !== option?.value)
      : [...neighborhoods, option?.value]
    setNeighborhoods(newNeighborhoods)
    setNeighborhoodsFilterIsOpen(false)
    setInputValue('')
    refetchListings({ building__neighborhood__uuid__in: newNeighborhoods }, 'NeighborhoodsFilter.handleOptionOnClick')
  }

  const handleCloseChipOnClick = (event, option) => {
    event.preventDefault()
    event.stopPropagation()
    const newNeighborhoods = neighborhoods?.filter((neighborhood) => neighborhood !== option?.value)
    setNeighborhoods(newNeighborhoods)
    setNeighborhoodsFilterIsOpen(false)
    setInputValue('')
    refetchListings(
      { building__neighborhood__uuid__in: newNeighborhoods },
      'NeighborhoodsFilter.handleCloseChipOnClick',
    )
  }

  const handleInputOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      inputRef?.current?.blur()
      setNeighborhoodsFilterIsOpen(false)
      const remainingOptions = neighborhoodOptions?.filter(
        (option) => !inputValue || option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase()),
      )
      setInputValue('')
      if (remainingOptions?.length) {
        const firstOption = remainingOptions[0]
        const newNeighborhoods = [...neighborhoods, firstOption?.value]
        setNeighborhoods(newNeighborhoods)
        refetchListings(
          { building__neighborhood__uuid__in: newNeighborhoods },
          'NeighborhoodsFilter.handleInputOnKeyDown',
        )
      }
    }
  }

  const chips = neighborhoodOptions
    ?.filter((option) => neighborhoods?.includes(option?.value))
    ?.map((option) => (
      <NeighborhoodChipContainer key={option?.value}>
        <NeighborhoodChipValue>{option?.label}</NeighborhoodChipValue>
        <NeighborhoodChipIcon onClick={(event) => handleCloseChipOnClick(event, option)}>
          <CloseIcon />
        </NeighborhoodChipIcon>
      </NeighborhoodChipContainer>
    ))

  const inputInFocus = !neighborhoods?.length || neighborhoodsFilterIsOpen
  const chipsContainerInFocus = neighborhoodsFilterIsOpen
  const maxChips = 4
  const extraChipCount = chips?.length > maxChips && chips?.length - maxChips

  const options = neighborhoodOptions
    ?.filter((option) => !inputValue || option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase()))
    ?.map((option) => {
      const isSelected = neighborhoods?.includes(option?.value)
      return (
        <NeighborhoodOption
          key={option?.value}
          isSelected={isSelected}
          onClick={() => handleOptionOnClick(option, isSelected)}
        >
          <OptionText isSelected={isSelected}>{option?.label}</OptionText>
          {isSelected && (
            <NeighborhoodChipIcon>
              <CloseIcon />
            </NeighborhoodChipIcon>
          )}
        </NeighborhoodOption>
      )
    })

  useOutsideClick(
    containerRef,
    () => {
      setNeighborhoodsFilterIsOpen(false)
    },
    [neighborhoods],
  )

  return (
    <NeighborhoodsFilterContainer ref={containerRef} isOpen={neighborhoodsFilterIsOpen}>
      <NeighborhoodsTriggerContainer onClick={handleNeighborhoodFilterOnClick}>
        <ContainerTitle>Neighborhoods</ContainerTitle>
        <ContainerMessage>
          <NeighborhoodsContentContainer>
            <NeighborhoodsChipsContainer inFocus={chipsContainerInFocus}>
              {chips?.slice(0, maxChips)}
              {extraChipCount && <ExtraChipsContainer>+{extraChipCount}</ExtraChipsContainer>}
            </NeighborhoodsChipsContainer>
            <NeighborhoodsInput
              ref={inputRef}
              value={inputValue}
              onChange={handleInputOnChange}
              onKeyDown={handleInputOnKeyDown}
              placeholder={window.innerWidth > 992 ? 'Choose a Neighborhood...' : 'Select...'}
              inFocus={inputInFocus}
            />
          </NeighborhoodsContentContainer>
          <IconsContainer>
            <IconContainer isHidden={closeIconIsHidden} ref={closeIconRef}>
              <CloseIcon />
            </IconContainer>
            <IconContainer>
              <DownArrow />
            </IconContainer>
          </IconsContainer>
        </ContainerMessage>
      </NeighborhoodsTriggerContainer>
      <NeighborhoodsOptionsContainer isOpen={neighborhoodsFilterIsOpen}>{options}</NeighborhoodsOptionsContainer>
    </NeighborhoodsFilterContainer>
  )
}

export default NeighborhoodsFilter
