import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Button = styled.button.attrs({
  className: 'Button',
})`
  border: none;
  padding: 11px 26px;
  border-radius: 4px;
  background: #226597;
  color: #fff;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  line-height: 19.1px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`

export const HamburgerIconContainer = styled.div.attrs({
  className: 'HamburgerIconContainer',
})`
  padding: 0;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition: 0.2s all;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  position: absolute;
  left: 24px;
  top: 17.5px;
  color: #226597;
  font-weight: 500;
`

export const LogoContainer = styled.img.attrs({
  className: 'LogoContainer',
})`
  height: auto;
  width: auto;
  height: 35px;
  object-fit: cover;
  cursor: pointer;
  position: absolute;
  top: -2px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  @media (max-width: 992px) {
    height: 30px;
  }
`

export const MainHeaderContainer = styled.div.attrs({
  className: 'MainHeaderContainer',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 80px;
  height: 70px;
  position: fixed;
  z-index: 250;
  top: 0;
  left: 0;
  width: 100vw;
  background: #fff;
  flex-wrap: wrap;
  @media (max-width: 992px) {
    padding: 15px;
    align-items: center;
    justify-content: center;
    gap: 20px;
    height: 70px;
    border-bottom: 1px solid rgb(230, 230, 230);
  }
`

export const MainHeaderContentContainer = styled.div.attrs({
  className: 'MainHeaderContentContainer',
})`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 992px) {
    margin-left: auto;
  }
`

export const MainHeaderControlsContainer = styled.div.attrs({
  className: 'MainHeaderControlsContainer',
})`
  display: flex;
  flex-direction: row;
  gap: 1.5em;
`

export const NavbarItem = styled.div.attrs({
  className: 'NavbarItem',
})`
  padding: 0;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 15px;
  cursor: pointer;
  transition: 0.2s all;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  position: relative;
  height: 100%;
`

export const StyledLink = styled(Link).attrs({
  className: 'StyledLink',
})`
  color: inherit;
`
