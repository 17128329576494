import styled from 'styled-components'

export const BodyContainer = styled.div.attrs({
  className: 'BodyContainer',
})`
  color: #1a1f2c;
  background: #f8f9fa;
  font-size: 1em;
  margin: 0;
  top: 140px;
  position: absolute;
  padding: 0;
  display: flex;
  min-height: calc(100vh - 140px);
  flex-direction: column;
  width: 100%;
  padding-bottom: 600px;
  @media (max-width: 992px) {
    top: 70px;
    padding-bottom: 350px;
  }
`
