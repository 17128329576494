import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from 'semantic-ui-react'
import useAxios from 'axios-hooks'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import ReactGA from 'react-ga4'

import { CONSUMER_SAVED_LISTINGS } from '../../../../constants/api'
import clickEvents from '../../../../services/analytics.service'
import { modalsActionReducer } from '../../../../store/modals/modalsSlice'
import {
  Annotation,
  Button,
  ButtonText,
  ButtonsContainer,
  SaveAndShareContainer,
  SaveAndShareContentContainer,
  SaveAndShareTextContainer,
} from './SaveAndShare.style'

function letterPosition(letter) {
  // Convert the letter to uppercase to handle both cases
  letter = (letter || '').toUpperCase()

  // Iterate until a valid letter is found
  while (letter.length > 0) {
    // Check if the character is a letter
    if (/^[A-Z]$/.test(letter)) {
      // Get the ASCII code of the letter
      var charCode = letter.charCodeAt(0)

      // Subtract the ASCII code of 'A' to get the position
      var position = charCode - 65 + 1

      return position
    } else {
      // Move to the next character
      letter = letter.slice(1)
    }
  }

  // If no valid letter is found, return 1
  return 1
}

const SaveAndShare = ({ listing }) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  const openAuthModal = () => dispatch(modalsActionReducer.setAuthModalIsOpen(true))

  const userIsLoggedIn = !!user

  const [{ data: favoritesResponse, loading: loadingFavoritesResponse }, getFavorites] = useAxios(
    {
      url: CONSUMER_SAVED_LISTINGS,
      data: {
        listing__uuid: listing?.uuid,
      },
      method: 'GET',
    },
    { manual: true },
  )

  const [{ loading: loadingAddFavorite }, addFavorite] = useAxios(
    {
      url: CONSUMER_SAVED_LISTINGS,
      data: {
        listing: listing.uuid,
      },
      method: 'POST',
    },
    { manual: true },
  )

  const savedListing = favoritesResponse?.results?.find((e) => e.listing === listing.uuid)
  const isFavorite = !!savedListing

  const [{ loading: loadingRemoveFavorite }, removeFavorite] = useAxios(
    {
      url: `${CONSUMER_SAVED_LISTINGS}${savedListing?.uuid}/`,
      method: 'DELETE',
    },
    { manual: true },
  )

  const saveButtonIsDisabled = loadingFavoritesResponse || loadingRemoveFavorite || loadingAddFavorite

  const handleSaveFavorite = () => {
    ReactGA.event({
      category: 'action',
      action: 'listing-page-save-listing-clicked',
    })
    clickEvents.SAVE_LISTING()
    if (!userIsLoggedIn) openAuthModal()
    else {
      addFavorite().then((rs) => {
        getFavorites()
      })
    }
  }

  const handleRemoveFavorite = () => {
    ReactGA.event({
      category: 'action',
      action: 'listing-page-remove-listing-clicked',
    })
    if (!userIsLoggedIn) openAuthModal()
    else {
      removeFavorite().then((rs) => {
        getFavorites()
      })
    }
  }

  const handleShareOnClick = async () => {
    ReactGA.event({
      category: 'action',
      action: 'listing-page-share-listing-clicked',
    })
    clickEvents.SHARE_LISTING()
    const dataShare = {
      title: document.title,
      url: window.location.href,
    }
    if (navigator.share && navigator.canShare(dataShare)) {
      try {
        await navigator.share(dataShare)
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    if (!userIsLoggedIn) return
    getFavorites()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsLoggedIn])

  const messageSubject = `OceanPads | ${listing?.building?.name} - Unit ${listing?.unit?.number}`
  const messageBody = encodeURIComponent(`There is a problem with this listing.\rhttps://oceanpads.com${listing?.url}`)

  return (
    <SaveAndShareContainer>
      <SaveAndShareContentContainer>
        <SaveAndShareTextContainer>
          <Annotation>
            This listing has been saved by{' '}
            {listing?.numberOfSaves +
              letterPosition(listing?.building?.name?.[listing?.building?.name?.length - 3]) +
              Number(listing?.unit?.number?.slice(-1)) * 2}{' '}
            {listing?.numberOfSaves === 1 ? 'user' : 'users'}.
          </Annotation>
          <Annotation>
            See a problem with this listing? Report it{' '}
            <a href={`mailto:help@oceanpads.com?subject=${messageSubject}&body=${messageBody}`}>here.</a>
          </Annotation>
        </SaveAndShareTextContainer>
        <ButtonsContainer>
          {isFavorite ? (
            <Button onClick={handleRemoveFavorite} disabled={saveButtonIsDisabled}>
              {saveButtonIsDisabled ? (
                <Loader size="mini" inline="centered" active color="blue" />
              ) : (
                <>
                  <FavoriteIcon fontSize="small" />
                  <ButtonText>Remove</ButtonText>
                </>
              )}
            </Button>
          ) : (
            <Button isOutline onClick={handleSaveFavorite} disabled={saveButtonIsDisabled}>
              {saveButtonIsDisabled ? (
                <Loader size="mini" inline="centered" active color="blue" />
              ) : (
                <>
                  <FavoriteBorderIcon fontSize="small" />
                  <ButtonText>Save</ButtonText>
                </>
              )}
            </Button>
          )}
          <Button onClick={handleShareOnClick}>
            <MailOutlineIcon fontSize="small" />
            <ButtonText>Share</ButtonText>
          </Button>
        </ButtonsContainer>
      </SaveAndShareContentContainer>
    </SaveAndShareContainer>
  )
}

export default SaveAndShare
