import styled from 'styled-components'
import THEME from '../../../../constants/themes'

export const AgentsContainer = styled.div.attrs({
  className: 'AgentsContainer',
})`
  @media (max-width: 992px) {
    padding: 0 20px;

    padding-top: 20px;
  }
`

export const TitleSecondary = styled.div.attrs({
  className: 'TitleSecondary',
})`
  color: #1c1c1c;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.2px;
  margin: 8px 0;
  @media (max-width: 992px) {
    font-size: 14px;
  }
`

export const Text = styled.div.attrs({
  className: 'Text',
})`
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 3px;
  border-radius: 4px;
  > svg {
    min-width: 20px;
  }
  > a {
    display: contents;
    color: ${THEME.darkBlue};
  }
  &:hover {
    background: ${THEME.backgroundGray};
  }
`

export const AgentCardsContainer = styled.div.attrs({
  className: 'AgentCardsContainer',
})`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  @media (max-width: 992px) {
    flex-direction: column;
    gap: 13px;
    padding-bottom: 13px;
  }

  border-bottom: 0.5px solid rgb(196, 196, 196);
  padding-bottom: 1.5em;
`

export const AgentCardContainer = styled.div.attrs({
  className: 'AgentCardContainer',
})`
  margin-right: auto;
  @media (max-width: 992px) {
    margin: 0;
    flex-direction: column;
    gap: 0px !important;
    justify-content: flex-start;
    text-align: left;
    align-items: flex-start;
  }
`
