import { memo, useRef, useState } from 'react'
import Switch from '@mui/material/Switch'
import useAxios from 'axios-hooks'

import Item from './Item'
import OrderingDropdown from './OrderingDropdown'
import useOutsideClick from '../../../hooks/outsideClick'
import Loader from './Loader'
import { CONSUMER_SAVED_LISTING_PREVIEWS } from '../../../constants/api'
import {
  CardsContainer,
  HeaderContainer,
  HeaderTitle,
  OnMarketContainer,
  OnMarketTrigger,
  OrderByContainer,
  OrderByTrigger,
  ResultsTitle,
  SavedSalesContainer,
} from './SavedSales.style'

const SavedSales = () => {
  const orderByRef = useRef()

  const [savedSalesOnMarketOnly, setSavedSalesOnMarketOnly] = useState(false)
  const [savedSalesOrderBy, setSavedSalesOrderBy] = useState('newest')

  const [{ data: savedSalesResponse, loading: savedSalesResponseIsLoading }] = useAxios(
    {
      url: CONSUMER_SAVED_LISTING_PREVIEWS,
      params: { listing__type: 'FOR_SALE' },
    },
    { useCache: false },
  )

  const sortingMethods = {
    newest: (a, b) => b.onMarketDate?.localeCompare(a.onMarketDate),
    recentlyUpdated: (a, b) => b.lastModificationDatetime?.localeCompare(a.lastModificationDatetime),
    mostExpensive: (a, b) => b.price - a.price,
    leastExpensive: (a, b) => a.price - b.price,
    largest: (a, b) => b.unit?.squareFootage - a.unit?.squareFootage,
    smallest: (a, b) => a.unit?.squareFootage - b.unit?.squareFootage,
  }

  const toggleSavedSalesOnMarketOnly = () => setSavedSalesOnMarketOnly((onMarketOnly) => !onMarketOnly)

  const [orderingDropdownIsOpen, setOrderingDropdownIsOpen] = useState(false)

  const numberOfSavedSales = savedSalesResponse?.count
  const savedSales = savedSalesResponse?.results
    ?.filter((savedSale) => (savedSalesOnMarketOnly ? savedSale.status === 'OPEN' : savedSale))
    ?.sort(sortingMethods[savedSalesOrderBy])

  const resultsTitle = numberOfSavedSales === 1 ? `1 Saved Sale` : `${numberOfSavedSales} Saved Sales`

  const handleOnMarketTriggerOnClick = () => toggleSavedSalesOnMarketOnly()
  const handleOrderByTriggerOnClick = () => setOrderingDropdownIsOpen((isOpen) => !isOpen)

  const savedSalesPreviewCards = savedSales?.map((savedSale) => <Item listing={savedSale} key={savedSale?.uuid} />)

  useOutsideClick(orderByRef, () => setOrderingDropdownIsOpen(false), [])

  return (
    <SavedSalesContainer>
      <HeaderContainer>
        <OnMarketContainer isActive={savedSalesOnMarketOnly}>
          <OnMarketTrigger onClick={handleOnMarketTriggerOnClick}>
            <HeaderTitle>On Market Only</HeaderTitle>
            <Switch checked={savedSalesOnMarketOnly} />
          </OnMarketTrigger>
        </OnMarketContainer>
        <OrderByContainer ref={orderByRef} isActive={orderingDropdownIsOpen}>
          <OrderByTrigger onClick={handleOrderByTriggerOnClick}>
            <OrderingDropdown
              dropdownIsOpen={orderingDropdownIsOpen}
              setDropdownIsOpen={setOrderingDropdownIsOpen}
              savedSalesOrderBy={savedSalesOrderBy}
              setSavedSalesOrderBy={setSavedSalesOrderBy}
            />
          </OrderByTrigger>
        </OrderByContainer>
      </HeaderContainer>
      {savedSalesResponseIsLoading ? (
        <Loader />
      ) : (
        <>
          <ResultsTitle>{resultsTitle}</ResultsTitle>
          <CardsContainer>{savedSalesPreviewCards}</CardsContainer>
        </>
      )}
    </SavedSalesContainer>
  )
}

export default memo(SavedSales)
