import styled from 'styled-components'

export const CardContainer = styled.div.attrs({
  className: 'CardContainer',
})`
  overflow: unset;
  container-type: inline-size;
  .slick-list {
    overflow: unset;
    overflow-x: clip;
  }
  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    z-index: -1;
    height: 20px;
    width: 30px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: #4183c4;
    top: 50%;
    padding: 0;
    border: none;
    outline: none;
    &:hover,
    &:focus {
      outline: none;
      background: transparent;
      color: transparent;
      &:before {
        opacity: $slick-opacity-on-hover;
      }
    }
    &.slick-disabled:before {
      opacity: $slick-opacity-not-active;
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 50px;
    line-height: 1;
    color: #4183c4;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-prev {
    left: 0 !important;
    z-index: 9;
    [dir='rtl'] & {
      left: auto;
      left: 0 !important;
      z-index: 9;
    }
    &:before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.68457 15L2.31615 8L9.68457 1' stroke='%2300669A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      width: 36px;
      height: 36px;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 23px;
      border: 1px solid rgba(0, 102, 154, 0.2);
      background-color: #e6f2f4;
      background-position: center;
      [dir='rtl'] & {
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.31592 1L8.68434 8L1.31592 15' stroke='%2300669A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }
  }
  .slick-next {
    right: 0 !important;
    z-index: 9;
    [dir='rtl'] & {
      left: 0 !important;
      right: auto;
    }
    &:before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.31592 1L8.68434 8L1.31592 15' stroke='%2300669A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      width: 36px;
      height: 36px;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 23px;
      border: 1px solid rgba(0, 102, 154, 0.2);
      background-color: #e6f2f4;
      background-position: center;
      [dir='rtl'] & {
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.68457 15L2.31615 8L9.68457 1' stroke='%2300669A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }
  }
  .slick-slide {
    box-shadow: none;
    max-width: 100%;
    padding: 0 15px;
  }
  @media (max-width: 992px) {
    margin: 0;
    .slick-slide {
      padding: 0;
    }
    position: relative;
    .slick-prev {
      left: 10px !important;
    }
    .slick-next {
      right: 10px !important;
    }
    .slick-track,
    .slick-list {
      height: 100%;
      border-radius: 0 0 10px 10px;
    }
  }
`

export const Card = styled.div.attrs({
  className: 'Card',
})`
  box-shadow: none;
  border: 0.5px solid #c4c4c4;
  cursor: pointer;
  @media (max-width: 600px) {
    border: none;
    overflow: unset;
  }
`

export const SimilarListingsContainer = styled.div.attrs({
  className: 'SimilarListingsContainer',
})`
  display: flex;
  gap: 1em;
  flex-direction: column;
`

export const SimilarListingsTitleContainer = styled.div.attrs({
  className: 'SimilarListingsTitleContainer',
})`
  @media (max-width: 992px) {
    padding: 20px;
    padding-bottom: 0;
  }
`
