import { SectionTitle } from '../../ListingDetail.style'
import { AmenitiesContainer, AmenitiesContentContainer, AmenityText } from './Amenities.style'

const Amenities = ({ listing }) => {
  const amenitiesSet = new Set()
  const amenitiesToRender = listing?.amenities?.filter((amenity) => {
    if (!amenitiesSet.has(amenity.alternativeText) && amenity.display) {
      amenitiesSet.add(amenity.alternativeText)
      return true
    }
    return false
  })

  const amenities = amenitiesToRender?.map((amenity, index) => (
    <AmenityText key={index}>{amenity.alternativeText}</AmenityText>
  ))

  return (
    <AmenitiesContainer>
      <SectionTitle>Amenities</SectionTitle>
      <AmenitiesContentContainer>{amenities?.length ? amenities : 'Contact for details.'}</AmenitiesContentContainer>
    </AmenitiesContainer>
  )
}

export default Amenities
