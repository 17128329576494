import styled from 'styled-components'

export const HeaderContainer = styled.div.attrs({
  className: 'HeaderContainer',
})`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1240px) {
    flex-direction: column;
  }
`

export const HeaderTitle = styled.div.attrs({
  className: 'HeaderTitle',
})`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  @media (max-width: 992px) {
    font-size: 14px;
  }
`
