import styled from 'styled-components'
import { Popup } from 'react-leaflet'
import { Skeleton } from '@mui/material'
import THEME from '../../../../../../constants/themes'

export const BuildingImage = styled.img.attrs({
  className: 'BuildingImage',
})`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`

export const IconContainer = styled.div.attrs({
  className: 'IconContainer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border-radius: 10px;
  &:hover {
    background: rgba(230, 242, 244, 0.325);
  }
`

export const HeaderContainer = styled.div.attrs({
  className: 'HeaderContainer',
})`
  position: relative;
  display: flex;
  gap: 2px;
  flex-direction: column;
  width: 100%;
  border-radius: 10px 10px 0 0;
  height: 150px;
  box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.2);
  > a {
    z-index: 1;
    background: rgba(230, 242, 244, 0.2);
    border-radius: 10px 10px 0 0;
    // padding: 10px;
    height: 100%;
    &:hover {
      background: rgba(230, 242, 244, 0.325);
    }
  }
`

export const HeaderTextContainer = styled.div.attrs({
  className: 'HeaderTextContainer',
})`
  width: 100%;
  height: 100%;
  // width: calc(100% - 10px);
  // margin: 5px auto;
  // width: fit-content;
  // margin: 5px;
  padding: 5px 10px;
  // background: ${THEME.backgroundBlue};
  background: rgba(230, 242, 244, 0.675);
  border-radius: 10px 10px 0 0;
  border: 1px solid rgb(179, 196, 202);
`

export const ListingCardsContainer = styled.div.attrs({
  className: 'ListingCardsContainer',
})`
  max-height: 293px;
  overflow: auto;
  padding: 0px 10px;
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 1px solid #dee2e7;
    &::-webkit-scrollbar {
      width: 4px;
    }
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    border: 1px solid #dee2e7;
  }
`
export const LoadingSkeleton = styled(Skeleton)`
  width: 100% !important;
  display: block !important;
  position: relative !important;
  transform: none !important;
`

export const PopupControlsContainer = styled.div.attrs({
  className: 'PopupControlsContainer',
})`
  position: absolute;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 99;
  cursor: pointer;
  bakground: white;
  right: 0px;
  top: 0px;
  gap: 0;
  & > svg {
    &:hover {
      opacity: 0.9;
    }
  }
`

export const StyledPopup = styled(Popup).attrs({
  className: 'StyledPopup',
})`
  .leaflet-popup-content {
    min-width: 390px;
    background: ${THEME.backgroundBlue};
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 10px !important;
  }
  .leaflet-popup-content-wrapper {
    min-width: 390px;
    border-radius: 10px !important;
    box-shadow: -1px 4px 15px 0px rgba(0, 0, 0, 0.25);
    padding: 0;
  }
  .leaflet-popup-close-button {
    display: none;
  }
  .leaflet-popup-tip {
    background: ${THEME.backgroundBlue};
  }
  @media (max-width: 992px) {
    .leaflet-popup-content-wrapper,
    .leaflet-popup-content {
      max-width: calc(100vw - 2px);
      min-width: 370px;
    }
  }
`

export const SubTitleContainer = styled.div.attrs({
  className: 'SubTitleContainer',
})`
  color: #333;
  display: flex;
  flex-direction: column;
  font-family: Nunito Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const TitleContainer = styled.div.attrs({
  className: 'TitleContainer',
})`
  color: ${THEME.darkblue};
  font-size: 16px;
  font-weight: 700;
  font-family: Nunito Sans;
  font-style: normal;
  line-height: normal;
`
