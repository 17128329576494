import { useRef } from 'react'

import { DownArrow } from '../../../Search/Filters/Containers'
import {
  IconContainer,
  IconsContainer,
  OrderingDropdownContainer,
  OrderingOptionContainer,
  OrderingOptionsContainer,
  OrderingValueContainer,
} from './OrderingDropdown.style'

const OrderingDropdown = ({ dropdownIsOpen, setDropdownIsOpen, savedRentalsOrderBy, setSavedRentalsOrderBy }) => {
  const dropdownRef = useRef()

  const options = [
    { label: 'Newest', value: 'newest' },
    { label: 'Recently Updated ', value: 'recentlyUpdated' },
    { label: 'Most Expensive', value: 'mostExpensive' },
    { label: 'Least Expensive', value: 'leastExpensive' },
    { label: 'Largest', value: 'largest' },
    { label: 'Smallest', value: 'smallest' },
  ]

  const orderingOption = options?.find((option) => option?.value === savedRentalsOrderBy)

  const handleOptionOnClick = (option) => {
    setSavedRentalsOrderBy(option?.value)
    setDropdownIsOpen(false)
  }

  const orderingOptions = options
    ?.filter((option) => option?.value !== savedRentalsOrderBy)
    ?.map((option) => (
      <OrderingOptionContainer key={option?.value} onClick={() => handleOptionOnClick(option)}>
        {option?.label}
      </OrderingOptionContainer>
    ))

  return (
    <OrderingDropdownContainer ref={dropdownRef}>
      <OrderingValueContainer>
        {orderingOption?.label} First
        <IconsContainer>
          <IconContainer>
            <DownArrow />
          </IconContainer>
        </IconsContainer>
      </OrderingValueContainer>
      <OrderingOptionsContainer isOpen={dropdownIsOpen}>{orderingOptions}</OrderingOptionsContainer>
    </OrderingDropdownContainer>
  )
}

export default OrderingDropdown
