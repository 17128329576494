import { API_OCEAN_PADS } from './env.const'

// public read only endpoints
export const PUBLIC_ADDRESSES = `${API_OCEAN_PADS}/api/public/addresses/`
export const PUBLIC_BUILDING_NAMES = `${API_OCEAN_PADS}/api/public/building-names/`
export const PUBLIC_BUILDING_LISTINGS = `${API_OCEAN_PADS}/api/public/building-listings/`
export const PUBLIC_BUILDING_PREVIEWS = `${API_OCEAN_PADS}/api/public/building-previews/`
export const PUBLIC_BUILDING_SEARCH = `${API_OCEAN_PADS}/api/public/building-search/`
export const PUBLIC_BUILDING_UNITS = `${API_OCEAN_PADS}/api/public/building-units/`
export const PUBLIC_BUILDINGS = `${API_OCEAN_PADS}/api/public/buildings/`
export const PUBLIC_FEATURED_LISTING_PREVIEWS = `${API_OCEAN_PADS}/api/public/featured-listing-previews/`
export const PUBLIC_LISTING_GROUPS = `${API_OCEAN_PADS}/api/public/listing-groups/`
export const PUBLIC_LISTING_PREVIEWS = `${API_OCEAN_PADS}/api/public/listing-previews/`
export const PUBLIC_LISTING_SEARCH = `${API_OCEAN_PADS}/api/public/listing-search/`
export const PUBLIC_LISTINGS = `${API_OCEAN_PADS}/api/public/listings/`
export const PUBLIC_NEIGHBORHOODS = `${API_OCEAN_PADS}/api/public/neighborhoods/`
export const PUBLIC_NEW_DEVELOPMENTS = `${API_OCEAN_PADS}/api/public/new-developments/`
export const PUBLIC_RATES = `${API_OCEAN_PADS}/api/public/rates/`

// public event endpoints
export const PUBLIC_CLICK = `${API_OCEAN_PADS}/api/public/clicks/`

// auth endpoints:
export const AUTH_NEW_USER = `${API_OCEAN_PADS}/api/authorization/new-user/`
export const AUTH_SOCIAL_TOKEN_USER = `${API_OCEAN_PADS}/api/authorization/social/token_user/`
export const AUTH_TOKENS_SESSION = `${API_OCEAN_PADS}/api/authorization/tokens-session/`
export const AUTH_USERS = `${API_OCEAN_PADS}/api/authorization/users/`
export const AUTH_VALIDATE_EMAIL = `${API_OCEAN_PADS}/api/authorization/validate-email/`
export const AUTH_VALIDATE_PASSWORD = `${API_OCEAN_PADS}/api/authorization/validate-password/`

// private endpoints
export const CONSUMER_SAVED_BUILDING_PREVIEWS = `${API_OCEAN_PADS}/api/consumer/saved-building-previews/`
export const CONSUMER_SAVED_BUILDINGS = `${API_OCEAN_PADS}/api/consumer/saved-buildings/`
export const CONSUMER_SAVED_LISTING_PREVIEWS = `${API_OCEAN_PADS}/api/consumer/saved-listing-previews/`
export const CONSUMER_SAVED_LISTINGS = `${API_OCEAN_PADS}/api/consumer/saved-listings/`
export const CONSUMER_SAVED_SEARCHES = `${API_OCEAN_PADS}/api/consumer/saved-searches/`

export const REALTOR_BRAINTREE_PAYMENT_METHODS = `${API_OCEAN_PADS}/api/realtor/braintree-payment-methods/`
export const REALTOR_BRAINTREE_TOKEN = `${API_OCEAN_PADS}/api/realtor/braintree-token/`
export const REALTOR_FEATURED_LISTING_PREVIEWS = `${API_OCEAN_PADS}/api/realtor/featured-listing-previews/`
export const REALTOR_FEATURED_LISTINGS = `${API_OCEAN_PADS}/api/realtor/featured-listings/`
export const REALTOR_LISTING_PREVIEWS = `${API_OCEAN_PADS}/api/realtor/listing-previews/`
export const REALTOR_LISTING_GROUPS = `${API_OCEAN_PADS}/api/realtor/listing-groups/`
export const REALTOR_PROFILES = `${API_OCEAN_PADS}/api/realtor/profiles/`

export const ADMIN_ADDRESSES = `${API_OCEAN_PADS}/api/admin/addresses/`
export const ADMIN_BUILDING_MEDIAS = `${API_OCEAN_PADS}/api/admin/building-medias/`
export const ADMIN_BUILDING_PREVIEWS = `${API_OCEAN_PADS}/api/admin/building-previews/`
export const ADMIN_BUILDINGS = `${API_OCEAN_PADS}/api/admin/buildings/`
