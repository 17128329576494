import { AgentsContainer, AgentCardsContainer, AgentCardContainer, TitleSecondary, Text } from './Agents.style'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga4'

import { ReactComponent as USER_ICON } from '../../../../assets/images/agent-user.svg'
import { ReactComponent as EMAIL_ICON } from '../../../../assets/images/agent-email.svg'
import { ReactComponent as PHONE_ICON } from '../../../../assets/images/agent-phone.svg'
import { SectionTitle } from '../../ListingDetail.style'
import clickEvents from '../../../../services/analytics.service'

const Agents = ({ listing }) => {
  const agents = listing?.agents
  const office = agents?.length > 0 && agents[0]?.office

  const messageSubject = encodeURIComponent(
    `OceanPads - Inquiring about ${listing?.address?.value} Unit ${listing?.unit?.number}`,
  )
  const messageBody = encodeURIComponent(
    `Hi,\r\rI found your listing on OceanPads and I am interested in learning more about it.\r\r${listing?.address?.value} Unit ${listing?.unit?.number} - https://oceanpads.com${listing?.url}`,
  )

  const agentCards = agents?.map((agent) => {
    const emailLink = `mailto:${agent?.email}?subject=${messageSubject}&body=${messageBody}`
    const phoneLink =
      agent?.officePhone || agent?.directPhone
        ? `+1${(agent?.officePhone || agent?.directPhone || '').replaceAll('-', '')}`
        : null

    const handleAgentNameOnClick = () => {
      ReactGA.event({
        category: 'action',
        action: 'listing-page-agent-name-clicked',
      })
      clickEvents.AGENT_NAME()
    }
    const handleAgentEMailOnClick = () => {
      ReactGA.event({
        category: 'action',
        action: 'listing-page-agent-email-clicked',
      })
      clickEvents.AGENT_EMAIL()
    }
    const handleAgentPhoneOnClick = () => {
      ReactGA.event({
        category: 'action',
        action: 'listing-page-agent-phone-clicked',
      })
      clickEvents.AGENT_PHONE()
    }

    return (
      <AgentCardContainer key={agent?.uuid}>
        <Text>
          <Link to={emailLink} onClick={handleAgentNameOnClick}>
            <USER_ICON /> {agent?.fullName}
          </Link>
        </Text>
        <Text>
          <Link to={emailLink} onClick={handleAgentEMailOnClick}>
            <EMAIL_ICON />
            {agent?.email || 'N/A'}
          </Link>
        </Text>
        <Text>
          <Link to={`tel:${phoneLink}`} onClick={handleAgentPhoneOnClick}>
            <PHONE_ICON />
            {agent?.officePhone || agent?.directPhone || 'N/A'}
          </Link>
        </Text>
      </AgentCardContainer>
    )
  })

  return (
    <AgentsContainer>
      <SectionTitle>Listed By</SectionTitle>
      <TitleSecondary>{office}</TitleSecondary>
      <AgentCardsContainer>{agentCards}</AgentCardsContainer>
    </AgentsContainer>
  )
}

export default Agents
