import ReactGA from 'react-ga4'

import clickEvents from '../../../services/analytics.service'
import { BillboardContainer, SponsorTag } from './Billboard.style'
import Fileforms from './Fileforms'
import { ADS_FILEFORMS_MOBILE } from '../../../constants/image.const'

const Billboard = () => {
  const referralLink =
    'https://fileforms.com/landing?RR_WCID=FC2CDB88-F96A-437A-AD70-20AC10939103&RR_WCID_TTL=396&REFERRALCODE=55208829'

  const handleFileformsOnClick = (event) => {
    event?.preventDefault()
    ReactGA.event({
      category: 'action',
      action: `fileforms-ad-clicked`,
    })
    clickEvents.FILEFORMS()
    window.open(referralLink, '_blank')
  }

  return (
    <>
      <BillboardContainer className="hidden-mobile">
        <SponsorTag>OUR SPONSORS</SponsorTag>
        <a href={referralLink} target="_blank" rel="noreferrer" onClick={handleFileformsOnClick}>
          <Fileforms />
        </a>
      </BillboardContainer>
      <BillboardContainer className="hidden-desktop">
        <SponsorTag>OUR SPONSORS</SponsorTag>
        <a href={referralLink} target="_blank" rel="noreferrer" onClick={handleFileformsOnClick}>
          <img src={ADS_FILEFORMS_MOBILE} alt="Fileforms" />
        </a>
      </BillboardContainer>
    </>
  )
}

export default Billboard
