import { useDispatch, useSelector } from 'react-redux'
import useAxios from 'axios-hooks'
import { useCallback, useEffect, useMemo } from 'react'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'

import { BodyContainer } from '../components.style'
import { Footer } from '../Footer'
import Navigation from '../Navigation'
import Header from '../Header'
import MobilePanels from '../MobilePanels'
import Modals from '../Modals'
import { selectionsActionReducer } from '../../store/selections/selectionsSlice'
import { searchActionReducer } from '../../store/search/searchSlice'
import {
  CONSUMER_SAVED_LISTINGS,
  CONSUMER_SAVED_SEARCHES,
  PUBLIC_FEATURED_LISTING_PREVIEWS,
  PUBLIC_LISTING_GROUPS,
  PUBLIC_LISTING_PREVIEWS,
} from '../../constants/api'
import { generateSearchURLs } from '../../services/searchParams.service'
import clickEvents from '../../services/analytics.service'
import findSavedSearch from '../../services/findSavedSearch.service'

const SearchController = ({ currentUser, setCurrentUser }) => {
  // this high level component is responsible for provoding the logic for refetching listings from the backend
  // all child components can manipulate the search criteria in some way and, hence, will need access to the
  // refetchListings function

  const dispatch = useDispatch()

  const activeParams = useSelector((state) => state.search)
  const { user } = useSelector((state) => state.auth)

  const resetSelectedBuilding = () => dispatch(selectionsActionReducer?.setBuilding(null))
  const setCount = (payload) => dispatch(searchActionReducer?.setCount(payload))
  const setNextUrl = (payload) => dispatch(searchActionReducer?.setNextUrl(payload))
  const setPage = (payload) => dispatch(searchActionReducer?.setPage(payload))
  const setPreviousUrl = (payload) => dispatch(searchActionReducer?.setPreviousUrl(payload))
  const setSavedSearch = (payload) => dispatch(searchActionReducer?.setSavedSearch(payload))
  const setUrl = (payload) => dispatch(searchActionReducer?.setUrl(payload))

  const userIsLoggedIn = !!user?.user

  const [{ data: listingsPreviewsResponse, loading: loadingListingsPreviewsResponse }, getListingPreviews] = useAxios(
    PUBLIC_LISTING_PREVIEWS,
    { manual: true },
  )
  const [{ data: listingsGroupsResponse, loading: loadingListingsGroupsResponse }, getListingGroups] = useAxios(
    PUBLIC_LISTING_GROUPS,
    { manual: true },
  )
  const [{ data: featuredListingsResponse, loading: loadingFeaturedListingsResponse }, getFeaturedListings] = useAxios(
    PUBLIC_FEATURED_LISTING_PREVIEWS,
    { manual: true },
  )
  const [{ data: favoritesResponse, loading: loadingFavoritesResponse }, getFavorites] = useAxios(
    CONSUMER_SAVED_LISTINGS,
    { manual: true },
  )
  const [{ data: savedSearchesResponse, loading: savedSearchesResponseIsLoading }, getSearches] = useAxios(
    CONSUMER_SAVED_SEARCHES,
    { manual: true },
  )

  const searches = savedSearchesResponse?.results

  const scrollToTop = () => window.scrollTo({ top: 0 })

  const refetchListings = useCallback(
    (extraParams, caller) => {
      const { featuredListingsPreviewsUrl, isNew, listingsGroupsUrl, listingsPreviewsUrl, page } = generateSearchURLs(
        activeParams,
        extraParams,
      )

      const dataLoaded = listingsPreviewsResponse || loadingListingsPreviewsResponse
      const exitWithoutAPICall = !isNew && dataLoaded

      if (exitWithoutAPICall) return

      console.log(`[${page}]`, caller)

      ReactGA.event({
        category: 'Search',
        action: 'search_conducted',
        label: listingsPreviewsUrl,
      })
      ReactPixel.track('Search', { search: listingsPreviewsUrl })
      clickEvents.SEARCH_CONDUCTED()

      setPage(page)
      setUrl(listingsPreviewsUrl)
      resetSelectedBuilding()
      scrollToTop()

      const handleErrors = (error) => {
        if (error?.code === 'ERR_CANCELED') return
        console.log(error)
      }

      getListingGroups(listingsGroupsUrl).catch(handleErrors)
      getListingPreviews(listingsPreviewsUrl)
        .catch(handleErrors)
        .then((response) => {
          setCount(response?.data?.count)
          setNextUrl(response?.data?.next)
          setPreviousUrl(response?.data?.previous)
        })
      getFeaturedListings(featuredListingsPreviewsUrl).catch(handleErrors)

      if (userIsLoggedIn) {
        getFavorites().catch(handleErrors)
        const savedSearch = findSavedSearch({ ...activeParams, ...extraParams }, searches)
        setSavedSearch(savedSearch)
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeParams],
  )

  // pull listings on first render
  useEffect(() => {
    if (
      (listingsPreviewsResponse || loadingListingsPreviewsResponse) &&
      (featuredListingsResponse || loadingFeaturedListingsResponse) &&
      (listingsGroupsResponse || loadingListingsGroupsResponse)
    )
      return
    refetchListings({}, 'Search.useEffect [listingsPreviewsResponse, featuredListingsResponse, listingsGroupsResponse]')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingsPreviewsResponse, featuredListingsResponse, listingsGroupsResponse])

  return (
    <>
      <BodyContainer>
        <Header currentUser={currentUser} setCurrentUser={setCurrentUser} refetchListings={refetchListings} />
        <Navigation
          currentUser={currentUser}
          refetchListings={refetchListings}
          listingsPreviewsResponse={listingsPreviewsResponse}
          listingsGroupsResponse={listingsGroupsResponse}
          featuredListingsResponse={featuredListingsResponse}
          favoritesResponse={favoritesResponse}
          savedSearchesResponse={savedSearchesResponse}
          loadingFeaturedListingsResponse={loadingFeaturedListingsResponse}
          loadingListingsGroupsResponse={loadingListingsGroupsResponse}
          loadingListingsPreviewsResponse={loadingListingsPreviewsResponse}
          loadingFavoritesResponse={loadingFavoritesResponse}
          savedSearchesResponseIsLoading={savedSearchesResponseIsLoading}
          getFavorites={getFavorites}
          getSearches={getSearches}
        />
        <Footer />
      </BodyContainer>
      <MobilePanels refetchListings={refetchListings} />
      <Modals />
    </>
  )
}

export default SearchController
