import styled from 'styled-components'
import THEME from '../../../../../constants/themes'

export const IconContainer = styled.div.attrs({
  className: 'IconContainer',
})(
  ({ isHidden }) => `
    display: ${isHidden ? 'none' : 'flex'};
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  `,
)

export const IconsContainer = styled.div.attrs({
  className: 'IconsContainer',
})`
  height: 100%;
  display: flex;
  align-items: center;
`

export const PriceFilterDropdownContainer = styled.div.attrs({
  className: 'PriceFilterDropdownContainer',
})`
  width: 100%;
  margin-bottom: 5px;
  position: relative;
`

export const PriceOptionContainer = styled.div.attrs({
  className: 'PriceOptionContainer',
})`
  padding: 2px 0;
  &:hover {
    background: ${THEME.backgroundGray};
  }
`

export const PriceOptionsContainer = styled.div.attrs({
  className: 'PriceOptionContainer',
})(
  ({ isOpen }) => `
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    position: absolute;
    background: white;
    width: 100%;
    z-index: 195;
    gap: 0.5em;
    padding: 0 10px;
    border-radius: 0 0 8px 8px;
    overflow-y: auto;
    max-height: 200px;
    font-size: 13px;
    @media (max-width: 992px) {
      max-height: 100px;
    }
  `,
)

export const PriceSubtitleContainer = styled.div.attrs({
  className: 'PriceSubtitleContainer',
})`
  margin-bottom: 5px;
  color: #5f5f5f;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
`

export const PriceValueContainer = styled.div.attrs({
  className: 'PriceValueContainer',
})`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: space-between;
  background: white;
  border-radius: 4px;
  height: 40px;
  cursor: text;
  padding: 0 5px 0 10px;
  max-width: 100%;
  > svg {
    top: 0;
    right: 0;
  }
`

export const PriceValueInputContainer = styled.input.attrs({
  className: 'PriceValueInputContainer',
})`
  border: none;
  outline: none;
  box-shadow: none;
  margin-bottom: 5px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: black;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  &::placeholder {
    color: #5f5f5f;
  }
`
