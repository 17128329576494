import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  authModalIsOpen: false,
  savedSearchModalIsOpen: false,
  featuredListingsModalIsOpen: false,
  paymentModalIsOpen: false,
  towersModalIsOpen: false,
}

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setAuthModalIsOpen: (state, action) => {
      state.authModalIsOpen = action.payload
    },
    setSavedSearchModalIsOpen: (state, action) => {
      state.savedSearchModalIsOpen = action.payload
    },
    setFeaturedListingModalIsOpen: (state, action) => {
      state.featuredListingsModalIsOpen = action.payload
    },
    setPaymentModalIsOpen: (state, action) => {
      state.paymentModalIsOpen = action.payload
    },
    setTowersModalIsOpen: (state, action) => {
      state.towersModalIsOpen = action.payload
    },
  },
})

export const modalsActionReducer = modalsSlice.actions
export default modalsSlice.reducer
