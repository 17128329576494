import { useSelector } from 'react-redux'

import Header from './Header'
import Cards from './Cards'
import Footer from './Footer'
import SwitchViewButton from './SwitchViewButton'
import Ads from '../../Ads'
import { AdsContainer, ListContainer, SearchListContainer } from './List.style'

const List = ({
  buildings,
  map,
  favorites,
  getFavorites,
  loadingFavoritesResponse,
  isLoading,
  setPage,
  page,
  count,
  sortMethod,
  setSortMethod,
}) => {
  const { searchPage: currentMobileView } = useSelector((state) => state.page)
  const listViewIsActive = currentMobileView === 'list'

  return (
    <ListContainer isActive={listViewIsActive}>
      <SearchListContainer>
        <Header
          isLoading={isLoading}
          buildings={buildings}
          page={page}
          count={count}
          sortMethod={sortMethod}
          setSortMethod={setSortMethod}
        />
        <Cards
          buildings={buildings}
          map={map}
          favorites={favorites}
          getFavorites={getFavorites}
          loadingFavoritesResponse={loadingFavoritesResponse}
          isLoading={isLoading}
        />
        <Footer isLoading={isLoading} page={page} count={count} setPage={setPage} />
        <SwitchViewButton />
      </SearchListContainer>
      <AdsContainer>
        <Ads variant="1" />
        <Ads variant="2" />
      </AdsContainer>
    </ListContainer>
  )
}

export default List
