import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const neighborhoodSlice = createSlice({
  name: 'neighborhood',
  initialState,
  reducers: {
    setNeighborhoods: (state, action) => {
      return action.payload
    },
  },
})

export const neighborhoodActionReducer = neighborhoodSlice.actions
export default neighborhoodSlice.reducer
