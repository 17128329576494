import styled from 'styled-components'

export const CardsContainer = styled.div.attrs({
  className: 'CardsContainer',
})`
  display: grid;
  // grid-template-columns: 1fr 1fr;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  width: 100%;
  gap: 1.33em;
  justify-items: center;
`
