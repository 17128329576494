import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  savedBuildingsOrderBy: 'building__name',
  savedRentalsOnMarketOnly: false,
  savedRentalsOrderBy: '-listing__on_market_date',
  savedSalesOnMarketOnly: false,
  savedSalesOrderBy: '-listing__on_market_date',
}

export const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    setSavedBuildingsOrderBy: (state, action) => {
      state.savedBuildingsOrderBy = action.payload
    },
    setSavedRentalsOnMarketOnly: (state, action) => {
      state.savedRentalsOnMarketOnly = action.payload
    },
    setSavedRentalsOrderBy: (state, action) => {
      state.savedRentalsOrderBy = action.payload
    },
    setSavedSalesOnMarketOnly: (state, action) => {
      state.savedSalesOnMarketOnly = action.payload
    },
    setSavedSalesOrderBy: (state, action) => {
      state.savedSalesOrderBy = action.payload
    },
  },
})

export const favoritesActionReducer = favoritesSlice.actions
export default favoritesSlice.reducer
