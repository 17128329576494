import { OrderingDropdownContainer, OrderingDropdownTitle } from './Ordering.style'
import OrderingDropdown from './OrderingDropdown'

const Ordering = ({ listings, refetchListings }) =>
  listings?.length > 0 && (
    <OrderingDropdownContainer>
      <OrderingDropdownTitle>Sort by</OrderingDropdownTitle>
      <OrderingDropdown refetchListings={refetchListings} />
    </OrderingDropdownContainer>
  )

export default Ordering
