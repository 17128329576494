import styled from 'styled-components'

export const AmenitiesContainer = styled.div.attrs({
  className: 'AmenitiesContainer',
})`
  @media (max-width: 992px) {
    padding: 0 20px;
  }
`

export const AmenitiesContentContainer = styled.div.attrs({
  className: 'AmenitiesContentContainer',
})`
  display: grid;
  gap: 3px 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-bottom: 1.5em;
  border-bottom: 0.5px solid rgb(196, 196, 196);
  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 1em;
  }
  @media (max-width: 786px) {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 1em;
  }
`

export const AmenityText = styled.div.attrs({
  className: 'AmenityText',
})``
