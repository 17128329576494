import { Content, DescriptionContainer } from './Description.style'
import { SectionTitle } from '../../ListingDetail.style'

const Description = ({ listing }) => (
  <DescriptionContainer>
    <SectionTitle>Description</SectionTitle>
    <Content>{listing?.description}</Content>
  </DescriptionContainer>
)

export default Description
