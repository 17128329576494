import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
}

export const listingSlice = createSlice({
  name: 'listing',
  initialState,
  reducers: {
    setListing: (state) => {
      state.value += 1
    },
  },
})

export default listingSlice.reducer
