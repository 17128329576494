import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  // hover states:
  hoveredNeighborhood: null,
}

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    // hovered states:
    setHoveredNeighborhood: (state, action) => {
      state.hoveredNeighborhood = action.payload
    },
  },
})

export const mapActionReducer = mapSlice.actions
export default mapSlice.reducer
