import styled from 'styled-components'

import THEME from '../../../../constants/themes'

export const Address = styled.div.attrs({
  className: 'Address',
})`
  font-size: small;
  display: flex;
  flex-direction: column;
`

export const DropdownContainer = styled.div.attrs({
  className: 'DropdownContainer',
})(
  ({ isOpen }) => `
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    gap: 0.5em;
    position: absolute;
    top: 65px;
    width: 100%;
    right: 0;
    background: ${THEME.backgroundGray};
    border-top: none;
    z-index: 400;
    opacity: 1 !important;
    min-height: 290px;
    border-radius: 0 0 15px 15px;
    padding-bottom: 5px;
  `,
)

export const InputContainer = styled.div.attrs({
  className: 'InputContainer',
})`
  position: relative;
  width: 100%
  transition: 0.2s all ease-in-out;
  > img {
    position: absolute;
    left: 0px;
    top: 12px;
    cursor: text;
  }
`

export const OptionContainer = styled.div.attrs({
  className: 'OptionContainer',
})`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  color: #222;
  padding: 4px 20px;
  &:hover {
    background: white;
  }
`

export const BuildingName = styled.div.attrs({
  className: 'BuildingName',
})`
  // font-weight: 600;
  // float: left;
  font-size: small;
`

export const NeighborhoodName = styled.div.attrs({
  className: 'NeighborhoodName',
})`
  font-style: italic;
  font-weight: 300;
  float: right;
  font-size: smaller;
  margin-left: auto;
`

export const SectionContainer = styled.div.attrs({
  className: 'SectionContainer',
})`
  display: flex;
  flex-direction: column;
`

export const SectionHeader = styled.div.attrs({
  className: 'SectionHeader',
})`
  color: #333;
  // color: #ddd;
  display: flex;
  justify-content: space-between;
  padding: 4px 12px;
  font-weight: 600;
`

export const SectionTitle = styled.div.attrs({
  className: 'SectionTitle',
})`
  font-size: 16px;
`

export const SearchBarContainer = styled.div.attrs({
  className: 'SearchBarContainer',
})(
  ({ isOpen }) => `
    width: 100%;
    height: 100%;
    padding: 15px;
    position: relative;
    background: ${isOpen ? THEME.backgroundGray : 'inherit'};
    border-radius: ${isOpen ? '15px 15px 0 0' : 'none'};
  `,
)

export const StyledInput = styled.input.attrs({
  className: 'StyledInput',
})`
  background: transparent;
  border: none;
  border-bottom: 0.5px solid #aaa;
  padding: 10px 6px 10px 24px;
  width: 100%;
  height: 40px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 15px;
  transition: 0.2s all ease-in-out;
  &::-webkit-input-placeholder {
    color: #888;
    font-size: 14px;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
`
