import { memo, useRef, useState } from 'react'
import Switch from '@mui/material/Switch'
import useAxios from 'axios-hooks'

import Item from './Item'
import OrderingDropdown from './OrderingDropdown'
import useOutsideClick from '../../../hooks/outsideClick'
import Loader from './Loader'
import { CONSUMER_SAVED_LISTING_PREVIEWS } from '../../../constants/api'
import {
  CardsContainer,
  HeaderContainer,
  HeaderTitle,
  OnMarketContainer,
  OnMarketTrigger,
  OrderByContainer,
  OrderByTrigger,
  ResultsTitle,
  SavedRentalsContainer,
} from './SavedRentals.style'

const SavedRentals = () => {
  const orderByRef = useRef()

  const [savedRentalsOnMarketOnly, setSavedRentalsOnMarketOnly] = useState(false)
  const [savedRentalsOrderBy, setSavedRentalsOrderBy] = useState('newest')

  const [{ data: savedRentalsResponse, loading: savedRentalsResponseIsLoading }] = useAxios(
    {
      url: CONSUMER_SAVED_LISTING_PREVIEWS,
      params: { listing__type: 'FOR_RENT' },
    },
    { useCache: false },
  )

  const sortingMethods = {
    newest: (a, b) => b.onMarketDate?.localeCompare(a.onMarketDate),
    recentlyUpdated: (a, b) => b.lastModificationDatetime?.localeCompare(a.lastModificationDatetime),
    mostExpensive: (a, b) => b.price - a.price,
    leastExpensive: (a, b) => a.price - b.price,
    largest: (a, b) => b.unit?.squareFootage - a.unit?.squareFootage,
    smallest: (a, b) => a.unit?.squareFootage - b.unit?.squareFootage,
  }

  const toggleSavedRentalsOnMarketOnly = () => setSavedRentalsOnMarketOnly((onMarketOnly) => !onMarketOnly)

  const [orderingDropdownIsOpen, setOrderingDropdownIsOpen] = useState(false)

  const numberOfSavedRentals = savedRentalsResponse?.count
  const savedRentals = savedRentalsResponse?.results
    ?.filter((savedRental) => (savedRentalsOnMarketOnly ? savedRental.status === 'OPEN' : savedRental))
    ?.sort(sortingMethods[savedRentalsOrderBy])

  const resultsTitle = numberOfSavedRentals === 1 ? `1 Saved Rental` : `${numberOfSavedRentals} Saved Rentals`

  const handleOnMarketTriggerOnClick = () => toggleSavedRentalsOnMarketOnly()
  const handleOrderByTriggerOnClick = () => setOrderingDropdownIsOpen((isOpen) => !isOpen)

  const savedRentalsPreviewCards = savedRentals?.map((savedRental) => (
    <Item listing={savedRental} key={savedRental?.uuid} />
  ))

  useOutsideClick(orderByRef, () => setOrderingDropdownIsOpen(false), [])

  return (
    <SavedRentalsContainer>
      <HeaderContainer>
        <OnMarketContainer isActive={savedRentalsOnMarketOnly}>
          <OnMarketTrigger onClick={handleOnMarketTriggerOnClick}>
            <HeaderTitle>On Market Only</HeaderTitle>
            <Switch checked={savedRentalsOnMarketOnly} />
          </OnMarketTrigger>
        </OnMarketContainer>
        <OrderByContainer ref={orderByRef} isActive={orderingDropdownIsOpen}>
          <OrderByTrigger onClick={handleOrderByTriggerOnClick}>
            <OrderingDropdown
              dropdownIsOpen={orderingDropdownIsOpen}
              setDropdownIsOpen={setOrderingDropdownIsOpen}
              savedRentalsOrderBy={savedRentalsOrderBy}
              setSavedRentalsOrderBy={setSavedRentalsOrderBy}
            />
          </OrderByTrigger>
        </OrderByContainer>
      </HeaderContainer>
      {savedRentalsResponseIsLoading ? (
        <Loader />
      ) : (
        <>
          <ResultsTitle>{resultsTitle}</ResultsTitle>
          <CardsContainer>{savedRentalsPreviewCards}</CardsContainer>
        </>
      )}
    </SavedRentalsContainer>
  )
}

export default memo(SavedRentals)
