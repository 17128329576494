import { Link, useParams } from 'react-router-dom'

import Settings from './Settings'
import SavedSales from './SavedSales'
import SavedBuildings from './SavedBuildings'
import SavedSearches from './SavedSearches'
import SavedRentals from './SavedRentals'
import { AccountContainer, HeaderContainer, TabControllerContainer, TabContainer } from './Account.style'

const Account = () => {
  const { tab } = useParams()

  const indecies = {
    undefined: 0,
    sales: 1,
    rentals: 2,
    buildings: 3,
    searches: 4,
  }

  const activeTab = indecies[tab]

  return (
    <AccountContainer>
      <HeaderContainer>
        <TabControllerContainer>
          <TabContainer isActive={activeTab === 0}>
            <Link to="/account">Settings</Link>
          </TabContainer>
          <TabContainer isActive={activeTab === 1}>
            <Link to="/account/sales">Sales</Link>
          </TabContainer>
          <TabContainer isActive={activeTab === 2}>
            <Link to="/account/rentals">Rentals</Link>
          </TabContainer>
          <TabContainer isActive={activeTab === 3}>
            <Link to="/account/buildings">Buildings</Link>
          </TabContainer>
          <TabContainer isActive={activeTab === 4}>
            <Link to="/account/searches">Searches</Link>
          </TabContainer>
        </TabControllerContainer>
      </HeaderContainer>
      {activeTab === 0 ? (
        <Settings />
      ) : activeTab === 1 ? (
        <SavedSales />
      ) : activeTab === 2 ? (
        <SavedRentals />
      ) : activeTab === 3 ? (
        <SavedBuildings />
      ) : activeTab === 4 ? (
        <SavedSearches />
      ) : (
        <></>
      )}
    </AccountContainer>
  )
}

export default Account
