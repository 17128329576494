import Ordering from './Ordering'
import Loader from './Loader'
import { HeaderContainer, HeaderTitle } from './Header.style'

const Header = ({ buildings, isLoading, page, count, sortMethod, setSortMethod }) => {
  const buildingsRangeLowerEnd = !count ? 0 : page * 10 - 9
  const buildingsRangeUpperEnd = !count ? 0 : count < page * 10 ? count : page * 10

  if (isLoading) return <Loader />

  return (
    <HeaderContainer>
      <HeaderTitle>
        Showing {buildingsRangeLowerEnd}-{buildingsRangeUpperEnd} of {count} new developments
      </HeaderTitle>
      <Ordering buildings={buildings} sortMethod={sortMethod} setSortMethod={setSortMethod} />
    </HeaderContainer>
  )
}

export default Header
