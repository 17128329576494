import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const AddressAndDetailsContianer = styled.div.attrs({
  className: 'AddressAndDetailsContianer',
})`
  @media (max-width: 992px) {
    padding: 0 20px;
  }
`

export const BuildingName = styled.h1.attrs({
  className: 'BuildingName',
})`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 5px;
  @media (max-width: 992px) {
    font-size: 22px;
    margin-bottom: 2px;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 127.273% */
  }
`

export const DetailsContainer = styled.section.attrs({
  className: 'DetailsContainer',
})`
  text-align: right;
  @media (max-width: 992px) {
    margin-top: 1em;
    padding: 1em 0;
    border-top: 0.1px solid #c4c4c4;
    border-bottom: 0.1px solid #c4c4c4;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
`

export const DetailsIconContainer = styled.img.attrs({
  className: 'DetailsIconContainer',
})`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`

export const IconsContainer = styled.div.attrs({
  className: 'IconsContainer',
})`
  display: flex;
  margin-top: 8px;
  gap: 19px;
  align-items: center;
  justify-content: center;
  @media (max-width: 992px) {
    justify-content: flex-start;
    margin-top: 0;
  }
`

export const Item = styled.div.attrs({
  className: 'Item',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: 992px) {
    font-size: 14px;
  }
`

export const ListingAddress = styled.h3.attrs({
  className: 'ListingAddress',
})`
  font-size: 15px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 992px) {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
  }
`

export const ListingPriceAndType = styled.div.attrs({
  className: 'ListingPriceAndType',
})`
  font-size: 28px;
  font-weight: 600;
  @media (max-width: 992px) {
    font-size: 22px;
  }
`

export const ListingType = styled.span.attrs({
  className: 'ListingType',
})`
  margin-left: 5px;
  font-size: 15px;
  font-weight: 400;
  @media (max-width: 992px) {
    font-size: 14px;
  }
`

export const PriceAndDetailsContainer = styled.div.attrs({
  className: 'PriceAndDetailsContainer',
})`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 0.5px solid #c4c4c4;
  padding-bottom: 1.5em;
  @media (max-width: 992px) {
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 0;
    padding-bottom: 0;
  }
`

export const StyledLink = styled(Link).attrs({
  className: 'StyledLink',
})``

export const UnitText = styled.div.attrs({
  className: 'UnitText',
})`
  @media (max-width: 992px) {
    font-size: 15px;
    text-align: left;
    span {
      small {
        font-size: 15px;
      }
    }
  }
`
