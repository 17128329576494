import { useRef } from 'react'

import { DownArrow } from '../../../Search/Filters/Containers'
import {
  IconContainer,
  IconsContainer,
  OrderingDropdownContainer,
  OrderingOptionContainer,
  OrderingOptionsContainer,
  OrderingValueContainer,
} from './OrderingDropdown.style'

const OrderingDropdown = ({ dropdownIsOpen, setDropdownIsOpen, savedBuildingsOrderBy, setSavedBuildingsOrderBy }) => {
  const dropdownRef = useRef()

  const options = [
    { label: 'Name', value: 'name' },
    { label: 'Year Built', value: 'yearBuilt' },
  ]

  const orderingOption = options?.find((option) => option?.value === savedBuildingsOrderBy)

  const handleOptionOnClick = (option) => {
    setSavedBuildingsOrderBy(option?.value)
    setDropdownIsOpen(false)
  }

  const orderingOptions = options
    ?.filter((option) => option?.value !== savedBuildingsOrderBy)
    ?.map((option) => (
      <OrderingOptionContainer key={option?.value} onClick={() => handleOptionOnClick(option)}>
        Sort By {option?.label}
      </OrderingOptionContainer>
    ))

  return (
    <OrderingDropdownContainer ref={dropdownRef}>
      <OrderingValueContainer>
        Sort By {orderingOption?.label}
        <IconsContainer>
          <IconContainer>
            <DownArrow />
          </IconContainer>
        </IconsContainer>
      </OrderingValueContainer>
      <OrderingOptionsContainer isOpen={dropdownIsOpen}>{orderingOptions}</OrderingOptionsContainer>
    </OrderingDropdownContainer>
  )
}

export default OrderingDropdown
