import styled from 'styled-components'

import THEME from '../../../../../../constants/themes'

export const DetailsTableContainer = styled.div.attrs({
  className: 'DetailsTableContainer',
})`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  @media (max-width: 992px) {
    border-radius: 0;
    width: calc(100% + 40px);
    margin-left: -20px;
    border-bottom: 1px solid rgb(230, 230, 230);
  }
`

export const SortImage = styled.img.attrs({
  className: 'SortImage',
})(
  ({ isSorted }) => `
    display: ${isSorted ? 'block' : 'none'};
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0.5em;
    top: 11px;
    height: 20px;
    width: 20px;
    @media (max-width: 992px) {
      height: 10px;
      width: 10px;
      top: 4px;
      right: 4px;
    }
  `,
)

export const TableBody = styled.tbody.attrs({
  className: 'TableBody',
})`
  display: block;
  overflow: hidden;
  overflow-y: auto;
  // max-height: calc(100vh - 300px);
  max-height: 400px;
`

export const TableBodyCell = styled.td.attrs({
  className: 'TableBodyCell',
})`
  margin: auto;
  height: 40px;
  > a,
  > div {
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 1em 0;
  }
  @media (max-width: 500px) {
    font-size: 10px;
  }

  flex: 1;
`

export const TableBodyRow = styled.tr.attrs({
  className: 'TableBodyRow',
})(
  ({ index, isHoverable }) => `
    background: ${index % 2 === 0 ? THEME.backgroundGray : '#f7f6f7'};
    display: flex;
    ${
      isHoverable &&
      `&:hover {
      background: #eceff1;
    }`
    }
  `,
)

export const TableContainer = styled.table.attrs({
  className: 'TableContainer',
  cellSpacing: 0,
  cellPadding: 0,
})`
  width: 100%;
`

export const TableHeader = styled.thead.attrs({
  className: 'TableHeader',
})`
  background: ${THEME.mediumBlue};
  color: white;
  position: sticky;
  top: 0;
  z-index: 1;

  display: block;
`

export const TableHeaderCell = styled.th.attrs({
  className: 'TableHeaderCell',
})(
  ({ numberOfColumns, numberOfRows }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    height: 40px;
    cursor: pointer;
    user-select: none;
    flex: 1;
    border-right: 1px solid ${THEME.lightBlue};
    &:hover {
      opacity: 0.9;
    }
    @media (max-width: 500px) {
      font-size: 10px;
    }
    &:last-child {
      flex: 0 0 calc(100% / ${numberOfColumns} + 10px);
      ${numberOfRows <= 10 ? 'flex: 1;' : ''}
      border-right: none;
    }
  `,
)

export const TableHeaderRow = styled.tr.attrs({
  className: 'TableHeaderRow',
})`
  display: flex;
  height: 100%;
`
