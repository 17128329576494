import { useDispatch, useSelector } from 'react-redux'

import NeighborhoodsFilter from './NeighborhoodsFilter'
import AmenitiesFilter from './AmenitiesFilter'
import { searchActionReducer } from '../../../store/search/searchSlice'
import { ReactComponent as CLOSE_ICON } from '../../../assets/images/close.svg'
import {
  DoneButton,
  HeaderTitle,
  FilterPanelBodyContainer,
  FilterPanelContainer,
  FilterPanelFooterContainer,
  FilterPanelHeaderContainer,
  ResetButton,
} from './FiltersPanel.style'
import { panelsActionReducer } from '../../../store/panels/panelsSlice'

export const NewDevelopmentsFilteringPanel = () => {
  const dispatch = useDispatch()

  const { newDevelopmentsFilterPanelIsOpen: filterPanelIsOpen } = useSelector((state) => state?.panels)

  const closeFilterPanel = () => dispatch(panelsActionReducer?.setNewDevelopmentsFilterPanelIsOpen(false))

  const resetFilters = () => {
    dispatch(searchActionReducer.setAmenities([]))
    dispatch(searchActionReducer.setNeighborhoods([]))
  }

  const handleResetFiltersOnClick = () => resetFilters()

  return (
    <FilterPanelContainer className="hidden-desktop" isOpen={filterPanelIsOpen}>
      <FilterPanelHeaderContainer>
        <HeaderTitle>Filters</HeaderTitle>
        <CLOSE_ICON onClick={closeFilterPanel} />
      </FilterPanelHeaderContainer>
      <FilterPanelBodyContainer>
        <NeighborhoodsFilter />
        <AmenitiesFilter />
      </FilterPanelBodyContainer>
      <FilterPanelFooterContainer>
        <ResetButton onClick={handleResetFiltersOnClick}>Reset All Filters</ResetButton>
        <DoneButton onClick={closeFilterPanel}>Apply Filters</DoneButton>
      </FilterPanelFooterContainer>
    </FilterPanelContainer>
  )
}

export default NewDevelopmentsFilteringPanel
