import styled from 'styled-components'

import THEME from '../../../../constants/themes'

export const ButtonsContainer = styled.div.attrs({
  className: 'ButtonsContainer',
})`
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  @media (max-width: 992px) {
    flex-direction: row;
  }
`

export const DoneButton = styled.div.attrs({
  className: 'DoneButton',
})`
  display: flex;
  min-width: auto;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  height: 100%;
  cursor: pointer;
  border-radius: 4px;
  min-height: 41px;
  width: 100%;
  background: ${THEME.mediumBlue};
  color: #fff;
  &:hover {
    background: ${THEME.darkBlue};
  }
`
export const IconContainer = styled.div.attrs({
  className: 'IconContainer',
})(
  ({ isHidden }) => `
    display: ${isHidden ? 'none' : 'flex'};
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  `,
)

export const IconsContainer = styled.div.attrs({
  className: 'IconsContainer',
})`
  height: 100%;
  display: flex;
  align-items: center;
`

export const PriceDropdownContainer = styled.div.attrs({
  className: 'PriceDropdownContainer',
})(
  ({ isOpen }) => `
    visibility: ${isOpen ? 'visible' : 'hidden'};
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    z-index: 185 !important;
    border-radius: 0 0 10px 10px !important;
    margin-top: -10px !important;
    padding: 0.5em;
    left: -1px;
    width: 180px;
    background: ${THEME.backgroundGray} !important;
    border: 1px solid rgb(230, 230, 230) !important;
    border-top: none !important;
    position: absolute;
    top: 75px;
    @media (max-width: 992px) {
      width: 300px !important;
      max-width: 300px;
      right: -1px;
      left: unset;
      height: 126px;
    }
  `,
)

export const PriceFilterContainer = styled.div.attrs({
  className: 'PriceFilterContainer',
})(
  ({ isOpen }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-color: ${isOpen ? '#fafafa' : 'white'};
    border-radius: 0 60px 60px 0;
    position: relative;
    &:hover {
      background-color: #fafafa;
    }
    @media (max-width: 992px) {
      border-radius: 0;
      border-right: ${isOpen ? '1px solid rgb(230, 230, 230)' : '1px solid white'};
    }
  `,
)

export const PriceFilterTriggerContainer = styled.div.attrs({
  className: 'PriceFilterTriggerContainer',
})`
  width: 100%;
  // min-width: 230px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 80px 0 1em;
  @media (max-width: 992px) {
    padding: 0.5em;
    padding-left: 1em;
    margin-bottom: 0.5em;
  }
`

export const PriceOptionDropdownContainer = styled.div.attrs({
  className: 'PriceOptionDropdownContainer',
})`
  width: 100%;
  padding-right: 0;
  margin-bottom: 5px;
  @media (max-width: 992px) {
    width: 100%;
    &:last-child {
      padding-right: 0px;
    }
  }
`

export const PriceOptionsContainer = styled.div.attrs({
  className: 'PriceOptionContainer',
})`
  display: flex;
  flex-direction: column;
  @media (max-width: 992px) {
    flex-direction: row;
    gap: 5px;
    width: 100%;
  }
`

export const ResetButton = styled.div.attrs({
  className: 'ResetButton',
})`
  display: flex;
  min-width: auto;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  height: 100%;
  cursor: pointer;
  border-radius: 4px;
  min-height: 41px;
  color: ${THEME.mediumBlue};
  width: 100%;
  &:hover {
    background-color: #eee;
  }
`
