import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { modalsActionReducer } from '../../../store/modals/modalsSlice'
import CLOSE_ICON from '../../../assets/images/close.svg'
import Login from './Login'
import Register from './Register'
import {
  AuthContainer,
  ContentContainer,
  CloseButton,
  HeaderContainer,
  TabBackground,
  TabContainer,
  TabContentContainer,
  TabControllerContainer,
} from './Auth.style'

export const AuthModal = () => {
  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState('sign in')

  const closeAuthModal = () => dispatch(modalsActionReducer.setAuthModalIsOpen(false))

  const signInIsActive = activeTab === 'sign in'

  return (
    <AuthContainer>
      <HeaderContainer>
        <CloseButton onClick={closeAuthModal}>
          <img src={CLOSE_ICON} alt="close" />
        </CloseButton>
      </HeaderContainer>
      <ContentContainer>
        <TabControllerContainer>
          <TabBackground signInIsActive={signInIsActive} />
          <TabContainer isActive={signInIsActive} onClick={() => setActiveTab('sign in')}>
            Sign In
          </TabContainer>
          <TabContainer isActive={!signInIsActive} onClick={() => setActiveTab('register')}>
            Register
          </TabContainer>
        </TabControllerContainer>
        <TabContentContainer>{signInIsActive ? <Login /> : <Register />}</TabContentContainer>
      </ContentContainer>
    </AuthContainer>
  )
}

export default AuthModal
