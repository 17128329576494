import styled from 'styled-components'
import THEME from '../../../../constants/themes'

export const TypeFilterContainer = styled.div.attrs({
  className: 'TypeFilterContainer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  left: 1em;
  z-index: 150;
  height: 100%;
`

export const TypeFilterOption = styled.div.attrs({
  className: 'TypeFilterOption',
})`
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  z-index: 195;
  padding: 0 10px;
`

export const TypeFilterSwitch = styled.div.attrs({
  className: 'TypeFilterSwitch',
})(
  ({ position }) => `
    position: absolute;
    width: ${position === 'left' ? '45px' : '49px'};
    transition: 0.5s all;
    background: lightblue;
    // border-radius: 4px;
    // left: ${position === 'left' ? '1px' : '43px'};
    // top: 1px;
    // height: 40px;
    // border: 1px solid ${THEME.backgroundGray};
    background: ${THEME.backgroundBlue};
    border-radius: ${position === 'left' ? '4px 0 0 4px' : '0 4px 4px 0'};
    left: ${position === 'left' ? '0px' : '47px'};
    height: 42px;
  `,
)

export const TypeFilterTriggerContainer = styled.div.attrs({
  className: 'TypeFilterTriggerContainer',
})`
  height: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px;
  background: white;
  position: relative;
  cursor: pointer;
`
