import { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import MagicSliderDots from 'react-magic-slider-dots'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-magic-slider-dots/dist/magic-dots.css'

import { ReactComponent as CLOSE_ICON } from '../../assets/images/close.svg'
import {
  CloseIconContainer,
  ImageReelContainer,
  IndexTag,
  NextButtonContainer,
  PreviewImage,
  PreviewIndexTag,
  PreviousButtonContainer,
  PrimaryImage,
  PrimaryImageContainer,
  SlideShowContainer,
} from './SlideShow.style'
import { PreviewImageContainer } from './SlideShow.style'

const NextArrow = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
    <path
      d="M4.81592 1L12.1843 8L4.81592 15"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const PrevArrow = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
    <path
      d="M10.1841 15L2.81566 8L10.1841 1"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const SlideShow = ({ setSlideShowIsOpen, images }) => {
  const sliderRef = useRef()

  const [currentSlide, setCurrentSlide] = useState(0)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const setWindowDimensions = () => setWindowWidth(window.innerWidth)

  const dotWidth = windowWidth > 992 ? 70 : 46

  const numberOfDots = Math.floor(windowWidth / dotWidth - 0.75)

  const renderDots = (dots) =>
    dots.map((dot, index) => {
      return (
        <PreviewImageContainer key={index} {...dot.props} {...dot.props.children.props}>
          <PreviewImage src={images[index]?.file} />
          {/* <PreviewIndexTag>{index + 1}</PreviewIndexTag> */}
        </PreviewImageContainer>
      )
    })

  const primaryImages = images?.map((image, index) => (
    <PrimaryImageContainer key={index}>
      <PrimaryImage src={image?.file} />
    </PrimaryImageContainer>
  ))

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 250,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (currentSlide, nextSlide) => setCurrentSlide(nextSlide),
    nextArrow: (
      <NextButtonContainer>
        <NextArrow />
      </NextButtonContainer>
    ),
    prevArrow: (
      <PreviousButtonContainer>
        <PrevArrow />
      </PreviousButtonContainer>
    ),
    appendDots: (dots) => <MagicSliderDots dots={renderDots(dots)} numDotsToShow={numberOfDots} dotWidth={dotWidth} />,
  }

  const handleCloseClick = () => setSlideShowIsOpen(false)

  const handleKeyPress = (event) => {
    if (event.key === 'ArrowLeft') sliderRef.current.slickPrev()
    else if (event.key === 'ArrowRight') sliderRef.current.slickNext()
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions)
    return () => window.removeEventListener('resize', setWindowDimensions)
  }, [])

  useEffect(() => {
    if (setSlideShowIsOpen) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'unset'
    return () => (document.body.style.overflow = 'unset')
  }, [setSlideShowIsOpen])

  return (
    <SlideShowContainer>
      <CloseIconContainer onClick={handleCloseClick}>
        <CLOSE_ICON />
      </CloseIconContainer>
      <IndexTag>
        {currentSlide + 1} of {images?.length}
      </IndexTag>
      <ImageReelContainer>
        <Slider ref={sliderRef} {...settings}>
          {primaryImages}
        </Slider>
      </ImageReelContainer>
    </SlideShowContainer>
  )
}

export default SlideShow
