import styled from 'styled-components'

import THEME from '../../../../constants/themes'

export const TypeFilterContainer = styled.div.attrs({
  className: 'TypeFilterContainer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  position: absolute;
  left: 1em;
  z-index: 150;
`

export const TypeFilterOption = styled.div.attrs({
  className: 'TypeFilterOption',
})`
  font-size: 14px;
  border-radius: 39px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  cursor: pointer;
  z-index: 195;
`

export const TypeFilterSwitch = styled.div.attrs({
  className: 'TypeFilterSwitch',
})(
  ({ position }) => `
    cursor: pointer;
    position: absolute;
    border-radius: 39px;
    width: 60px;
    top: 6px;
    left: ${position === 'left' ? '8px' : '70px'};
    height: calc(100% - 12px);
    transition: 0.5s left;
    // border: 2px solid ${THEME.lightBlue};
    // background: white; 
    background: ${THEME.backgroundBlue};
  `,
)

export const TypeFilterTriggerContainer = styled.div.attrs({
  className: 'TypeFilterTriggerContainer',
})`
  height: 50px;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  border-radius: 39px;
  // background: lightblue;
  background: ${THEME.backgroundGray};
  gap: 4px;
  position: relative;
  cursor: pointer;
`
