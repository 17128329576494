import moment from 'moment'

import DetailsTable from './DetailsTable'
import { SectionTitle } from '../../ListingDetail.style'
import { PriceHistoryContainer, PriceHistoryContentContainer, PriceHistoryTitleContainer } from './PriceHistory.style'

const UpArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="10"
    viewBox="0 0 8 10"
    fill="none"
    style={{
      marginRight: 5,
    }}
  >
    <path
      d="M3.33691 8.83203C3.33691 9.10817 3.56077 9.33203 3.83691 9.33203C4.11306 9.33203 4.33691 9.10817 4.33691 8.83203L3.33691 8.83203ZM4.19047 0.478477C3.99521 0.283216 3.67862 0.283216 3.48336 0.478477L0.30138 3.66046C0.106118 3.85572 0.106118 4.1723 0.30138 4.36757C0.496643 4.56283 0.813225 4.56283 1.00849 4.36757L3.83691 1.53914L6.66534 4.36757C6.8606 4.56283 7.17719 4.56283 7.37245 4.36757C7.56771 4.1723 7.56771 3.85572 7.37245 3.66046L4.19047 0.478477ZM4.33691 8.83203L4.33691 0.832031L3.33691 0.832031L3.33691 8.83203L4.33691 8.83203Z"
      fill="#09CF28"
    />
  </svg>
)

const DownArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="10"
    viewBox="0 0 8 10"
    fill="none"
    style={{
      marginRight: 5,
    }}
  >
    <path
      d="M4.33691 0.832031C4.33691 0.555889 4.11306 0.332031 3.83691 0.332031C3.56077 0.332031 3.33691 0.555889 3.33691 0.832031L4.33691 0.832031ZM3.48336 9.18559C3.67862 9.38085 3.99521 9.38085 4.19047 9.18559L7.37245 6.0036C7.56771 5.80834 7.56771 5.49176 7.37245 5.2965C7.17719 5.10124 6.8606 5.10124 6.66534 5.2965L3.83691 8.12492L1.00849 5.2965C0.813225 5.10124 0.496643 5.10124 0.30138 5.2965C0.106118 5.49176 0.106118 5.80834 0.30138 6.0036L3.48336 9.18559ZM3.33691 0.832031L3.33691 8.83203L4.33691 8.83203L4.33691 0.832031L3.33691 0.832031Z"
      fill="#FF0000"
    />
  </svg>
)

const PriceHistory = ({ listing }) => {
  const hasPriceHistory = !!listing?.priceHistory?.length

  const priceFormatter = () =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    })

  const ppsfFormatter = () =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: listing?.type === 'FOR_RENT' ? 2 : 0,
      // minimumSignificantDigits: 2,
      // maximumSignificantDigits: 2,
      // notation: windowWidth <= 768 ? 'compact' : 'standard',
    })

  const percentFormatter = () =>
    new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

  const toDate = (str) => {
    if (!str) return ''
    const dateFormat = window.innerWidth <= 992 ? 'M/D/YYYY' : 'MMM Do, YYYY'
    return moment(str).format(dateFormat)
  }

  const data = listing?.priceHistory
    ?.sort((a, b) => b.date?.localeCompare(a.date))
    ?.map((datum, index) => {
      const oldPrice = listing?.priceHistory[index + 1]?.price
      const currentPrice = listing?.priceHistory[index]?.price
      const change = (currentPrice - oldPrice) / oldPrice
      const changeCell = change ? (
        <>
          {change > 0 ? <UpArrow /> : <DownArrow />}
          {percentFormatter().format(change)}
        </>
      ) : (
        ' '
      )
      return {
        date: toDate(datum?.date),
        price: priceFormatter().format(datum.price),
        change: changeCell,
        ppsf: ppsfFormatter().format(datum.pricePerSquareFoot),
      }
    })

  const columns = [
    { value: 'date', label: 'Date' },
    { value: 'price', label: 'Price' },
    { value: 'change', label: 'Change' },
    { value: 'ppsf', label: 'Price/ft²' },
  ]

  if (!hasPriceHistory) return

  return (
    <PriceHistoryContainer>
      <PriceHistoryTitleContainer>
        <SectionTitle>Price History</SectionTitle>
      </PriceHistoryTitleContainer>
      <PriceHistoryContentContainer>
        <DetailsTable data={data} columns={columns} />
      </PriceHistoryContentContainer>
    </PriceHistoryContainer>
  )
}

export default PriceHistory
