import L from 'leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { useSelector } from 'react-redux'

import BuildingMarker from './BuildingMarker'

function transformNumber(oldValue) {
  const oldMin = 2
  const oldMax = 250
  const newMin = 40
  const newMax = 65
  if (oldValue >= oldMax) return newMax
  const oldRange = oldMax - oldMin
  const newRange = newMax - newMin
  const newValue = ((oldValue - oldMin) * newRange) / oldRange + newMin
  return newValue
}

const BuildingClusters = ({ buildings }) => {
  const { building: selectedBuildingUuid } = useSelector((state) => state.selections)

  const renderMarkerCluster = (cluster) => {
    const firstChild = cluster.getAllChildMarkers()[0]
    const isCluster = cluster.getChildCount() > 1
    const isSelected = firstChild?.options?.selected && !isCluster
    const isSingleListing = cluster.getChildCount() === 1 && firstChild?.options?.markerText === 1
    const iconClass = isCluster ? 'marker-icon-cluster' : 'marker-icon'
    const selectedClass = isSelected ? 'marker-icon-selected' : ''

    const listingsCount = cluster.getAllChildMarkers()?.reduce((acc, obj) => acc + obj?.options?.markerText, 0)

    const clusterRadius = transformNumber(listingsCount)
    const markerHeight = isSelected ? 40 : 30
    const markerWidth = isSelected ? 95 : 85
    const markerAnchorY = isSelected ? 43 : 38

    const iconAnchor = isCluster ? [clusterRadius / 2, clusterRadius / 2] : [markerWidth / 2, markerAnchorY]

    const markerText = isCluster
      ? `${listingsCount}+`
      : isSingleListing
      ? `${firstChild?.options?.markerText} unit`
      : `${firstChild?.options?.markerText} units`

    const markerHtml = `
      <div 
        class="${iconClass} ${selectedClass}" 
        key="${Math.random()}"
        ${
          isCluster
            ? `style="height: ${clusterRadius}px; width: ${clusterRadius}px;border-radius: 50%; border: 4px solid #eef3f2"`
            : `style="height: ${markerHeight}px; width: ${markerWidth}px;border-radius: 40px; border: 2.5px solid #eef3f2"`
        }
      >
        ${markerText}
        </div>
      `
    return L.divIcon({
      className: isSelected ? 'marker-selected' : '',
      html: markerHtml,
      iconAnchor: iconAnchor,
    })
  }

  const buildingMarkers = buildings?.map((building, index) => (
    <BuildingMarker key={index} building={building} isSelected={building?.uuid === selectedBuildingUuid} />
  ))

  return (
    <MarkerClusterGroup
      singleMarkerMode={true}
      iconCreateFunction={renderMarkerCluster}
      chunkedLoading
      disableClusteringAtZoom={17}
      showCoverageOnHover={false}
      removeOutsideVisibleBounds
      maxClusterRadius={105}
      polygonOptions={{
        color: '#f00800',
        weight: 5,
        opacity: 1,
      }}
    >
      {buildingMarkers}
    </MarkerClusterGroup>
  )
}

export default BuildingClusters
