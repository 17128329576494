import styled from 'styled-components'

export const SponsorTag = styled.div.attrs({
  className: 'SponsorTag',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  // background: #002c3f;
  background: #113f67;
  position: absolute;
  top: -10px;
  left: 20px;
  width: 110px;
  height: 25px;
  padding: 5px;
  padding-bottom: 0;
  color: #ccc;
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 9px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.18px;
  border-radius: 8px 8px 0 0;
  }
`

export const BillboardContainer = styled.div.attrs({
  className: 'BillboardContainer',
})`
  // background: #002c3f;
  background: #113f67;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  min-width: 350px;
  padding: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1240px;
  flex-wrap: wrap;
  margin: auto;
  margin-bottom: 30px;
  border-radius: 10px;
  @media (max-width: 992px) {
    width: 98%;
    max-width: 401px;
    margin: auto;
    // min-height: 350px;
    padding: 15px 10px 5px 15px;
    > a {
      width: 100%;
      height: 100%;
      > img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }
  @media (max-width: 425px) {
    width: 100vw;
    // max-width: 401px;
    // margin: auto;
    // min-height: 350px;
    padding: 15px 10px 5px 15px;
    border-radius: 0;
    > a {
      width: 100%;
      height: 100%;
      > img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }
`
