import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga4'

import useOutsideClick from '../../../../hooks/outsideClick.js'
import { searchActionReducer } from '../../../../store/search/searchSlice'
import { ContainerMessage, ContainerTitle } from '../Containers/Containers.style'
import { CloseIcon, DownArrow } from '../Containers'
import {
  BedsFilterContainer,
  BedsFilterTriggerContainer,
  BedOption,
  BedOptionsContainer,
  BedsDropdownContainer,
  ButtonsContainer,
  DoneButton,
  IconContainer,
  IconsContainer,
  ResetButton,
} from './BedsFilter.style'
import clickEvents from '../../../../services/analytics.service.js'

const BedsFilter = ({ refetchListings }) => {
  const closeIconRef = useRef()
  const containerRef = useRef()
  const dispatch = useDispatch()

  const { beds, page, url } = useSelector((state) => state.search)
  const { bedsContainer } = useSelector((state) => state.search)

  const setBeds = (payload) => dispatch(searchActionReducer.setBeds(payload))
  const setBedsContainer = (payload) => dispatch(searchActionReducer.setBedsContainer(payload))

  const minBeds = beds?.length > 0 && Math.min(...beds)
  const maxBeds = beds?.length > 0 && Math.max(...beds)
  const message = beds?.length
    ? minBeds === maxBeds
      ? minBeds === 4
        ? '4+ beds'
        : minBeds === 1
        ? `${minBeds} bed`
        : `${minBeds} beds`
      : maxBeds === 4
      ? `${minBeds}+ beds`
      : `${minBeds} - ${maxBeds}`
    : 'Any'

  const [bedsFilterIsOpen, setBedsFilterIsOpen] = useState(false)

  const closeIconIsHidden = bedsContainer === 'Any'

  const zeroBedsSelected = beds?.includes(0)
  const oneBedSelected = beds?.includes(1)
  const twoBedsSelected = beds?.includes(2)
  const threeBedsSelected = beds?.includes(3)
  const fourBedsSelected = beds?.includes(4)

  const handleCloseIconOnClick = () => {
    setBeds([])
    setBedsFilterIsOpen(false)
    setBedsContainer('Any')
    refetchListings(
      {
        unit__number_of_bedrooms: null,
        unit__number_of_bedrooms__lte: null,
        unit__number_of_bedrooms__gte: null,
      },
      'BedsFilter.handleCloseIconOnClick',
    )
  }

  const handleBedsFilterOnClose = () => {
    setBedsContainer(message)
    refetchListings({}, 'BedsFilter.handleBedsFilterOnClose')
  }

  const handleBedsFilterOnClick = (event) => {
    if (closeIconRef?.current?.contains(event?.target)) return handleCloseIconOnClick()
    setBedsFilterIsOpen((bedsFilterIsOpen) => !bedsFilterIsOpen)
    if (bedsFilterIsOpen) handleBedsFilterOnClose()
    ReactGA.event({
      category: 'action',
      action: 'search-page-beds-filter-clicked',
    })
    clickEvents.BEDS_FILTER()
  }

  const handleResetOnClick = () => {
    setBeds([])
  }

  const handleDoneOnClick = () => {
    setBedsFilterIsOpen(false)
    handleBedsFilterOnClose()
  }

  const handleZeroOnClick = () => {
    if (!zeroBedsSelected && fourBedsSelected) setBeds([])
    else if (!zeroBedsSelected && threeBedsSelected) setBeds([0, 1, 2, 3])
    else if (!zeroBedsSelected && twoBedsSelected) setBeds([0, 1, 2])
    else if (!zeroBedsSelected && oneBedSelected) setBeds([0, 1])
    else if (!zeroBedsSelected) setBeds([0])
    else setBeds(beds?.filter((option) => option !== 0))
  }

  const handleOneOnClick = () => {
    if (oneBedSelected && !(maxBeds === 1 || minBeds === 1)) setBeds([1])
    else if (!oneBedSelected && fourBedsSelected) setBeds([1, 2, 3, 4])
    else if (!oneBedSelected && threeBedsSelected) setBeds([1, 2, 3])
    else if (!oneBedSelected && twoBedsSelected) setBeds([1, 2])
    else if (!oneBedSelected && zeroBedsSelected) setBeds([0, 1])
    else if (!oneBedSelected) setBeds([1])
    else setBeds(beds?.filter((option) => option !== 1))
  }

  const handleTwoOnClick = () => {
    if (twoBedsSelected && !(maxBeds === 2 || minBeds === 2)) setBeds([2])
    else if (!twoBedsSelected && fourBedsSelected) setBeds([2, 3, 4])
    else if (!twoBedsSelected && zeroBedsSelected) setBeds([0, 1, 2])
    else if (!twoBedsSelected && threeBedsSelected) setBeds([2, 3])
    else if (!twoBedsSelected && oneBedSelected) setBeds([1, 2])
    else if (!twoBedsSelected) setBeds([2])
    else setBeds(beds?.filter((option) => option !== 2))
  }

  const handleThreeOnClick = () => {
    if (threeBedsSelected && !(maxBeds === 3 || minBeds === 3)) setBeds([3])
    else if (!threeBedsSelected && zeroBedsSelected) setBeds([0, 1, 2, 3])
    else if (!threeBedsSelected && oneBedSelected) setBeds([1, 2, 3])
    else if (!threeBedsSelected && twoBedsSelected) setBeds([2, 3])
    else if (!threeBedsSelected && fourBedsSelected) setBeds([3, 4])
    else if (!threeBedsSelected) setBeds([3])
    else setBeds(beds?.filter((option) => option !== 3))
  }

  const handleFourOnClick = () => {
    if (!fourBedsSelected && zeroBedsSelected) setBeds([])
    else if (!fourBedsSelected && oneBedSelected) setBeds([1, 2, 3, 4])
    else if (!fourBedsSelected && twoBedsSelected) setBeds([2, 3, 4])
    else if (!fourBedsSelected && threeBedsSelected) setBeds([3, 4])
    else if (!fourBedsSelected) setBeds([4])
    else setBeds(beds?.filter((option) => option !== 4))
  }

  useOutsideClick(
    containerRef,
    () => {
      if (!bedsFilterIsOpen) return
      setBedsFilterIsOpen(false)
      handleBedsFilterOnClose()
    },
    [beds, bedsFilterIsOpen, page, url],
  )

  return (
    <BedsFilterContainer ref={containerRef} isOpen={bedsFilterIsOpen}>
      <BedsFilterTriggerContainer onClick={handleBedsFilterOnClick}>
        <ContainerTitle>Beds</ContainerTitle>
        <ContainerMessage selection={bedsContainer !== 'Any'}>
          {bedsContainer}
          <IconsContainer>
            <IconContainer isHidden={closeIconIsHidden} ref={closeIconRef}>
              <CloseIcon />
            </IconContainer>
            <IconContainer>
              <DownArrow />
            </IconContainer>
          </IconsContainer>
        </ContainerMessage>
      </BedsFilterTriggerContainer>
      <BedsDropdownContainer isOpen={bedsFilterIsOpen}>
        <BedOptionsContainer>
          <BedOption selected={zeroBedsSelected} onClick={handleZeroOnClick}>
            Studio
          </BedOption>
          <BedOption selected={oneBedSelected} onClick={handleOneOnClick}>
            1
          </BedOption>
          <BedOption selected={twoBedsSelected} onClick={handleTwoOnClick}>
            2
          </BedOption>
          <BedOption selected={threeBedsSelected} onClick={handleThreeOnClick}>
            3
          </BedOption>
          <BedOption selected={fourBedsSelected} onClick={handleFourOnClick}>
            4+
          </BedOption>
        </BedOptionsContainer>
        <ButtonsContainer>
          <ResetButton onClick={handleResetOnClick}>Reset</ResetButton>
          <DoneButton onClick={handleDoneOnClick}>Done</DoneButton>
        </ButtonsContainer>
      </BedsDropdownContainer>
    </BedsFilterContainer>
  )
}

export default BedsFilter
