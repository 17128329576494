import styled from 'styled-components'

import THEME from '../../../../constants/themes'

export const Annotation = styled.div.attrs({
  className: 'Annotation',
})`
  line-height: 24px;
`

export const Button = styled.button.attrs({
  className: 'Button',
})(
  ({ isOutline }) => `
    background: #fff;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    padding: 16px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 40px;
    min-width: 162px;
    background-color: ${isOutline ? THEME.backgroundGray : '#226597'};
    color: ${isOutline ? '#226597' : THEME.backgroundGray};
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 500;
    cursor: pointer;
    > svg {
      font-size: 17px;
    }
    &:hover {
      // color: #104e91;
      // background-color: #f7f9fb;
      opacity: 0.9;
    }
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    @media (max-width: 992px) {
      min-width: auto;
      flex: 1;
    }
  `,
)

export const ButtonText = styled.span.attrs({
  className: 'ButtonText',
})`
  margin-left: 4px;
`

export const ButtonsContainer = styled.div.attrs({
  className: 'ButtonsContainer',
})`
  display: flex;
  gap: 1em;
  align-items: center;
  @media (max-width: 992px) {
    width: 100%;
  }
`

export const SaveAndShareContainer = styled.div.attrs({
  className: 'SaveAndShareContainer',
})`
  @media (max-width: 992px) {
    padding: 0 20px;
  }
`

export const SaveAndShareContentContainer = styled.div.attrs({
  className: 'SaveAndShareContentContainer',
})`
  display: flex;
  align-items: flex-start;
  gap: 1em;
  justify-content: space-between;
  @media (max-width: 992px) {
    padding-bottom: 1em;
    flex-direction: column;
  }
`

export const SaveAndShareTextContainer = styled.div.attrs({
  className: 'SaveAndShareTextContainer',
})``
