import { useRef } from 'react'
import { MapContainer as LeafletMapContainer, TileLayer } from 'react-leaflet'
import { useSelector } from 'react-redux'

import NeighborhoodPolygons from './NeighborhoodPolygons'
import SwitchViewButton from '../Search/List/SwitchViewButton'
import { MapContainer, StyledMapLoadingSkeleton } from './Map.style'
import Markers from './Markers'

const accessToken = 'Dt2bOiQCnur25k4G2YQ5vN4cvVK7fr2jaKvYukA0Tk15dJyF5LF6h15Ayp5UTGeK'

const Map = ({ buildings, isLoading, refetchListings, setMap, zipcodes }) => {
  const mapOuterContainterRef = useRef(null)

  const { building: selectedBuildingUuid } = useSelector((state) => state.selections)
  const { searchPage: currentMobileView } = useSelector((state) => state.page)

  const mapCenter = [25.762, -80.202]
  const mapZoom = 13
  const maxBounds = [
    [25.5, -80.6], // Southwest corner (latitude, longitude)
    [26.4, -80.0], // Northeast corner (latitude, longitude)
  ]
  const isMobileMapView = currentMobileView === 'map'
  const buildingIsInFocus = !!selectedBuildingUuid

  return (
    <MapContainer ref={mapOuterContainterRef} isActive={isMobileMapView} buildingIsInFocus={buildingIsInFocus}>
      {isLoading && false ? (
        <StyledMapLoadingSkeleton />
      ) : (
        <LeafletMapContainer
          center={mapCenter}
          zoom={mapZoom}
          style={{ width: '100%', height: '100%' }}
          maxBounds={maxBounds}
          minZoom={11}
          ref={setMap}
        >
          <TileLayer
            url={'https://{s}.tile.jawg.io/jawg-light/{z}/{x}/{y}{r}.png?access-token=' + accessToken}
            attribution='&copy <a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {!isLoading && <Markers buildings={buildings} />}
          <NeighborhoodPolygons refetchListings={refetchListings} />
        </LeafletMapContainer>
      )}
      <SwitchViewButton />
    </MapContainer>
  )
}

export default Map
