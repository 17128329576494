import styled from 'styled-components'
import THEME from '../../../../constants/themes'

export const MoreCheckboxesContainer = styled.div.attrs({
  className: 'MoreCheckboxesContainer',
})`
  width: 100%;
`

export const MoreFilterContainer = styled.div.attrs({
  className: 'MoreFilterContainer',
})(
  ({ isOpen }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-color: ${THEME.backgroundGray};
    position: relative;
    &:hover {
      background-color: #fafafa;
    }    
  `,
)

export const MoreFilterDropdownContainer = styled.div.attrs({
  className: 'MoreFilterDropdownContainer',
})(
  ({ isOpen }) => `
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    z-index: 185;
    gap: 0.5em;
    border-radius: 0 0 10px 10px;
    margin-top: -10px;
    width: calc(100% - 60px);
    max-width: calc(100% + 1px);
    padding: 0.5em;
    left: -1px;
    background: ${THEME.backgroundGray};
    border: 1px solid rgb(230, 230, 230);
    border-top: none;
    position: absolute;
    top: 75px;
  `,
)

export const MoreFilterTriggerContainer = styled.div.attrs({
  className: 'MoreFilterTriggerContainer',
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0.5em;
`

export const MoreOption = styled.div.attrs({
  className: 'MoreOption',
})(
  ({ isSelected }) => `
    display: flex;
    gap: 0.5em;
    padding: 3px;
    align-items: center;
    background: ${isSelected ? 'lightblue' : THEME.backgroundGray};
    &:hover {
      background: ${isSelected ? THEME.lightBlue : 'white'};
    }
  `,
)

export const MoreOptionCheckbox = styled.input.attrs({
  className: 'MoreOptionCheckbox',
  type: 'checkbox',
})`
  &:checked {
    accent-color: ${THEME.mediumBlue};
  }
`

export const MoreOptionLabel = styled.div.attrs({
  className: 'MoreOptionLabel',
})`
  font-size: 13px;
`

export const MoreOptionSubtitle = styled.div.attrs({
  className: 'MoreOptionSubtitle',
})`
  margin-bottom: 5px;
  color: #5f5f5f;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
`
