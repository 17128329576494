import { Skeleton } from '@mui/material'

import { FooterContainer } from '../Footer.style'

const Loader = () => (
  <FooterContainer>
    <Skeleton width="60%" height="100%" />
  </FooterContainer>
)

export default Loader
