import { useDispatch, useSelector } from 'react-redux'
import MenuItem from '@mui/material/MenuItem'

import { searchActionReducer } from '../../../store/search/searchSlice'
import { ReactComponent as CLOSE_ICON } from '../../../assets/images/close.svg'
import { panelsActionReducer } from '../../../store/panels/panelsSlice'
import { OrderingPanelContainer, OrderingPanelHeaderContainer, OrderingPanelTitle } from './OrderingPanel.style'

const NewDevelopmentsOrderingPanel = () => {
  const dispatch = useDispatch()

  const { newDevelopmentsOrderingPanelIsOpen: orderingPanelIsOpen } = useSelector((state) => state?.panels)
  const { ordering } = useSelector((state) => state?.search)

  const setOrdering = (payload) => dispatch(searchActionReducer?.setNewDevelopmentsOrdering(payload))
  const closeOrderingPanel = () => dispatch(panelsActionReducer?.setNewDevelopmentsOrderingPanelIsOpen(false))

  const orderingOptions = [
    { label: 'Soonest', value: 'soonest' },
    { label: 'Name', value: 'name' },
    { label: 'Neigborhood', value: 'neighborhood' },
  ]

  const handleOptionOnClick = (option) => {
    setOrdering(option?.value)
    closeOrderingPanel()
  }

  const optionLines = orderingOptions?.map((option) => (
    <MenuItem
      key={option?.value}
      value={option?.value}
      className={option?.value === ordering ? 'active' : ''}
      onClick={() => handleOptionOnClick(option)}
    >
      {option?.label}
    </MenuItem>
  ))

  return (
    <OrderingPanelContainer className="hidden-desktop" isOpen={orderingPanelIsOpen}>
      <OrderingPanelHeaderContainer>
        <OrderingPanelTitle>Sort Results By</OrderingPanelTitle>
        <CLOSE_ICON onClick={closeOrderingPanel} />
      </OrderingPanelHeaderContainer>
      {optionLines}
    </OrderingPanelContainer>
  )
}

export default NewDevelopmentsOrderingPanel
