import styled from 'styled-components'

export const Image = styled.img.attrs({
  className: 'Image',
})`
  width: 100%;
  height: 100%;
  max-height: 170px;
  min-height: 170px;
  transition: 0.3s;
  object-fit: cover;
  :hover {
    transform: scale(105%);
  }
`

export const ImagesContainer = styled.div.attrs({
  className: 'ImagesContainer',
})`
  width: 45%;
  height: 170px;
  .slick-initialized .slick-slide {
    height: 170px;
  }

  &:hover {
    .slick-prev {
      z-index: 1;
    }
    .slick-next {
      z-index: 1;
    }
  }
  .magic-dots.slick-dots {
    width: 70px !important;
    ul {
      li {
        width: 6px;
        height: 6px;
        background: #ffffff8a;
        border-radius: 6px;
        margin: 0 3.5px;
        transition: 0.25s all;
        &.slick-active {
          width: 24px;
          background: #fff;
          button {
            width: 24px;
            height: 6px;
            &:before {
              display: none;
            }
          }
        }
        button {
          width: 6px;
          height: 6px;
          padding: 0;
          &:before {
            display: none;
          }
        }
      }
    }
  }
  .slick-prev {
    left: 15px !important;
  }
  .slick-next {
    right: 0 !important;
  }
  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    z-index: -1;
    height: 20px;
    width: 30px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: #4183c4;
    top: 48%;
    padding: 0;
    border: none;
    outline: none;
    &:hover,
    &:focus {
      outline: none;
      background: transparent;
      color: transparent;
      &:before {
        opacity: $slick-opacity-on-hover;
      }
    }
    &.slick-disabled:before {
      opacity: $slick-opacity-not-active;
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 25px;
    line-height: 1;
    color: #4183c4;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-prev {
    left: -25px;
    [dir='rtl'] & {
      left: auto;
      right: -25px;
    }
    &:before {
      content: $slick-prev-character;
      [dir='rtl'] & {
        content: $slick-next-character;
      }
    }
  }
  .slick-next {
    right: -25px;
    [dir='rtl'] & {
      left: -25px;
      right: auto;
    }
    &:before {
      content: $slick-next-character;
      [dir='rtl'] & {
        content: $slick-prev-character;
      }
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    width: 100%;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
`
