import {
  ImagesContainer,
  LoaderContainer,
  LoadingSkeleton,
  PrimaryImageContainer,
  SecondaryImageContainer,
  TextContainer,
} from './TopSectionLoader.style'

const TopSectionLoader = () => {
  return (
    <LoaderContainer>
      <ImagesContainer>
        <PrimaryImageContainer>
          <LoadingSkeleton />
        </PrimaryImageContainer>
        <SecondaryImageContainer>
          <LoadingSkeleton />
        </SecondaryImageContainer>
      </ImagesContainer>
      <TextContainer>
        <LoadingSkeleton />
      </TextContainer>
      <TextContainer>
        <LoadingSkeleton />
      </TextContainer>
      <TextContainer>
        <LoadingSkeleton />
      </TextContainer>
    </LoaderContainer>
  )
}

export default TopSectionLoader
