import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import Auth from './Auth'
import FeaturedListing from './FeaturedListing'
import Payment from './Payment'
import SavedSearch from './SavedSearch'

import { BackgroundContainer } from './Modals.style'

const Modals = () => {
  const { authModalIsOpen, savedSearchModalIsOpen, featuredListingsModalIsOpen, paymentModalIsOpen } = useSelector(
    (state) => state.modals,
  )

  const isOpen = authModalIsOpen || savedSearchModalIsOpen || featuredListingsModalIsOpen || paymentModalIsOpen

  useEffect(() => {
    if (isOpen) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'visible'
  }, [isOpen])

  return (
    <BackgroundContainer isOpen={isOpen}>
      {authModalIsOpen && <Auth />}
      {savedSearchModalIsOpen && <SavedSearch />}
      {featuredListingsModalIsOpen && <FeaturedListing />}
      {paymentModalIsOpen && <Payment />}
    </BackgroundContainer>
  )
}

export default Modals
