import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga4'

import amenityOptions from '../../../../constants/amenities'
import useOutsideClick from '../../../../hooks/outsideClick'
import { searchActionReducer } from '../../../../store/search/searchSlice'
import { ContainerTitle, ContainerMessage } from '../Containers/Containers.style'
import { CloseIcon, DownArrow } from '../Containers'
import {
  AmenitiesCheckboxesContainer,
  AmenitiesTriggerContainer,
  AmenitiesDropdownContainer,
  AmenitiesFilterContainer,
  AmenityOption,
  AmenityOptionCheckbox,
  AmenityOptionLabel,
  ButtonsContainer,
  DoneButton,
  IconsContainer,
  IconContainer,
  ResetButton,
} from './AmenitiesFilter.style'
import clickEvents from '../../../../services/analytics.service'

const AmenitiesFilter = ({ refetchListings }) => {
  const closeIconRef = useRef()
  const containerRef = useRef()

  const dispatch = useDispatch()

  const [amenitiesFilterIsOpen, setAmenitiesFilterIsOpen] = useState(false)

  const { amenities, page, url } = useSelector((state) => state.search)
  const { amenitiesContainer } = useSelector((state) => state.search)

  const setAmenities = (payload) => dispatch(searchActionReducer.setAmenities(payload))
  const setAmenitiesContainer = (payload) => dispatch(searchActionReducer.setAmenitiesContainer(payload))

  const numberOfOptionsSelected = amenities?.length
  const message =
    numberOfOptionsSelected === 1
      ? '1 Option Selected'
      : numberOfOptionsSelected > 1
      ? `${numberOfOptionsSelected} Options Selected`
      : 'Select Amenities'

  const closeIconIsHidden = amenitiesContainer === 'Select Amenities'

  const handleCloseIconOnClick = () => {
    setAmenities([])
    setAmenitiesContainer('Select Amenities')
    setAmenitiesFilterIsOpen(false)
    refetchListings({ amenities: null }, 'AmenitiesFilter.handleCloseOnClick')
  }

  const handleAmenityOnClick = (option, isSelected) =>
    setAmenities(isSelected ? amenities.filter((amenity) => amenity !== option?.value) : [...amenities, option?.value])

  const handleAmenitiesFilterOnClose = () => {
    refetchListings({}, 'AmenitiesFilter.handleAmenitiesFilterOnClose')
    setAmenitiesContainer(message)
  }

  const handleAmenitiesFilterOnClick = (event) => {
    if (closeIconRef?.current?.contains(event?.target)) return handleCloseIconOnClick()
    setAmenitiesFilterIsOpen((amenitiesFilterIsOpen) => !amenitiesFilterIsOpen)
    if (amenitiesFilterIsOpen) handleAmenitiesFilterOnClose()
    ReactGA.event({
      category: 'action',
      action: 'search-page-amenities-filter-clicked',
    })
    clickEvents.AMENITIES_FILTER()
  }

  const handleDoneOnClick = () => {
    setAmenitiesFilterIsOpen(false)
    handleAmenitiesFilterOnClose()
  }

  const handleResetOnClick = () => setAmenities([])

  useOutsideClick(
    containerRef,
    () => {
      if (!amenitiesFilterIsOpen) return
      setAmenitiesFilterIsOpen(false)
      handleAmenitiesFilterOnClose()
    },
    [amenities, amenitiesFilterIsOpen, page, url],
  )

  const amenityCheckboxes = amenityOptions?.map((option) => {
    const isSelected = amenities?.includes(option?.value)

    return (
      <AmenityOption
        key={option?.label}
        onClick={() => handleAmenityOnClick(option, isSelected)}
        isSelected={isSelected}
      >
        <AmenityOptionCheckbox checked={isSelected} readOnly />
        <AmenityOptionLabel>{option?.label}</AmenityOptionLabel>
      </AmenityOption>
    )
  })

  return (
    <AmenitiesFilterContainer ref={containerRef} isOpen={amenitiesFilterIsOpen}>
      <AmenitiesTriggerContainer onClick={handleAmenitiesFilterOnClick}>
        <ContainerTitle>Amenities</ContainerTitle>
        <ContainerMessage selection={amenitiesContainer !== 'Select Amenities'}>
          {amenitiesContainer}
          <IconsContainer>
            <IconContainer isHidden={closeIconIsHidden} ref={closeIconRef}>
              <CloseIcon />
            </IconContainer>
            <IconContainer>
              <DownArrow />
            </IconContainer>
          </IconsContainer>
        </ContainerMessage>
      </AmenitiesTriggerContainer>
      <AmenitiesDropdownContainer isOpen={amenitiesFilterIsOpen}>
        <AmenitiesCheckboxesContainer>{amenityCheckboxes}</AmenitiesCheckboxesContainer>
        <ButtonsContainer>
          <ResetButton onClick={handleResetOnClick}>Reset</ResetButton>
          <DoneButton onClick={handleDoneOnClick}>Done</DoneButton>
        </ButtonsContainer>
      </AmenitiesDropdownContainer>
    </AmenitiesFilterContainer>
  )
}

export default AmenitiesFilter
