import styled from 'styled-components'

export const CloseIconContainer = styled.div.attrs({
  className: 'CloseIconContainer',
})`
  border-radius: 50%;
  padding: 5px;
  position: fixed;
  cursor: pointer;
  right: 20px;
  top: 20px;
  z-index: 700;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  svg path {
    fill: white;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`

export const ImagePreviewsContainer = styled.div.attrs({
  className: 'ImagePreviewsContainer',
})`
  margin: 0 auto;
  display: flex;
  text-align: center;
  overflow: hidden;
  align-items: center;
`

export const ImageReelContainer = styled.div.attrs({
  className: 'ImageReelContainer',
})`
  height: 85%;
  width: 100%;
  padding: 5%;
  white-space: nowrap;
  transition: ease 1000ms;
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
    margin: auto;
  }
  .slick-list {
    max-width: 1300px;
    margin: 0 auto;
  }
  .slick-dots {
    bottom: 20px !important;
    position: relative;
    & > ul {
      align-items: center;
    }
  }
  .magic-dots {
    position: fixed;
    top: 20px;
    display: flex;
  }
  @media (max-width: 992px) {
    height: 75%;
    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide > div {
      height: 100%;
      width: 100%;
      margin: auto;
    }
  }
`

export const IndexTag = styled.div.attrs({
  className: 'IndexTag',
})`
  position: absolute;
  top: 20px;
  left: 20px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.5em;
  border-radius: 5px;
  height: 34px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  z-index: 150;
`

export const NextButtonContainer = styled.button.attrs({
  className: 'NextButtonContainer',
})`
  width: 54px;
  height: 54px;
  border-radius: 49px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  // background: rgba(230, 242, 244, 0.1);
  background: rgba(0, 0, 0, 0.7);
  right: 2.5% !important;
  top: 40%;
  position: fixed;
  cursor: pointer;
  z-index: 700;
  &:before {
    display: none;
  }
  &:hover {
    // background: rgba(230, 242, 244, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 992px) {
    right: 20px !important;
  }
`

export const PreviewImage = styled.img.attrs({
  className: 'PreviewImage',
})`
  object-fit: cover;
  display: flex;
  cursor: pointer;
  transition: 0.5s all;
  overflow: hidden;
  height: 100%;
  width: 100%;
  &:focus-visible {
    outline: none;
  }
`

export const PreviewImageContainer = styled.div.attrs({
  className: 'PreviewImageContainer',
})(
  ({ isActive }) => `
    display: flex;
    width: 60px;
    height: 60px;
    margin: 0 5px;
    opacity: 0.7;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 2px;
    position: relative;
    &:focus-visible {
      outline: none;
    }
    &.slick-active {
      opacity: 1;
      border: 1px solid rgb(240, 240, 240);
    }
    @media (max-width: 992px) {
      width: 40px;
      height: 40px;
      margin: 0 3px;
    }
  `,
)

export const PreviewIndexTag = styled.div.attrs({
  className: 'PreviewIndexTag',
})`
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  height: 20px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  color: white;
  @media (max-width: 992px) {
    display: none;
  }
`

export const PreviousButtonContainer = styled.button.attrs({
  className: 'PreviousButtonContainer',
})`
  width: 54px;
  height: 54px;
  border-radius: 49px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  // background: rgba(230, 242, 244, 0.1);
  background: rgba(0, 0, 0, 0.7);
  left: 2.5% !important;
  top: 40%;
  position: fixed;
  cursor: pointer;
  z-index: 700;
  &:before {
    display: none;
  }
  &:hover {
    // background: rgba(230, 242, 244, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 992px) {
    left: 20px !important;
  }
`

export const PrimaryImage = styled.img.attrs({
  className: 'PrimaryImage',
})`
  height: 100%;
  width: 100%;
  object-fit: contain;
`

export const PrimaryImageContainer = styled.div.attrs({
  className: 'PrimaryImageContainer',
})`
  height: 100%;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
`

export const SlideShowContainer = styled.div.attrs({
  className: 'SlideShowContainer',
})`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 500;
  height: 100vh;
  background: rgba(0, 0, 0, 0.925);
  width: 100vw;
`
