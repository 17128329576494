import { Skeleton } from '@mui/material'
import { BottomSectionContainer } from './BottomSection.style'
import Listings from './Listings'

const BottomSection = ({ building, isLoading }) => {
  if (isLoading) {
    return <Skeleton variant="rectangular" height={400} />
  }

  return (
    <BottomSectionContainer>
      <Listings building={building} />
    </BottomSectionContainer>
  )
}

export default BottomSection
