import { ArrowIconContainer } from './ArrowIcon.style'

const ArrowIcon = ({ className, onClick, type }) => (
  <ArrowIconContainer onClick={onClick} className={className}>
    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      {type === 'next' ? (
        <path d="M1 1L8.36842 8L1 15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      ) : (
        <path d="M9 15L1.63158 8L9 1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      )}
    </svg>
  </ArrowIconContainer>
)

export default ArrowIcon
