import { Link } from 'react-router-dom'
import ReactGA from 'react-ga4'

import FeaturedIcon from './FeaturedIcon'
import { BATH_ICON, BED_ICON, SQURE_FOOT_ICON } from '../../../../../constants/image.const'
import {
  Icon,
  IconImage,
  IconsContainer,
  Image,
  IsFeaturedContainer,
  ItemContainer,
  ItemDataContainer,
  ItemLowerHalfContainer,
  ItemSubtitle,
  PriceElement,
  TextElement,
} from './Item.style'
import clickEvents from '../../../../../services/analytics.service'

const Item = ({ listing }) => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumSignificantDigits: 1,
  })

  const numberFormatter = new Intl.NumberFormat('en-US', {
    minimumSignificantDigits: 1,
  })

  const handleSimilarListingOnClick = () => {
    ReactGA.event({
      category: 'action',
      action: 'listing-page-similar-listing-clicked',
    })
    clickEvents.SIMILAR_LISTINGS()
  }

  return (
    <ItemContainer>
      <Link to={listing?.url} onClick={handleSimilarListingOnClick}>
        {listing?.isFeatured && (
          <IsFeaturedContainer>
            <FeaturedIcon />
            Featured
          </IsFeaturedContainer>
        )}
        <Image src={listing?.medias[0]?.file} alt="similar-listing" />
        <ItemLowerHalfContainer>
          <ItemDataContainer>
            <TextElement>
              <ItemSubtitle>{listing?.building?.name}</ItemSubtitle>
              <span>{listing?.address}</span>
              <span>Unit {listing?.unit?.number}</span>
            </TextElement>
            <PriceElement>{currencyFormatter.format(listing?.price)}</PriceElement>
          </ItemDataContainer>
          <IconsContainer>
            <Icon>
              <IconImage src={BED_ICON} alt="bed-icon" />
              {listing?.unit?.numberOfBedrooms} Beds
            </Icon>
            <Icon>
              <IconImage src={BATH_ICON} alt="bath-icon" />
              {listing?.unit?.numberOfBathrooms} Baths
            </Icon>
            <Icon>
              <IconImage src={SQURE_FOOT_ICON} alt="square-foot-icon" />
              {numberFormatter.format(listing?.unit?.squareFootage)} ft²
            </Icon>
          </IconsContainer>
        </ItemLowerHalfContainer>
      </Link>
    </ItemContainer>
  )
}

export default Item
