import { createContext, useContext, useReducer } from 'react'

const ListingContext = createContext()
export const useListingContext = () => useContext(ListingContext)

const imageReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LISTING':
      return { ...state, [action.listingsId]: action.listings }
    default:
      return state
  }
}

export const ListingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(imageReducer, {})

  const setListings = (listingsId, listings) => {
    dispatch({ type: 'SET_LISTING', listingsId, listings })
  }

  return <ListingContext.Provider value={{ listings: state, setListings }}>{children}</ListingContext.Provider>
}
