import { useSelector } from 'react-redux'

import Header from './Header'
import Cards from './Cards'
import Footer from './Footer'
import SwitchViewButton from './SwitchViewButton'
import Ads from '../../Ads'
import { AdsContainer, ListContainer, SearchListContainer } from './List.style'

const List = ({
  featuredListings,
  listings,
  map,
  favorites,
  getFavorites,
  loadingFavoritesResponse,
  isLoading,
  refetchListings,
}) => {
  const { searchPage: currentMobileView } = useSelector((state) => state.page)
  const listViewIsActive = currentMobileView === 'list'

  return (
    <ListContainer isActive={listViewIsActive}>
      <SearchListContainer>
        <Header isLoading={isLoading} listings={listings} refetchListings={refetchListings} />
        <Cards
          listings={listings}
          featuredListings={featuredListings}
          map={map}
          favorites={favorites}
          getFavorites={getFavorites}
          loadingFavoritesResponse={loadingFavoritesResponse}
          isLoading={isLoading}
        />
        <Footer isLoading={isLoading} refetchListings={refetchListings} />
        <SwitchViewButton />
      </SearchListContainer>
      <AdsContainer>
        <Ads variant="1" />
        <Ads variant="2" />
      </AdsContainer>
    </ListContainer>
  )
}

export default List
