import styled from 'styled-components'
import THEME from '../../../constants/themes'

export const SubHeaderContainer = styled.div.attrs({
  className: 'SubHeaderContainer hidden-desktop',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0.5em;
  top: 70px;
  height: 70px;
  background: ${THEME.darkBlue};
  position: fixed;
  z-index: 250;
  padding: 1em;
  opacity: 0.99;
`

export const SubHeaderButton = styled.div.attrs({
  className: 'SubHeaderButton',
})(
  ({ isActive }) => `
    white-space: nowrap;
    border-radius: 4px;
    // background: ${isActive ? 'lightblue' : '#fff'};
    background: ${isActive ? THEME.backgroundBlue : '#fff'};
    // color: ${isActive ? 'white' : 'black'};
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.28px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    gap: 6px;
    flex: 1;
  `,
)
