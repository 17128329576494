import styled from 'styled-components'

export const NoOptionsContainer = styled.div.attrs({
  className: 'NoOptionsContainer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 16px;
  min-height: 100px;
`
