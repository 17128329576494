import styled from 'styled-components'

export const NewDevelopmentsContainer = styled.div.attrs({
  className: 'NewDevelopmentsContainer',
})`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 80px;
  background: rgb(248, 249, 250);
  left: 0;
  width: 100%;
  @media (max-width: 992px) {
    margin-top: 0;
    flex-flow: wrap;
    width: 100vw;
    justify-content: space-between;
  }
`
