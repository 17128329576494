import { Skeleton } from '@mui/material'

import { CardsContainer, Title } from '../SavedSearches.style'

const Loader = () => (
  <>
    <Title>
      <Skeleton height="40px" width="80vw" />
    </Title>
    <CardsContainer>
      <Skeleton height="100px" width="100%" style={{ transform: 'none' }} />
      <Skeleton height="100px" width="100%" style={{ transform: 'none' }} />
      <Skeleton height="100px" width="100%" style={{ transform: 'none' }} />
      <Skeleton height="100px" width="100%" style={{ transform: 'none' }} />
    </CardsContainer>
  </>
)

export default Loader
