import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  headerPanelIsOpen: false,
  filterPanelIsOpen: false,
  orderingPanelIsOpen: false,
  newDevelopmentsFilterPanelIsOpen: false,
  newDevelopmentsOrderingPanelIsOpen: false,
}

export const panelsSlice = createSlice({
  name: 'panels',
  initialState,
  reducers: {
    setHeaderPanelIsOpen: (state, action) => {
      state.headerPanelIsOpen = action.payload
    },
    setFilterPanelIsOpen: (state, action) => {
      state.filterPanelIsOpen = action.payload
    },
    setOrderingPanelsOpen: (state, action) => {
      state.orderingPanelIsOpen = action.payload
    },
    setNewDevelopmentsFilterPanelIsOpen: (state, action) => {
      state.newDevelopmentsFilterPanelIsOpen = action.payload
    },
    setNewDevelopmentsOrderingPanelIsOpen: (state, action) => {
      state.newDevelopmentsOrderingPanelIsOpen = action.payload
    },
  },
})

export const panelsActionReducer = panelsSlice.actions
export default panelsSlice.reducer
