import styled from 'styled-components'
import THEME from '../../../../constants/themes'

export const Image = styled.img.attrs({
  className: 'Image',
})`
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease 0s;
  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: 992px) {
    border-radius: 10px 10px 0 0;
  }
`

export const ImageGridContainer = styled.div.attrs({
  className: 'ImageGridContainer',
})`
  container-type: inline-size;
  height: 430px;
  flex: 1 1 47%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 1.5em;
  @media (max-width: 992px) {
    display: none;
  }
`

export const ImagesContainer = styled.div.attrs({
  className: 'ImagesContainer',
})`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5em;
  padding: 0;
  width: 100%;
  @media (max-width: 992px) {
    position: relative;
    // top: -20px;
    // left: -20px;
    // width: calc(100% + 40px);
    height: 100%;
    margin-bottom: 0;
  }
`

export const PhotoBadge = styled.div.attrs({
  className: 'PhotoBadge',
})`
  position: absolute;
  bottom: 20px;
  right: 40px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 8px 16px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.48);
  box-shadow: 0px 1px 2px 0px rgba(31, 41, 55, 0.08);
  cursor: pointer;
  @media (max-width: 992px) {
    top: 10px;
    right: 10px;
    bottom: auto;
  }
`

export const PrimaryImageContainer = styled.div.attrs({
  className: 'PrimaryImageContainer',
})`
  cursor: pointer;
  height: 430px;
  flex: 1 1 53%;
  background: #e4f6f9;
  border-radius: 10px;
  overflow: hidden;
  @media (max-width: 992px) {
    height: 280px;
    border-radius: 10px 10px 0 0;
  }
`
export const SecondaryImageContainer = styled.div.attrs({
  className: 'SecondaryImageContainer',
})`
  cursor: pointer;
  width: 100%;
  background-size: cover;
  background-position: center center;
  height: 100%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  max-height: 205px;
  flex: 1 1 calc(50% - 20px);
`

export const StatusBadge = styled.div.attrs({
  className: 'StatusBadge',
})`
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: white;
  // opacity: 0.85;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 12px 20px;
  border-radius: 0 0 8px 0;
  color: #222;
  cursor: pointer;
  @media (max-width: 992px) {
    top: -1px;
    left: -1px;
    background-color: ${THEME.backgroundGray};
  }
`
