const THEME = {
  boxShadow: 'rgba(0, 0, 0, 0.14) 0px 4px 15px 0px;',
  boxShadowSmall: 'rgba(0, 0, 0, 0.14) 0px 2px 5px 0px;',
  white: '#F3F9FB',
  whiteBlue: '#DCF6FF',
  backgroundGray: '#f8f9fa',
  backgroundBlue: '#e6f2f4',
  lightBlue: '#76c1ce',
  mediumBlue: '#00669a',
  darkBlue: '#113f67',
  linkColor: '#4183c4',
}

export default THEME
