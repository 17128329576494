import styled from 'styled-components'
import { Pagination } from 'semantic-ui-react'

import THEME from '../../../../constants/themes'

export const Button = styled.div.attrs({
  className: 'Button',
})(
  ({ disabled }) => `
    min-height: 36px;
    max-height: 36px;
    border: 1px solid rgb(230, 230, 230);
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: ${disabled ? 'default' : 'pointer'};
    opacity: ${disabled ? '0.5' : '1'};
    font-weight: 900;
    color: #000;
    svg {
      path {
        stroke: ${disabled ? '#D7DBEA' : 'currentColor'};
      }
    }
    &:hover {
      background: ${disabled ? '' : THEME.lightBlue};
    }
    @media (max-width: 1240px) {
      border: none;
    }
  `,
)

export const FooterContainer = styled.div.attrs({
  className: 'FooterContainer',
})`
  width: 100%;
  height: 80px;
  display: flex;
  gap: 10px;
  padding: 20px 10px;
  align-items: center;
  justify-content: center;
  @media (max-width: 1240px) {
    gap: 5px;
  }
`

export const StyledPagination = styled(Pagination).attrs({
  className: 'StyledPagination',
})`
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
  align-items: center;
  .item {
    border: none;
    padding: 8px 14px !important;
    color: var(--neutral-2, #32374e) !important;
    text-align: center !important;
    font-family: Nunito Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 150% !important;
    border-radius: 45px !important;
    min-width: 36px !important;
    min-height: 36px !important;
    height: 36px;
    &.active {
      color: #fff !important;
      background: #00669a !important;
    }
    &:hover {
      color: #fff !important;
      background: #00669a !important;
    }
    &:before {
      display: none;
    }
  }
  @media (max-width: 992px) {
    background: transparent !important;
  }
`
