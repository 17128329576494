import styled from 'styled-components'

import THEME from '../../../constants/themes'

export const Address = styled.div.attrs({
  className: 'Address',
})`
  font-size: small;
  display: flex;
  flex-direction: column;
`

export const BuildingName = styled.div.attrs({
  className: 'BuildingName',
})`
  // font-weight: 600;
  // float: left;
  font-size: small;
`

export const DropdownContainer = styled.div.attrs({
  className: 'DropdownContainer',
})(
  ({ isOpen }) => `
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    gap: 0.5em;
    position: absolute;
    top: 30px;
    width: 100%;
    right: 0;
    // background: ${THEME.mediumBlue};
    background: ${THEME.backgroundGray};
    box-shadow: ${THEME.boxShadow};
    border: 1px solid ${THEME.lightBlue};
    border-top: none;
    border-radius: 0 0 10px 10px;
    z-index: 400;
    opacity: 1 !important;
    overflow: hidden;
  `,
)

export const InputContainer = styled.div.attrs({
  className: 'InputContainer',
})`
  position: relative;
  width: 100%;
  opacity: 0.85;
  transition: 0.2s all ease-in-out;
  > img {
    position: absolute;
    left: 3px;
    top: 7px;
    cursor: text;
  }
  &:hover {
    opacity: 1;
  }
`

export const OptionContainer = styled.div.attrs({
  className: 'OptionContainer',
})`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  color: #222;
  // color: #fff;
  padding: 4px 10px;
  &:hover {
    background: white;
  }
`

export const NeighborhoodName = styled.div.attrs({
  className: 'NeighborhoodName',
})`
  font-style: italic;
  font-weight: 300;
  float: right;
  font-size: smaller;
  margin-left: auto;
`

export const SectionContainer = styled.div.attrs({
  className: 'SectionContainer',
})`
  display: flex;
  flex-direction: column;
`

export const SectionHeader = styled.div.attrs({
  className: 'SectionHeader',
})`
  color: #333;
  // color: #ddd;
  display: flex;
  justify-content: space-between;
  padding: 4px 7px;
  font-weight: 600;
`

export const SectionTitle = styled.div.attrs({
  className: 'SectionTitle',
})`
  font-size: 16px;
`

export const SearchBarContainer = styled.div.attrs({
  className: 'SearchBarContainer',
})(
  ({ isFocused }) => `
    // width: 100%;
    width: ${isFocused ? '350' : '350'}px;
    transition: 0.5s all ease-in-out;
  `,
)

export const StyledInput = styled.input.attrs({
  className: 'StyledInput',
})`
  background: transparent;
  color: #fff;
  height: 34px;
  border: none;
  border-bottom: 0.5px solid #aaa;
  padding: 10px 3px 11px 27px;
  width: 100%;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  transition: 0.2s all ease-in-out;
  &::-webkit-input-placeholder {
    color: #ccc;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
`
