function transformToSchemaOrg(listing) {
  const address = listing?.address?.value
  const name = `Unit ${listing?.unit?.number} in ${listing?.building?.name} in ${listing?.neighborhood?.name} Miami at ${address}`

  const schemaOrgJsonLd = {
    '@context': 'https://schema.org',
    '@type': 'Apartment',
    name: name,
    description: listing?.description,
    floorSize: {
      '@type': 'QuantitativeValue',
      value: listing?.unit?.squareFootage,
      unitCode: 'FTK',
    },
    numberOfBathroomsTotal: listing?.unit?.numberOfBathrooms,
    numberOfBedrooms: listing?.unit?.numberOfBedrooms,
    yearBuilt: listing?.building?.yearBuilt,
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'US',
      addressLocality: listing?.address?.city,
      addressRegion: 'Florida',
      postalCode: listing?.address?.zipcode,
      streetAddress: `${listing?.address?.streetNumber} ${listing?.address?.streetName} Unit ${listing?.unit?.number}`,
    },
    image: listing?.medias?.slice(0, 3).map((media) => media?.file),
  }

  return schemaOrgJsonLd
}

export default transformToSchemaOrg
