import styled from 'styled-components'

export const FilterWrapper = styled.div.attrs({
  className: 'FilterWrapper hidden-mobile',
})`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 300px;
  height: 80px;
  align-items: center;
  justify-content: center;
  background: white;
  position: fixed;
  top: 140px;
  z-index: 175;
  box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.2);
`
