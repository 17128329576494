import styled from 'styled-components'
import THEME from '../../constants/themes'

export const ContactUsContainer = styled.div.attrs({
  className: 'ContactUsContainer',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 40px;
  gap: 15px;
  position: relative;
  background: #e6f2f4;
  &:before {
    content: '';
    border-bottom: 1px solid rgb(17, 63, 103);
    bottom: 0px;
    left: 0px;
    width: calc(100% - 80px);
    position: absolute;
    margin: 0 40px;
  }
  @media (max-width: 992px) {
    padding: 15px 20px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    &:before {
      width: calc(100% - 40px);
      margin: 0 20px;
    }
  }
`

export const ContentContainer = styled.div.attrs({
  className: 'ContentContainer',
})`
  display: flex;
  gap: 20px;
  align-items: center;
`

export const FooterContainer = styled.div.attrs({
  className: 'FooterContainer',
})(
  ({ isMobileMapView }) => `
  ${isMobileMapView && 'display: none;'}
    z-index: 300;
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 100%;
    height: 600px;
    bottom: 0;
    position: absolute;
    overflow: hidden;
    @media (max-width: 992px) {
      height: 350px;
    }
  `,
)

export const FooterItem = styled.div.attrs({
  className: 'FooterItem',
})`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #333;
`

export const LogoImage = styled.img.attrs({
  className: 'LogoImage',
})`
  width: 147px;
`

export const SubFooterContainer = styled.div.attrs({
  className: 'SubFooterContainer',
})`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 25px 40px;
  gap: 40px;
  background: #e6f2f4;
  @media (max-width: 992px) {
    padding: 15px 20px;
    align-items: center;
    justify-content: center;
  }
`

export const WaveImage = styled.img.attrs({
  className: 'WaveImage',
})`
  margin-bottom: -5px;
  position: relative;
  max-height: 400px;
  object-fit: cover;
  width: 101%;
  object-position: top;
  @media (max-width: 992px) {
    max-height: 200px;
  }
`
