import { TermsContainer, TermsParagraph, TermsSection } from './Terms.style'

const Terms = () => {
  return (
    <TermsContainer>
      <TermsSection>
        <h1>Terms Policy</h1>
        <TermsParagraph>Effective Date: Janurary 2023</TermsParagraph>
      </TermsSection>
      <TermsSection>
        <h4>1. Acceptance of Terms</h4>
        <TermsParagraph>
          Welcome to OceanPads.com ("OceanPads," "we," "us," or "our"). By accessing or using our website and mobile
          application (collectively, the "Platform"), you agree to comply with and be bound by these Terms of Service.
          If you do not agree to these terms, please do not use our Platform.
        </TermsParagraph>
      </TermsSection>
      <TermsSection>
        <h4>2. Property Listings and Transactions</h4>
        <TermsParagraph>
          OceanPads provides property listings for informational purposes. We do not own or manage the properties listed
          on our Platform. Any transaction or agreement between you and a property owner or real estate agent is solely
          between the parties involved. OceanPads is not responsible for any aspect of these transactions.{' '}
        </TermsParagraph>
        <TermsParagraph>
          We do not guarantee the accuracy, availability, or quality of property listings. Property owners and agents
          are responsible for the accuracy and completeness of their listings.
        </TermsParagraph>
      </TermsSection>
      <TermsSection>
        <h4>3. User Conduct</h4>
        <TermsParagraph>
          By using our Platform, you agree not to: Use the Platform for any unlawful or unauthorized purpose. Post
          false, misleading, or fraudulent property listings. Interfere with the proper functioning of the Platform.
          Harass, abuse, or harm other users or third parties.
        </TermsParagraph>
      </TermsSection>
      <TermsSection>
        <h4>4. Intellectual Property</h4>
        <TermsParagraph>
          All content and materials on our Platform, including text, graphics, logos, and images, are protected by
          intellectual property laws and belong to OceanPads or our licensors. You may not use, reproduce, or distribute
          these materials without our prior written consent.
        </TermsParagraph>
      </TermsSection>
      <TermsSection>
        <h4>5. Privacy</h4>
        <TermsParagraph>
          Your use of our Platform is also governed by our Privacy Policy. By using our Platform, you consent to our
          data practices as described in the Privacy Policy.
        </TermsParagraph>
      </TermsSection>
      <TermsSection>
        <h4>6. Disclaimers and Limitations of Liability</h4>
        <TermsParagraph>
          Our Platform is provided on an "as is" and "as available" basis. We make no warranties or representations
          regarding the availability, accuracy, or reliability of the Platform.
        </TermsParagraph>
        <TermsParagraph>
          OceanPads shall not be liable for any direct, indirect, incidental, special, consequential, or punitive
          damages arising out of or relating to your use of the Platform, even if we have been advised of the
          possibility of such damages.
        </TermsParagraph>
      </TermsSection>
      <TermsSection>
        <h4>7. Changes to Terms of Service</h4>
        <TermsParagraph>
          We reserve the right to update or modify these Terms of Service at any time. We will notify you of any
          material changes, and the updated terms will be effective when posted.
        </TermsParagraph>
      </TermsSection>
      <TermsSection>
        <h4>8. Termination</h4>
        <TermsParagraph>
          We may terminate or suspend your access to the Platform, without notice, for any reason or no reason,
          including for a violation of these Terms of Service.
        </TermsParagraph>
      </TermsSection>
      <TermsSection>
        <h4>9. Contact Us</h4>
        <TermsParagraph>
          If you have any questions or concerns about these Terms of Service, please contact us at
          support@oceanpads.com.
        </TermsParagraph>
      </TermsSection>
    </TermsContainer>
  )
}

export default Terms
