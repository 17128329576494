import styled from 'styled-components'

export const Image = styled.img.attrs({
  className: 'Image',
})`
  width: 100%;
  padding: 15px;
`

export const Paragraph = styled.p.attrs({
  className: 'Paragraph',
})`
  display: flex;
  font-size: 1.1em;
  line-height: 1.6em;
  text-align: justify;
  margin: 0;
`

export const ParallaxContainer = styled.div.attrs({
  className: 'ParallaxContainer',
})`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100vh;
  img {
    height: 100%;
  }
  opacity: 0.1;
`

export const PostContainer = styled.div.attrs({
  className: 'PostContainer',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
  max-width: 1200px;
  width: 100%;
  margin: auto;
  margin-top: 80px;
  z-index: 1;
  @media (max-width: 992px) {
    padding: 20px;
    background-color: #f8f9fa;
    margin-top: 60px;
  }
`
