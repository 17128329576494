import styled from 'styled-components'

export const FilterContainer = styled.div.attrs({
  className: 'FilterContainer',
})`
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 111px 230px;
  height: 80px;
  width: 800px;
  border-radius: 60px;
  border: 1px solid rgba(0, 102, 154, 0.28);
  background: rgb(255, 255, 255);
  transition: all 0.25s !important;
  z-index: 175;
  position: relative;
  box-shadow: 0px 0px 21px 7px rgba(35, 102, 152, 0.08);

  @media (max-width: 992px) {
    grid-template-rows: 1fr 1fr 40px;
    grid-template-columns: 1fr 1fr;
    // row-gap: 0.5em;
    width: 100%;
    height: auto;
    padding: 14px;
    border-radius: 8px;
    max-width: 330px;
  }
`
