import { PrivacyContainer, PrivacyParagraph, PrivacySection } from './Privacy.style'

const Privacy = () => {
  return (
    <PrivacyContainer>
      <PrivacySection>
        <h1>Privacy Policy</h1>
        <PrivacyParagraph>Effective Date: Janurary 2023</PrivacyParagraph>
      </PrivacySection>
      <PrivacySection>
        <h4>1. Introduction</h4>
        <PrivacyParagraph>
          Welcome to OceanPads.com ("OceanPads," "we," "us," or "our"). At OceanPads, we are committed to protecting the
          privacy and security of your personal information. This Privacy Policy explains how we collect, use, and
          disclose your information when you use our website or mobile application (collectively, the "Platform").
        </PrivacyParagraph>
        <PrivacyParagraph>
          By accessing or using our Platform, you consent to the practices described in this Privacy Policy. Please take
          a moment to carefully read this Privacy Policy to understand how we handle your personal information.
        </PrivacyParagraph>
      </PrivacySection>
      <PrivacySection>
        <h4>2. Information We Collect</h4>
        <PrivacyParagraph>
          We collect various types of information when you use our Platform, including:
        </PrivacyParagraph>
        <PrivacyParagraph>
          <b>Personal Information:</b> REALTORS - Information that can be used to identify you, such as your name, email
          address, phone number, and postal address. CONSUMERS - Strictly your email address.
        </PrivacyParagraph>
        <PrivacyParagraph>
          <b>Property Information: </b>Information related to properties you are interested in, including property
          listings, searches, favorites, and inquiries.
        </PrivacyParagraph>
        <PrivacyParagraph>
          <b>Usage Information:</b> Information about how you use our Platform, including your browsing history, IP
          address, device type, and location data.
        </PrivacyParagraph>
      </PrivacySection>
      <PrivacySection>
        <h4>3. How We Use Your Information</h4>
        <PrivacyParagraph>
          We use your information for various purposes, including but not limited to: Providing and improving our
          services, including property listings and search functionalities. Communicating with you about your inquiries,
          account, and updates to our services. Analyzing and understanding user preferences to enhance the user
          experience. Sending promotional materials and updates about our services (with your consent, where required by
          law). Ensuring the security and integrity of our Platform.
        </PrivacyParagraph>
      </PrivacySection>
      <PrivacySection>
        <h4>4. How We Share Your Information</h4>
        <PrivacyParagraph>
          We may share your information with third parties under the following circumstances: With property owners or
          real estate agents when you express interest in a property listing. With service providers who assist us in
          delivering our services. With law enforcement agencies, government authorities, or in response to legal
          requests when required by applicable law.
        </PrivacyParagraph>
      </PrivacySection>
      <PrivacySection>
        <h4>5. Your Choices</h4>
        <PrivacyParagraph>
          You have choices regarding the information you provide and how it is used: You can update your account
          information and communication preferences. You can choose not to receive promotional emails from us. You can
          manage the sharing of certain information through your account settings.
        </PrivacyParagraph>
      </PrivacySection>
      <PrivacySection>
        <h4>6. Security</h4>
        <PrivacyParagraph>
          We take reasonable measures to protect your information, but no method of transmission over the internet is
          entirely secure. We encourage you to use strong passwords and keep your login credentials confidential.
        </PrivacyParagraph>
      </PrivacySection>
      <PrivacySection>
        <h4>7. Changes to this Privacy Policy</h4>
        <PrivacyParagraph>
          We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or
          regulatory reasons. We will notify you of any material changes, and the updated policy will be effective when
          posted.
        </PrivacyParagraph>
      </PrivacySection>
      <PrivacySection>
        <h4>8. Contact Us</h4>
        <PrivacyParagraph>
          If you have any questions or concerns about this Privacy Policy or your personal information, please contact
          us at support@oceanpads.com.
        </PrivacyParagraph>
      </PrivacySection>
    </PrivacyContainer>
  )
}

export default Privacy
