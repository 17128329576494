import axios from 'axios'
import {
  AUTH_NEW_USER,
  AUTH_SOCIAL_TOKEN_USER,
  AUTH_TOKENS_SESSION,
  AUTH_USERS,
  AUTH_VALIDATE_EMAIL,
  AUTH_VALIDATE_PASSWORD,
} from '../constants/api'

export const register = (email, password, type = 'CONSUMER') => {
  return axios.post(AUTH_NEW_USER, {
    email,
    password,
    type: type,
  })
}

export const login = (username, password) => {
  return axios
    .post(AUTH_TOKENS_SESSION, {
      username,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem('user', JSON.stringify(response.data))
      }

      return response.data
    })
}

export const loginAsSocial = (payload) => {
  return axios.post(AUTH_SOCIAL_TOKEN_USER, payload).then((response) => {
    return response.data
  })
}

export const checkEmail = (email) => {
  return axios
    .post(AUTH_VALIDATE_EMAIL, {
      email,
    })
    .then((response) => {
      return response.data
    })
}

export const checkPassword = (payload) => {
  return axios.post(AUTH_VALIDATE_PASSWORD, payload).then((response) => {
    return response.data
  })
}

export const logout = () => {
  localStorage.removeItem('user')
}

export const getCurrentUser = () => {
  try {
    if (typeof window !== 'undefined') {
      const userStr = localStorage.getItem('user')
      if (userStr) return JSON.parse(userStr)
    }
  } catch (error) {}

  return null
}

export const getUserByUuid = (uuid, header) => {
  return axios.get(`${AUTH_USERS}${uuid}/`, header).then((response) => {
    return response.data
  })
}
