const neighborhoods = [
  { label: 'Allapattah', value: 'fd6cc991-436c-4326-bfa4-5fbc01aca8a9' },
  { label: 'Aventura', value: 'e391d771-8e17-4846-9e03-e9ed09c9814d' },
  { label: 'Bal Harbour', value: '6f1ddcce-3a37-4767-aedf-566e5a3fe918' },
  { label: 'Bay Harbor Islands', value: '19c8b93e-8db7-4426-a26e-564643b4f062' },
  { label: 'Biscayne Park', value: '6f12d352-eb9e-4d1e-ae01-2cf7552c889f' },
  { label: 'Brickell', value: '32819f1f-1fad-4ddf-836c-09be9ed11df0' },
  { label: 'Brownsville', value: '054c61e4-00bd-41a4-bbab-b838b0050447' },
  { label: 'Coconut Grove', value: '7dd5fe5c-4a7f-4f8c-8820-1127ff8d251c' },
  { label: 'Coral Gables', value: 'ed61781a-75b4-4e11-87f8-c5f5ebd7b71a' },
  { label: 'Coral Terrace', value: 'edf81b57-2f0d-4603-b5a5-d65ff8e29799' },
  { label: 'Design District', value: '398dabbf-9fcc-49dc-8f20-5cdd547028f8' },
  { label: 'Doral', value: 'dfbc4edc-9dfe-478a-9d96-3f346148600a' },
  { label: 'Downtown Miami', value: '08b1e64d-f4ea-4d17-a168-d11bf504822d' },
  { label: 'Eastern Shores', value: '1467dd08-e030-4f76-82d5-51f9d69f36a9' },
  { label: 'Edgewater', value: 'a5e0e660-983b-4636-97bb-63baf0ef39b6' },
  { label: 'El Portal', value: '808c72c6-8b80-4756-927f-2d6fdebfde45' },
  { label: 'Fisher Island', value: 'b3ed764c-3645-4359-bad0-326b054e8fe5' },
  { label: 'Flagami', value: '3dca488a-1098-4206-9fb0-3ed2683ba755' },
  { label: 'Fountainbleau', value: 'ba2a46ca-a7f3-429e-83ed-6e95903b4ff0' },
  { label: 'Glenvar Heights', value: '2d28c3ac-2f39-4fcd-8bf7-5467f80d733b' },
  { label: 'Golden Beach', value: '457dab7a-8655-44dd-ac4c-24c4cb445e2e' },
  { label: 'Haulover Beach', value: '416b03ba-bff4-4ca6-b9a1-ce0691df9233' },
  { label: 'Hialeah', value: '653f4f1b-6c47-4ca4-a8f6-8addb4827f3f' },
  { label: 'Key Biscayne', value: 'afbdd009-624a-45bb-ac39-5acdcfec5ae1' },
  { label: 'Little Haiti', value: '9e54fafc-5bca-441f-a4bc-824027562b06' },
  { label: 'Little Havana', value: 'ec760514-e8e9-4173-b2f0-847a6b5b72fc' },
  { label: 'Little River', value: '1049d624-8f5d-4a88-8257-ec78cbd9d675' },
  { label: 'Miami Gardens', value: 'd3dab48e-03aa-4aed-8bb7-fa39e8fa32db' },
  { label: 'Miami Lakes', value: '9eb7ae74-6b60-4d72-8f4f-c0785448fa47' },
  { label: 'Miami Shores', value: '09a5061f-ba06-4cbe-a338-1b671c9c02e6' },
  { label: 'Miami Springs', value: 'c87db675-272a-4201-9fb8-38199ee4a546' },
  { label: 'Mid Beach', value: 'd03a2e6d-8a44-494a-9529-681ed161bbe1' },
  { label: 'Midtown', value: 'd3a08ec5-739a-4904-9991-8f9b055c29b9' },
  { label: 'MiMo', value: '91b6a60d-b4a2-4c69-93e6-e708141e18ef' },
  { label: 'Normandy Isles Normandie Shores', value: 'de45b966-ad30-4cd0-b142-8166571d028a' },
  { label: 'North Bay Village', value: '92f11df7-e8d9-49dc-9935-193eed937d2b' },
  { label: 'North Beach', value: '9a4e33bc-9e48-4be3-8808-f07bece9929c' },
  { label: 'North Miami', value: 'e8485bd5-a17c-4a9a-a5ab-e1f6c9d998f2' },
  { label: 'North Miami Beach', value: '5c0d3d38-13a8-4257-9335-262d1d93d6c8' },
  { label: 'Olympia Heights', value: '20da02ef-659a-41a3-9732-fde8bfa0a3b2' },
  { label: 'Opa Locka', value: 'aca70e3c-2360-4778-809a-8a6cad867410' },
  { label: 'Pinecrest', value: '1c9a37eb-e2f8-4b0c-a4b4-875e74f41cc2' },
  { label: 'Shenandoah', value: 'ea09063d-1281-4e10-9888-78c9bb98b7f0' },
  { label: 'South Beach', value: '5d144474-541c-41d5-ae13-4023ce832e4b' },
  { label: 'South Miami', value: '50502b52-fa26-4083-8cea-e32df3c4459c' },
  { label: 'South of Fifth', value: '5dffa2bb-b191-43df-a465-144ea7d95609' },
  { label: 'Sunny Isles', value: '8534a98c-62dc-42f3-a93e-5d4b750c2a7a' },
  { label: 'Sunset', value: 'c86c1b8f-6abc-45fc-8707-e799f58322f6' },
  { label: 'Sunset Harbor', value: '3aab95e6-96f5-4e17-9b82-bc9531c52c6c' },
  { label: 'Surfside', value: '70b45b5b-4ed2-4af9-92e7-2e0f6f4b7644' },
  { label: 'Town Park', value: 'b2dbd236-0d92-4e60-b79a-6bf396005e37' },
  { label: 'Upper East Side', value: 'fdc0212e-727d-46dd-8a3e-05771b458c7e' },
  { label: 'Venetian Islands', value: '67d802c3-9dae-4b85-920d-b204cb6da511' },
  { label: 'West Little River', value: 'c02e04cb-258d-4e1b-bff7-58bfd9f0d6a6' },
  { label: 'Wynwood', value: 'dfef81ca-a90a-40de-9062-c0eb15a962b4' },
]

export default neighborhoods
