import { FeaturedIconContainer } from './FeaturedIcon.style'

const FeaturedIcon = () => (
  <FeaturedIconContainer>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.04257 1.29512L8.10014 3.247C8.24265 3.51694 8.62518 3.77303 8.9477 3.82841L10.8603 4.11911C12.0829 4.30599 12.3679 5.12274 11.4904 5.93948L9.99777 7.31687C9.75026 7.54529 9.60775 7.99519 9.69025 8.3205L10.1178 10.0232C10.4553 11.366 9.67525 11.892 8.39266 11.186L6.60004 10.2032C6.27752 10.0232 5.73748 10.0232 5.41496 10.2032L3.62234 11.186C2.33975 11.8851 1.5597 11.366 1.89722 10.0232L2.32475 8.3205C2.39225 7.98827 2.24974 7.53836 2.00223 7.30995L0.509625 5.93256C-0.367935 5.12274 -0.0829153 4.30599 1.13967 4.11219L3.0523 3.82149C3.37482 3.77303 3.75735 3.51002 3.89986 3.24008L4.95743 1.28819C5.53497 0.236118 6.46503 0.236118 7.04257 1.29512Z"
        fill="#fff"
      />
    </svg>
  </FeaturedIconContainer>
)

export default FeaturedIcon
