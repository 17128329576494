import { memo } from 'react'

import { CardsContainer } from './Cards.style'
import Item from './Item'
import Loader from './Loader'
import NoOptions from './NoOptions'

const Cards = ({ buildings, map, favorites, getFavorites, loadingFavoritesResponse, isLoading }) => {
  const buildingCards = buildings?.map((building, index) => {
    const savedBuilding = favorites?.find((favorite) => favorite?.building === building.uuid)

    return (
      <Item
        building={building}
        key={`${building?.uuid}-${index}`}
        map={map}
        savedBuilding={savedBuilding}
        getFavorites={getFavorites}
        loadingFavoritesResponse={loadingFavoritesResponse}
      />
    )
  })

  if (isLoading) return <Loader />

  if (buildings?.length === 0) return <NoOptions />

  return <CardsContainer>{buildingCards}</CardsContainer>
}

export default memo(Cards)
